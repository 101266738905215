import React, { Component } from 'react'
import { connect } from 'react-redux'
import Marquee from 'utils/marquee'
// Components
import CaseStudy from 'CaseStudy/CaseStudyContainer'
import ValueStoryContainer from 'ValueStory/ValueStoryContainer'
import MyWorkContainer from 'ValueStory/MyWorkContainer'
import SharedValueStories from 'ValueStory/SharedValueStories';
// UI
import Modal from 'reboron/OutlineModal'
import Popup from "reactjs-popup"

// Utils
import { BreakLine, getCurrentSubLeverId, getCurrentLanguage } from 'utils/Utils'
import Linkify from 'react-linkify'
import classnames from 'classnames'
import {
	periodicEmailConfirmation,
	periodicEmailConfirmationFailure,
	periodicEmailConfirmationSuccess
}
	from 'actions/userActions'
// Settings
import { routes, modalStyle, modalContentStyle, path, changeModalStyle, changeModalContentStyle, backdropStyle } from 'Constants'
import {
	pendingChanges,
	saveKPIOnRedirect,
	isEnablementStrategyContainerPage,
	deleteIntermediateKPIOnRedirect,
	isSpendParameterPage,
    saveSpendParametersOnRedirect
} from 'actions/spendActions'
import {
    saveValueStoryOnRedirect,
    isValueStoryPage
} from 'actions/discoveryActions'
import Button from 'UI/Button'
import UserPopUp from './UserPopUp'

const changeBackdropStyle = Object.assign({}, backdropStyle);
changeBackdropStyle.zIndex = '1050';

const alertModalStyle = Object.assign({}, changeModalStyle);
alertModalStyle.zIndex = '1060';
alertModalStyle.width = '50%';

const valueStoryModalStyle = Object.assign({}, modalStyle);
valueStoryModalStyle.width = "60%";
valueStoryModalStyle.maxWidth = "1250px";
valueStoryModalStyle.height = "515px";

const valueStoryContentModalStyle = Object.assign({}, modalContentStyle);
valueStoryContentModalStyle.paddingTop = "20px";
valueStoryContentModalStyle.paddingLeft = "30px";
valueStoryContentModalStyle.paddingRight = "30px";
valueStoryContentModalStyle.paddingBottom = "10px";

const Help = (props) =>
	<div class="help_modal">
		<div className="learn-more-title">{props.content.title}</div>
		<div className="learn-more-description">{BreakLine(props.content.description)}</div>
	</div>

const SubLeverCalculationHelp = (props) =>
	<div className="sublever-calculation__help">
		{props.content.map((data, i) =>
			<div key={i}>
				{data.title && <div className="learn-more-title">{data.title}</div>}
				<div className="learn-more-heading">{data.listTitle}</div>
				<ul>
					{data.listItems.map((text, j) =>
						<li key={j}>{BreakLine(text)}</li>
					)}
				</ul>
				{data.sources && <div className="learn-more-heading">{data.sources.title}</div>}
				<ul >
					<Linkify>
						{data.sources && data.sources.source.map((source, k) =>
							<li key={k}>{BreakLine(source)}</li>
						)}
					</Linkify>
				</ul>
			</div>
		)}
	</div>

const NavUI = (props) =>
	<div className="grid-x avt-header">
		<div className="cell medium-5 large-5">
			<div className="logo">
				<img src="/images/sap_logo.png" width="60" />
			</div>
			<div className="menu_button">
				<button className="nav__menu" onClick={props.showMenu}>
					<span></span>{props.content.menu}
				</button>
			</div>
		</div>
		<div className="cell medium-2 large-2">
			<div className="site_name">
				<img src="/images/avatar_logo.png" width="120" />
			</div>
		</div>
		<div className="cell medium-5 large-5">
			<div className="float-right">
				<div className="float-left">
					{
						!props.noHelp ?
							(<button className="nav__help" onClick={props.helpClick}>{props.content.help}</button>) :
							null
					}
				</div>

				<div className="float-left language_selector">
					<LanguageSelector
						content={props.content}
						currentLang={props.currentLang}
						showLanguageSelector={props.showLanguageSelector}
						handleLanguageSelection={props.handleLanguageSelection}
						languageSelectorVisible={props.languageSelectorVisible}
					/>
				</div>
				<div className="float-left mywork">
					<button className="nav__case-work" onClick={props.myWorkClick}>My work</button>
					{
						props.caseStudy ?
							(<button className="nav__case-study" onClick={() => props.showModal('caseStudy')}>{props.content.caseStudy}</button>) :
							null
					}
				</div>
				<div className="float-left userPopUp">
					{/* <div id='col1'><UserPopUp /></div>
					<div id='col2'><button className="nav__userProfile"> Profile </button></div> */}
					{props.caseStudy ? null : <UserPopUp />}
				</div>
			</div>
		</div>
	</div>

const LanguageSelector = (props) =>
	<React.Fragment>
		<button
			className="nav__language-selector"
			onClick={() => props.showLanguageSelector()}
		>
			<div className="language_text">{props.currentLang}</div>
			<div className="down_arrow">
				<img src="/images/dropdown_inactive.svg" width="11px" />
			</div>
		</button>
		<ul className={classnames('nav__language-selector-dropdown', { 'nav__language-selector-dropdown--active': props.languageSelectorVisible })}>
			{props.content.languageSelector.map((data, i) =>
				<li key={i}>
					<span onClick={() => { props.handleLanguageSelection(data.text, data.lang) }} >
						{data.text}
					</span>
				</li>
			)}
		</ul>
	</React.Fragment>

class NavContainer extends Component {

	constructor(props) {
		super(props)

		this.state = {
			helpContent: {},
			caseStudyContent: {},
			toggle: false,
			isLoading: false,
		}

		this.helpClick = this.helpClick.bind(this)
		this.myWorkClick = this.myWorkClick.bind(this)
		this.showModal = this.showModal.bind(this)
		this.hideModal = this.hideModal.bind(this)
		this.checkForCaseStudy = this.checkForCaseStudy.bind(this)
		this.checkForHelp = this.checkForHelp.bind(this)
		this.PeriodicEmailConfirmation = this.PeriodicEmailConfirmation.bind(this)
		this.closeModal = this.closeModal.bind(this)
	}

	helpClick() {
		const content = this.props.content
		let helpContent = {}

		switch (this.props.currentRoute.pathname) {

			case routes.discovery:
				helpContent = content.discovery.help
				break

			case routes.priorities:
				helpContent = content.priorities.help
				break

			case routes.businessValue:
				helpContent = content.businessValue.help
				break

			case routes.subLeverCalculation:
				let obj = content.subLeverCalculations.subLeverCalculation.find((obj) => {
					if (this.props.currentRoute.state !== undefined) {
						return obj.id === this.props.currentRoute.state.subleverId
					} else {
						return obj.id === getCurrentSubLeverId()
					}
				})

				helpContent = obj.help
				break

			case routes.calculateROI:
				helpContent = content.calculateROI.help
				break

			default:
				break
		}

		this.setState({
			helpContent: helpContent
		})

		this.showModal('help')
	}

	checkForCaseStudy(props = this.props) {
		let obj = {}

		if (props.currentRoute.state && props.currentRoute.state.hasOwnProperty('subleverId')) {
			// Retrieve from React Router
			obj = props.content.subLeverCalculations.subLeverCalculation.find((obj) =>
				obj.id === props.currentRoute.state.subleverId)
		} else {
			// Retrieve from cookie
			obj = props.content.subLeverCalculations.subLeverCalculation.find((obj) =>
				obj.id === getCurrentSubLeverId())
		}

		/*
		if (obj !== undefined && obj !== 'undefined') {
			return obj.caseStudy
		}
		return false
		*/

		//Uncomment above code and comment below code to show case study to below hardcoded sub levers.
		if (obj !== undefined && obj !== 'undefined') {
			if (obj.id === "1.5" || obj.id === "1.6" || obj.id === "2.3" || obj.id === "3.6") {
				return false
			}
			else {
				return obj.caseStudy
			}
		}
		return false
	}

	checkForHelp(props = this.props) {
		let obj = {}

		if (props.currentRoute.state && props.currentRoute.state.hasOwnProperty('subleverId')) {
			// Retrieve from React Router
			obj = props.content.subLeverCalculations.subLeverCalculation.find((obj) =>
				obj.id === props.currentRoute.state.subleverId)
		} else {
			// Retrieve from cookie
			obj = props.content.subLeverCalculations.subLeverCalculation.find((obj) =>
				obj.id === getCurrentSubLeverId())
		}

		//Comment below If condition to show Learn more to below hardcoded sub levers.
		if (obj !== undefined && obj !== 'undefined') {
			if (obj.id === "1.5" || obj.id === "1.6" || obj.id === "2.3" || obj.id === "3.6") {
				return true
			}
			else {
				return false
			}
		}

		return false
	}

	caseStudyClick() {
		this.showModal('caseStudy')
	}

	myWorkClick() {
		if (this.props.change !== undefined && this.props.change !== null
			&& this.props.change === true &&
			this.props.spendDetails.isKpiStrategy !== undefined && this.props.spendDetails.isKpiStrategy !== null
			&& this.props.spendDetails.isKpiStrategy === true
			&& !this.state.toggle) {
			this.showModal("KPI");
		}
		else if (this.props.change !== undefined && this.props.change !== null
			&& this.props.change === true &&
			this.props.valueStory.isValueStoryPage !== undefined && this.props.valueStory.isValueStoryPage !== null
			&& this.props.valueStory.isValueStoryPage === true
			&& !this.state.toggle) {
			this.showModal("VS");
		}		
        else if (this.props.change !== undefined && this.props.change !== null
			&& this.props.change === true &&
			this.props.spendDetails.isSpendParameterPage !== undefined && this.props.spendDetails.isSpendParameterPage !== null
			&& this.props.spendDetails.isSpendParameterPage === true) {
            this.showModal("SpendParameter");
		}      
		else if (!this.state.toggle) {
			this.showModal('terms')
		} else {
			this.showModal('valueStory')
		}

	}

	continueKPINavigation(action,isIntermediateDataDelete) {     
        this.setState({ isLoading : true });
        if(isIntermediateDataDelete)
            this.props.deleteIntermediateKPIOnRedirect(true);
        else
            this.props.saveKPIOnRedirect(true);
        setTimeout(() => {
            this.props.isEnablementStrategyContainerPage(false);
            this.closeModal(action)          
            this.setState({ isLoading : false });
        }, 10000);
    }

	continueValueStoryNavigation(action) {
        this.setState({ isLoading: true });
        this.props.saveValueStoryOnRedirect(true);
        setTimeout(() => {
            this.props.isValueStoryPage(false);
            this.closeModal(action);
            this.setState({ isLoading: false });
        }, 4000);
    }
	
	continueSpendParameterNavigation(action) {
        this.setState({ isLoading: true });
        this.props.saveSpendParametersOnRedirect(true);
        setTimeout(() => {
            this.props.isSpendParameterPage(false);
            this.closeModal(action);
            this.setState({ isLoading: false });
        }, 4000);
    }

	showModal(action) {
		this.refs[action].show()
	}

	hideModal(action) {
		this.refs[action].hide()
	}

	closeModal(action) {
		this.props.pendingChanges(null)
		this.refs[action].hide()
		this.showModal('valueStory')
	}

	componentWillReceiveProps(nextProps) {
		const caseStudyContent = this.checkForCaseStudy(nextProps)
		this.setState({
			caseStudyContent: caseStudyContent
		})

		if (nextProps.change == null) {
			this.setState({ toggle: true })
		}
		else if (nextProps.change != null) {
			this.setState({ toggle: false })
		}
	}

	componentWillMount() {
		const caseStudyContent = this.checkForCaseStudy()

		this.setState({
			caseStudyContent: caseStudyContent
		})
	}

	PeriodicEmailConfirmation() {
		let language = getCurrentLanguage()

		if (language === undefined || language === 'undefined' || language === '') {
			language = 'en'
		}
		let email = localStorage.getItem("loggedinUser")
		let values = { Email: email, Language: language };
		var content = this.props.content.login
		this.props.periodicEmailConfirmation(values)
			.then((response) => {
				this.setState({ enablePeriodicValidation: false })
				this.props.periodicEmailConfirmationSuccess(content[response.status])
			}).catch((error) => {
				this.props.periodicEmailConfirmationFailure(content[error.response.status])
			})
	}


	render() {
		//!test.contains('@sap.com') &&
		//!this.localStorage.getItem('loggedinUser').toString().contains('sap.com') &&
		//var test = 'sunita.kawale@sap.com'
		const isLoggedIn = this.props.publicRoutes.indexOf(this.props.currentRoute.pathname) > -1 ? false : true,
			noHelp = (this.props.currentRoute.pathname === routes.discovery && this.props.slide === false) ||
				this.props.noHelpRoutes.indexOf(this.props.currentRoute.pathname) > -1 || (this.props.currentRoute.pathname === routes.subLeverCalculation && this.checkForHelp()) ? true : false,
			caseStudy = (this.props.currentRoute.pathname === routes.subLeverCalculation && this.checkForCaseStudy()) ? true : false
		var message = '';
		const showAlertMessage =
			localStorage.getItem('loggedinUser') !== undefined &&
				localStorage.getItem('loggedinUser') !== null
				&& !localStorage.getItem('loggedinUser').includes('@sap.com') &&
				this.props.currentRoute.pathname === '/start' &&
				localStorage.getItem('daysRemainforReactivation') !== null &&
				localStorage.getItem('daysRemainforReactivation') !== undefined &&
				localStorage.getItem('daysRemainforReactivation') >= 0 ? true : false;
		if (showAlertMessage) {
			message = this.props.content.login.alertMessage.toString().replace('%X%', localStorage.getItem('daysRemainforReactivation'))
		}

		const isCustomer = sessionStorage.getItem('isCustomer') === 'true'

		return (

			<nav className="nav">
				{
					isLoggedIn ? (
						<div>
							<NavUI
								content={this.props.content.nav}
								noHelp={noHelp}
								caseStudy={caseStudy}
								showMenu={this.props.showMenu}
								helpClick={this.helpClick}
								myWorkClick={this.myWorkClick}
								currentLang={this.props.currentLang}
								showLanguageSelector={this.props.showLanguageSelector}
								handleLanguageSelection={this.props.handleLanguageSelection}
								languageSelectorVisible={this.props.languageSelectorVisible}
								showModal={this.showModal}
							/>
							{
								showAlertMessage ?

									<Marquee Index0={message}
										Size="div"
										Color='red'
										Action={() => this.PeriodicEmailConfirmation()}
									/>
									: null
							}

						</div>
					) : (
						<div className="grid-x grid-padding-x">
							<div className="medium-6 large-6 cell">
								<div className="login-logo">
									<img src={path + `images/logo-sap-ariba.png`} alt="SAP Ariba" />
								</div>
							</div>
							<div className="medium-6 large-6 cell login-language-selector">
								<LanguageSelector
									content={this.props.content.nav}
									currentLang={this.props.currentLang}
									showLanguageSelector={this.props.showLanguageSelector}
									handleLanguageSelection={this.props.handleLanguageSelection}
									languageSelectorVisible={this.props.languageSelectorVisible}
								/>
							</div>
						</div>
					)
				}

				<Modal
					ref="help"
					modalStyle={modalStyle}
					contentStyle={modalContentStyle}
				>
					{this.props.currentRoute.pathname === routes.subLeverCalculation ? <SubLeverCalculationHelp content={this.state.helpContent} /> : <Help content={this.state.helpContent} />
					}
					<button onClick={() => this.hideModal('help')} className="close" />
				</Modal>
				<Modal
					ref="valueStory"
					modalStyle={valueStoryModalStyle}
					contentStyle={valueStoryContentModalStyle}
				>
					{!isCustomer ?
						<span>
							{<MyWorkContainer history={this.props.history} hideModal={this.hideModal} />}
							<button onClick={() => this.hideModal('valueStory')} className="close" />
						</span>
						: <span>
							<SharedValueStories isCustomer={isCustomer} content={this.props.menu.valueStory} history={this.props.history} hideModal={this.hideModal} />
							<button onClick={() => this.hideModal('valueStory')} className="close" />
						</span>
					}
				</Modal>
				<div>
					<Modal
						ref="terms"
						modalStyle={changeModalStyle}
						contentStyle={changeModalContentStyle}
					>
						<div className="grid-x grid-padding-x change-popup">
							<div className="medium-12">
								<button onClick={() => this.hideModal("terms")} className="close" />
							</div>
							<div className="medium-12 pt30">
								{this.props.tabMessage.toggleTabMessage}
							</div>
							<div className="medium-12 popupOkBtn">
								<div className="float-right mt5">
									<div className="float-left"><Button
										content={"Continue without saving changes"}
										onClick={() => this.closeModal("terms")}
										color /></div>
									<div className="float-left"><Button
										content={"Cancel to return to current page"}
										onClick={() => this.hideModal("terms")}
										color /></div>
								</div>
							</div>
						</div>
					</Modal>
				</div>
				<div>
                    <Modal
                        ref="KPI"
                        modalStyle={alertModalStyle}
                        contentStyle={changeModalContentStyle}
                        backdropStyle={changeBackdropStyle}
                    >
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12">
                                <button onClick={() => this.hideModal("KPI")} className="close" />
                            </div>
                            <div className="medium-12 pt30">
                            {this.props.tabMessage.toggleKPITabMessage}
                            </div>                            
							<div className="grid-x medium-12 mt20">
                                <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleKPITabYesButton}
                                        onClick={() => this.continueKPINavigation("KPI",false)}
                                        color />
                                </div>                                
                                <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleKPITabNoButton}
                                        onClick={() => this.hideModal("KPI")}
                                        color />
                                </div>
								<div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleKPITabRevertButton}
                                        onClick={() => this.continueKPINavigation("KPI",true)}
                                        color />
                                </div>
                            </div> 
                        </div>
                    </Modal>
                </div>
				<div>
                    <Modal
                        ref="VS"
                        modalStyle={alertModalStyle}
                        contentStyle={changeModalContentStyle}
                        backdropStyle={changeBackdropStyle}
                    >
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12">
                                <button onClick={() => this.hideModal("VS")} className="close" />
                            </div>
                            <div className="medium-12 pt30">
                            {this.props.tabMessage.toggleVSMessage}
                            </div>                            
							<div className="grid-x medium-12 mt20">
                                <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleVSYesButton}
                                        onClick={() => this.continueValueStoryNavigation("VS")}
                                        color />
                                </div>                                
                                <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleVSNoButton}
                                        onClick={() => this.closeModal("VS")} 
                                        color />
                                </div>
								<div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleVSRevertButton}
                                        onClick={() => this.hideModal("VS")}
                                        color />
                                </div>
                            </div> 
                        </div>
                    </Modal>
                </div>
				<div>
                    <Modal
                        ref="SpendParameter"
                        modalStyle={alertModalStyle}
                        contentStyle={changeModalContentStyle}
                        backdropStyle={changeBackdropStyle}
                    >
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12">
                                <button onClick={() => this.hideModal("SpendParameter")} className="close" />
                            </div>
                            <div className="medium-12 pt30">
                            {this.props.tabMessage.toggleVSMessage}
                            </div>                            
							<div className="grid-x medium-12 mt20">
                                <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleSpendParamYesButton}
                                        onClick={() => this.continueSpendParameterNavigation("SpendParameter")}
                                        color />
                                </div>                                
                                <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleVSNoButton}
                                        onClick={() => this.closeModal("SpendParameter")} 
                                        color />
                                </div>
								<div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleVSRevertButton}
                                        onClick={() => this.hideModal("SpendParameter")}
                                        color />
                                </div>
                            </div> 
                        </div>
                    </Modal>
                </div>
				{
					this.checkForCaseStudy()
					&& <Modal
						ref="caseStudy"
						modalStyle={modalStyle}
						contentStyle={modalContentStyle}
					>
						<CaseStudy content={this.state.caseStudyContent} />
						<button onClick={() => this.hideModal('caseStudy')} className="close" />
					</Modal>
				}
			</nav>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.default,
		content: state.content,
		slide: state.goToChoosePath,
		menu: state.content.menu,
		change: state.detailedFileChanges.changes,
		tabMessage: state.content.spendAnalysis.detailedFile,
		menu: state.content.menu,
		spendDetails: state.spendDetails,
		valueStory : state.valueStory,
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		periodicEmailConfirmation: (email) => dispatch(periodicEmailConfirmation(email)),
		periodicEmailConfirmationSuccess: (bool) => dispatch(periodicEmailConfirmationSuccess(bool)),
		periodicEmailConfirmationFailure: (error) => dispatch(periodicEmailConfirmationFailure(error)),
		pendingChanges: changes => dispatch(pendingChanges(changes)),
		saveKPIOnRedirect: changes => dispatch(saveKPIOnRedirect(changes)),
        isEnablementStrategyContainerPage: obj => dispatch(isEnablementStrategyContainerPage(obj)),
		deleteIntermediateKPIOnRedirect: changes => dispatch(deleteIntermediateKPIOnRedirect(changes)),
		saveValueStoryOnRedirect: changes => dispatch(saveValueStoryOnRedirect(changes)),
        isValueStoryPage:(obj)=>dispatch(isValueStoryPage(obj)),
		isSpendParameterPage: obj => dispatch(isSpendParameterPage(obj)),
        saveSpendParametersOnRedirect: changes => dispatch(saveSpendParametersOnRedirect(changes)),    
	}	
}

export default connect(mapStateToProps, mapDispatchToProps)(NavContainer)
