import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-redux-form'
import classNames from "classnames";
import { path } from 'Constants'
import moment from 'moment'
import { CheckboxE } from 'UI/CustomComponents/Controls';

// Actions
import {
    fetchSolutionInScopeValues,
    resetError,
    updateSolutionInScopeValues
} from 'actions/spendActions'

// Utils
import { BreakLine } from 'utils/Utils'

class SolutionInScopeValues extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            apiData: [],
            editedScopeRow: [],
            responseMessage: '',
            isError: false,
        }
        this.loadData = this.loadData.bind(this)
        this.trackScopeVisibilityChanges = this.trackScopeVisibilityChanges.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.getSolutionInScopeValues();
    }

    trackScopeVisibilityChanges(record) {
        var updatedRecords = this.state.editedScopeRow
        if (this.state.editedScopeRow.length === 0)
            updatedRecords.push(record)
        else {
            let index = this.state.editedScopeRow.findIndex(x => x.Id === record.Id);
            if (index !== undefined && index !== null && index > -1)
                updatedRecords.splice(index, 1)
            updatedRecords.push(record)
        }
        this.setState({ responseMessage: '', editedScopeRow: updatedRecords, isError: false })
    }

    getSolutionInScopeValues() {
        this.setState({ responseMessage: '', isLoading: true, isError: false });
        this.props.fetchSolutionInScopeValues().then((response) => {
            if (response.status === 200) {
                if (response.data.Result.length === 0) {
                    this.setState({ responseMessage: this.props.content.noRecords, isLoading: false, isError: true });
                }
                else {
                    console.log("api data-----------", response.data.Result)
                    this.setState({ apiData: response.data.Result, isLoading: false, responseMessage: '', isError: false });
                }
            }
            else {
                this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
            }
        }).catch((error) => {
            this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
        })
    }

    handleSave() {

        this.setState({ isLoading: true, responseMessage: '', isError: false });
        let updatedRecords = this.state.editedScopeRow
        let email = this.props.user.user.Email
        let finalObject = [];
        updatedRecords.map(function (data) {
            let record = {
                'SolutionInScope_Value': data.SolutionInScope_Value,
                'Visible_Flag': (data.Visible_Flag === "true" || data.Visible_Flag === true) ? true : false,
                'Updated_By': email,
                'TRAN_AN_SUPP_AUTO_ELIGIBLE' : data.TRAN_AN_SUPP_AUTO_ELIGIBLE == true ? 'Yes' : 'No',
                'UNCLASS_SUPP_AUTO_ELIGIBLE' : data.UNCLASS_SUPP_AUTO_ELIGIBLE == true ? 'Yes' : 'No',
                'DIRECT_YES_SUPP_SCC_ELIGIBLE' : data.DIRECT_YES_SUPP_SCC_ELIGIBLE == true ? 'Yes' : 'No',
                'FLDG_IN_SCOPE' : data.FLDG_IN_SCOPE == true ? 'Yes' : 'No',
                'FG_IN_AUTO_ELG' : data.FG_IN_AUTO_ELG == true ? 'Yes' : 'No',
                'SUPPLIER_ELIGIBLE': data.SUPPLIER_ELIGIBLE == true ? 'Yes' : 'No',
                'CATEGORY_IN_SCOPE': data.CATEGORY_IN_SCOPE == true ? 'Yes' : 'No',
                'Id': data.Id,
            }
            finalObject.push(record)
        });

        this.props.updateSolutionInScopeValues(finalObject).then((response) => {
            if (response.status === 200) {
                this.setState({
                    apiData: response.data.Result, editedScopeRow: [],
                    isLoading: false, responseMessage: this.props.content.successMessage,
                    isError: false
                });
            }
            else {
                this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true });
            }
        }).catch((error) => {
            this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true });
        })
    }

    componentWillUnmount() {
        this.props.resetError()
    }

    render() {
        var _instance = this
        var data = _instance.state.apiData !== null && _instance.state.apiData
        var account_yield = data.map(function (data, index) {
            data.TRAN_AN_SUPP_AUTO_ELIGIBLE = (data.TRAN_AN_SUPP_AUTO_ELIGIBLE == null || data.TRAN_AN_SUPP_AUTO_ELIGIBLE == "No" || data.TRAN_AN_SUPP_AUTO_ELIGIBLE == false) ? false : true ;
            data.UNCLASS_SUPP_AUTO_ELIGIBLE = (data.UNCLASS_SUPP_AUTO_ELIGIBLE == null || data.UNCLASS_SUPP_AUTO_ELIGIBLE == "No" || data.UNCLASS_SUPP_AUTO_ELIGIBLE == false) ? false : true ;
            data.DIRECT_YES_SUPP_SCC_ELIGIBLE = (data.DIRECT_YES_SUPP_SCC_ELIGIBLE == null || data.DIRECT_YES_SUPP_SCC_ELIGIBLE == "No" || data.DIRECT_YES_SUPP_SCC_ELIGIBLE == false) ? false : true ;
            data.FLDG_IN_SCOPE = (data.FLDG_IN_SCOPE == null || data.FLDG_IN_SCOPE == "No" || data.FLDG_IN_SCOPE == false)  ? false : true ;
            data.FG_IN_AUTO_ELG = (data.FG_IN_AUTO_ELG == null || data.FG_IN_AUTO_ELG == "No" || data.FG_IN_AUTO_ELG == false) ? false : true ;
            data.SUPPLIER_ELIGIBLE = (data.SUPPLIER_ELIGIBLE == null || data.SUPPLIER_ELIGIBLE == "No" || data.SUPPLIER_ELIGIBLE == false) ? false : true ;
            data.CATEGORY_IN_SCOPE = (data.CATEGORY_IN_SCOPE == null || data.CATEGORY_IN_SCOPE == "No" || data.CATEGORY_IN_SCOPE == false) ? false : true ;
            return (<TableRows
                currentRowData={data}
                index={index}
                readOnly={_instance.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? false : true}
                trackScopeVisibilityChanges={_instance.trackScopeVisibilityChanges}
            />
            );
        });

        return (
            <div className="grid-x" style={{ width: '100%' }}>
                <div className="medium-12">
                    {(this.state.isLoading) ?
                        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            src={path + `images/ajax-loader.gif`} alt="" /> : null}
                </div>
                <div className="medium-12">
                    <div className="grid-x">
                        <div className="medium-5 float-left" style={{ fontSize: '1.2rem', paddingBottom: '8px' }}>{BreakLine(this.props.content.scopeValueHeader)}</div>
                        <div className="medium-7 float-left" style={{ display: 'flex' }} >
                            {this.state.isError ?
                                <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.responseMessage}</div> :
                                <div style={{ margin: '0 0 0.5rem', color: '#4fb81c' }}>{this.state.responseMessage}</div>
                            }
                        </div>
                        <div className="medium-3"></div>
                    </div>
                    <div className="grid-x">
                        <div className="medium-11 float-left pb10">
                            {/* {this.props.content.accountYieldDesc} */}
                        </div>
                        {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ?
                            <div className="medium-1 pb5">
                                <button className={classNames('float-right',
                                    {
                                        'hand-cursor': this.state.isLoading ? false : (this.state.editedScopeRow.length > 0),
                                        'gray-scale': this.state.isLoading ? true : (this.state.editedScopeRow.length === 0)
                                    })}
                                    onClick={this.handleSave}
                                    disabled={this.state.isLoading ? true : this.state.editedScopeRow.length === 0} type="submit" float >
                                    <img width="25" title={this.props.content.save} src="images/save_active.svg" />
                                </button>
                            </div> : null}
                    </div>
                </div>
                {this.state.apiData.length > 0 ?
                    <div className="medium-12">
                        <Form model="netwrokEnablementMatchTypeParameter">
                            <div className="basic-table">
                                <table>
                                    <thead>
                                        <tr className="eachRow">
                                            <th>{this.props.content.solutionInScopeValue}</th>
                                            <th>{this.props.content.visibilityFlag}</th>
                                            <th>Enable Only Transacting SAP Business Network Suppliers</th>
                                            <th>Are Catalogs In Scope</th>
                                            <th>Transacting SAP Business Network Supplier Automation Eligible</th>
                                            <th>Unclassified suppliers automation eligible</th>
                                            <th>When Direct = Yes, supplier is SCC eligible</th>
                                            <th>Fieldglass in scope?</th>
                                            <th>Fieldglass matched suppliers automatically Fieldglass eligible</th>
                                            <th>{this.props.content.updatedBy}</th>
                                            <th>{this.props.content.updatedOn}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {account_yield}
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </div>
                    : null
                }
            </div>
        )
    }
}

class TableRows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transactionEligible: false,
            unclassifiedEligible: false,
            sCCEligible: false,
            fieldglass: false,
            fieldglassEligible: false
        };
    }

    onChangeEvent(val) {
        var editingRow = this.props.currentRowData;
        let editedValue = val.target.value.trim()
        if (editedValue.toString() !== editingRow[val.target.id].toString()) {
            editingRow[val.target.id] = editedValue
            this.props.trackScopeVisibilityChanges(editingRow)
        }
    }
    selectIstransactionEligibleChk(e) {
        //this.setState({ transactionEligible: !this.state.transactionEligible }, () => {
            var editingRow = this.props.currentRowData;
            editingRow['TRAN_AN_SUPP_AUTO_ELIGIBLE'] = !editingRow['TRAN_AN_SUPP_AUTO_ELIGIBLE'] //= true ? 'Yes' : 'No';
            this.props.trackScopeVisibilityChanges(editingRow);
        //});
    }
    selectIsSupplierEligibleChk(e) {
        //this.setState({ transactionEligible: !this.state.transactionEligible }, () => {
            var editingRow = this.props.currentRowData;
            editingRow['SUPPLIER_ELIGIBLE'] = !editingRow['SUPPLIER_ELIGIBLE'] //= true ? 'Yes' : 'No';
            this.props.trackScopeVisibilityChanges(editingRow);
        //});
    }
    selectIsCatalogInScopeChk(e) {
        //this.setState({ transactionEligible: !this.state.transactionEligible }, () => {
            var editingRow = this.props.currentRowData;
            editingRow['CATEGORY_IN_SCOPE'] = !editingRow['CATEGORY_IN_SCOPE'] //= true ? 'Yes' : 'No';
            this.props.trackScopeVisibilityChanges(editingRow);
        //});
    }

    

    selectIsunclassifiedEligibleChk(e) {
        this.setState({ unclassifiedEligible: !this.state.unclassifiedEligible }, () => {
            var editingRow = this.props.currentRowData;
            editingRow['UNCLASS_SUPP_AUTO_ELIGIBLE'] = !editingRow['UNCLASS_SUPP_AUTO_ELIGIBLE'] //= true ? 'Yes' : 'No';
            this.props.trackScopeVisibilityChanges(editingRow);
            //this.props.userData.UnclassifiedEligible = this.state.unclassifiedEligible;
        });
    }

    selectIssCCEligibleChk(e) {
        this.setState({ sCCEligible: !this.state.sCCEligible }, () => {
            var editingRow = this.props.currentRowData;
            editingRow['DIRECT_YES_SUPP_SCC_ELIGIBLE'] = !editingRow['DIRECT_YES_SUPP_SCC_ELIGIBLE'] //= true ? 'Yes' : 'No';
            this.props.trackScopeVisibilityChanges(editingRow);
            //this.props.userData.SCCEligible = this.state.sCCEligible;
        });
    }

    selectIsfieldglassChk(e) {
        this.setState({ fieldglass: !this.state.fieldglass }, () => {
            var editingRow = this.props.currentRowData;
            editingRow['FLDG_IN_SCOPE'] = !editingRow['FLDG_IN_SCOPE'] //= true ? 'Yes' : 'No';
            this.props.trackScopeVisibilityChanges(editingRow);
            //this.props.userData.Fieldglass = this.state.fieldglass;
        });
    }

    selectIsfieldglassEligibleChk(e) {
        this.setState({ fieldglassEligible: !this.state.fieldglassEligible }, () => {
            var editingRow = this.props.currentRowData;
            editingRow['FG_IN_AUTO_ELG'] = !editingRow['FG_IN_AUTO_ELG'] //= true ? 'Yes' : 'No';
            this.props.trackScopeVisibilityChanges(editingRow);
            //this.props.userData.FieldglassEligible  = this.state.fieldglassEligible ;
        });
    }

    render() {
        var bindValues = this.props.currentRowData;
        return (
            <tr className="eachRow">
                <td model="standardAccountYield.ACCOUNT_TYPE">{bindValues.SolutionInScope_Value}</td>

                <td>
                    <select style={{ height: '30px', width: '100px' }}
                        id={'Visible_Flag'}
                        onChange={this.onChangeEvent.bind(this)}
                        value={bindValues.Visible_Flag}
                        readOnly={this.props.readOnly}
                        disabled={this.props.readOnly}
                    >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </td>
                <td model="standardAccountYield.supplierEligible"> <CheckboxE disabled={false} checked={bindValues.SUPPLIER_ELIGIBLE} onClick={(e) => this.selectIsSupplierEligibleChk(e)} /> </td>                
                <td model="standardAccountYield.catalogInScope"> <CheckboxE disabled={false} checked={bindValues.CATEGORY_IN_SCOPE} onClick={(e) => this.selectIsCatalogInScopeChk(e)} /> </td>                
                <td model="standardAccountYield.transactionEligible"> <CheckboxE disabled={false} checked={bindValues.TRAN_AN_SUPP_AUTO_ELIGIBLE} onClick={(e) => this.selectIstransactionEligibleChk(e)} /> </td>
                <td model="standardAccountYield.unclassifiedEligible"> <CheckboxE disabled={false} checked={bindValues.UNCLASS_SUPP_AUTO_ELIGIBLE} onClick={(e) => this.selectIsunclassifiedEligibleChk(e)} /> </td>
                <td model="standardAccountYield.sCCEligible"> <CheckboxE disabled={false} checked={bindValues.DIRECT_YES_SUPP_SCC_ELIGIBLE} onClick={(e) => this.selectIssCCEligibleChk(e)} /> </td>
                <td model="standardAccountYield.fieldglass"> <CheckboxE disabled={false} checked={bindValues.FLDG_IN_SCOPE} onClick={(e) => this.selectIsfieldglassChk(e)} /> </td>
                <td model="standardAccountYield.fieldglassEligible"> <CheckboxE disabled={false} checked={bindValues.FG_IN_AUTO_ELG} onClick={(e) => this.selectIsfieldglassEligibleChk(e)} /> </td>
                <td model="standardAccountYield.UpdatedBy">{bindValues.Updated_By}</td>
                <td model="standardAccountYield.UpdatedOn">{moment(bindValues.Updated_Date).format('L')}</td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        ui: state.content.ui,
        user: state.default,
        spendDetails: state.spendDetails,
        content: state.content.menu.EnablementParametersManager[0].SELogicAndAnalysisTab
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        resetError: () => dispatch(resetError()),
        fetchSolutionInScopeValues: () => dispatch(fetchSolutionInScopeValues()),
        updateSolutionInScopeValues: (data) => dispatch(updateSolutionInScopeValues(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SolutionInScopeValues)


