import React from 'react';
import { connect } from 'react-redux'
import SubTabsPanel from '../../Tabs/SubTabsPanel'
import EnablementDetails from './EnablementDetails'
import OnboardingWorkstreamDetails from './OnboardingWorkstreamDetails'
// import EnablementRecommendations from './EnablementRecommendations'
import NetworkMatchTab from './NetworkMatchTab';
import SpendDocumentBuyProcessTab from './SpendDocumentBuyProcessTab'
import CategoryInsightsTab from './CategoryInsightsTab'
import CategoryOutOfScopeTab from './CategoryOutOfScopeTab'
import GlobalTab from './GlobalTab'
import VideoHelp from 'components/UI/VideoHelp'
import EnablementInsightTabFilters from 'components/Spend/EnablementInsight/EnablementInsightTabFilters'
import Button from 'UI/Button'
import { withRouter } from 'react-router';
import FaAngleRight from 'react-icons/lib/fa/angle-left'


import {
    enablementInsightSuccess,
    enablementInsightFailure,
    enablementInsightLoading,
    resetError,
    getEnablementDetails,
    getEnablementFiltersDetails,
    redirectFromEnablementTab
    ,redirectFromWizard
} from 'actions/spendActions'

class EnablementInsightTabContainer extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.state = {
            tabId: 1,
            tabs: [],
            tabsWithoutCapIQ: [],
            tabsWithoutParentCapIQ: [],
            tabsWithoutCapIQAndParentCapIQ: [],
            isCapIQLicesend: false,
            classificationData: [],

            filterData: {
                buyerOption: [],
                businessUnitOption: [],
                erpSystemOption: [],
                l4IndustryOption: [],
                erpCodeOption: [],
                erpDescOption: [],
                sicCodeOption: [],
                sicDescOption: [],
                goodsOption: [],
                invoiceCountryOption: [],
                legalInvoiceOption: [],
            },

            inputParam: {
                DMC_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.DMC_ID : 0
                , VS_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.VS_ID : 0,
                User_Email: this.props.spendDetails.currentActiveRequest !== undefined &&
                    this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester
            },
            filterValues: {}
        }

        this.dispatchTabChange = this.dispatchTabChange.bind(this)
        this.intializeSupplierTabs = this.intializeSupplierTabs.bind(this)
        this.setFilters = this.setFilters.bind(this)
        this.redirect = this.redirect.bind(this)
    }

    setFilters(value) {
        this.setState({ filterValues: value }, this.intializeSupplierTabs)
    }

    componentDidMount() {
        this.props.redirectFromWizard(false);
        this.intializeSupplierTabs();
        //this.loadFiltersData();
        // this.setState({ isCapIQLicesend: this.props.user.user.applicationFeatures.findIndex(a => a.Action === this.props.capIQ.capIQAction) > -1 ? true : false })
    }

    intializeSupplierTabs() {

        const tabs = [
            // {
            //    id: 1,
            //    label: "Enablement Recommendations",
            //    component: (<div className="grid-x"><EnablementRecommendations filterData={this.state.filterValues} /></div>)
            // },
            {
                id: 1,
                label: "Enablement Strategy",
                component: (<div className="grid-x"><EnablementDetails filterData={this.state.filterValues} /></div>)
            },
            {
                id: 7,
                label: "Onboarding Workstreams",
                component: (<div className="grid-x"><OnboardingWorkstreamDetails filterData={this.state.filterValues} /></div>)
            },


            {
                id: 3,
                label: "Network Match",
                component: (<div className="grid-x"><NetworkMatchTab filterData={this.state.filterValues} /></div>)
            }
            ,
            {
                id: 4,
                label: "Buyer Value & Buy Process", //"Spend, Document, & Buy Process",
                component: (<div className="grid-x"><SpendDocumentBuyProcessTab filterData={this.state.filterValues} /></div>)
            }
            ,
            {
                id: 5,
                label: "Category Insight",
                component: (<div className="grid-x"><CategoryInsightsTab filterData={this.state.filterValues} /></div>)
            },
            // {
            //     id: 8,
            //     label: "Category Out of Scope",
            //     component: (<div className="grid-x"><CategoryOutOfScopeTab filterData={this.state.filterValues} /></div>)
            // },
            {
                id: 6,
                label: "Global",
                component: (<div className="grid-x"><GlobalTab filterData={this.state.filterValues} /></div>)
            }
        ];

        this.setState({
            tabs: tabs,
            tabId: 1
        })
    }


    dispatchTabChange(tabId) {
        this.setState({
            tabId: tabId,
        })
    }

    redirect() {
        this.props.history.push('/yourSpend');
        this.props.redirectFromEnablementTab(true);
        // this.props.dispatchTabChange(1)
        // this.props.reDir()
    }

    render() {
        return (
            <div className="grid-x pt5">
                <div className="medium-8">
                    <div className="tab-title">{(this.props.content.title)}{<VideoHelp screen={{ module: 'Spend', screenName: 'Enablement Insight' }} />}</div>
                    <div className="tab-description">{this.props.content.subTitle}</div>
                </div>
                <div className="medium-4 float-right">
                    <a className="redirectButton float-right pr50" onClick={this.redirect}>{"< Back to Enablement Strategy Wizard"}</a>
                </div>
                <div className="medium-12 pt10">
                    <EnablementInsightTabFilters filterData={this.props.filterData} setFilters={this.setFilters} ></EnablementInsightTabFilters>
                </div>
                <div className="medium-12">
                    {<SubTabsPanel
                        tabs={this.state.tabs}
                        tabId={1}
                        dispatchTabChange={this.dispatchTabChange}
                        dispatchLastButtonClick={this.dispatchLastButtonClick}
                    />}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        content: state.content.enablementInsight,
        //content: state.content.subLeverCalculations,
        ui: state.content.ui,
        currency: decodeURIComponent(state.valueStory.Currency),
        userData: state.valueStory,
        spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
        benchMarkCalculations: state.benchMarksData,
        allContent: state.content,
        language: state.language,
        spendDetails: state.spendDetails,
        user: state.default
    }
}

const mapDispatchToProps = dispatch => {
    return {
        enablementInsightSuccess: message => dispatch(enablementInsightSuccess(message)),
        enablementInsightFailure: error => dispatch(enablementInsightFailure(error)),
        enablementInsightLoading: bool => dispatch(enablementInsightLoading(bool)),
        resetError: error => dispatch(resetError(error)),
        getEnablementDetails: (data) => dispatch(getEnablementDetails(data)),
        getEnablementFiltersDetails: (data) => dispatch(getEnablementFiltersDetails(data)),
        redirectFromEnablementTab: (flag) => dispatch(redirectFromEnablementTab(flag)),
        redirectFromWizard: (flag) => dispatch(redirectFromWizard(flag))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EnablementInsightTabContainer));