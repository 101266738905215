import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import ReactDOM from "react-dom";
import ReactPaginate from 'react-paginate';
import { path, dropdownColourStyles } from 'Constants'
import { ExportToCsv } from 'utils/ExportToCsv';
import classNames from "classnames";
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment'
import { routes } from 'Constants'
import * as FileSaver from "file-saver";

import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs/dist/exceljs';
import axios from 'axios'

import {
    integralPartWithBlank,
    roundFix, formatThousands, getNonEmptyValue, roundFixWithBlank, formatThousandsWithBlank, mergeArrayByKey, RoundFormatter2,
    IntegralpartFormatter, IntegralpartPercentFormatter, binarySearchIndex, formatter, filterRenderer, editorComponent
} from 'utils/Utils'
import {
    columnFieldModalStyle,
    suggestedValueContentStyle,ExcelUploadColumns,
    backdropStyle, exportOptionsModalStyle, changeModalContentStyle, modalContentStyle, changeModalStyle
} from 'Constants';

import {
    dropdownValues, directSpendDropdown, scopeValues, goodsValues, requesitionValues, aribaBuyProcessValues,
    workingCaptialSegmentationValues, VendorLinkFormatter, IndustryLinkFormatter, discountingVals, termRationalizationVals
}
    from '../SpendConstants'

import Modal from 'reboron/OutlineModal'
import { RadioButton } from 'material-ui';
// Settings
import Select from 'react-select'
import {
    getDetailedFileSuccess,
    getDetailedFileLoading,
    getDetailedFileFailure,
    getDetailedFileSpendData,
    getSpendColumnsConfiguration,
    getNdlColumnsConfiguration,
    getNdlReportData,
    detailedFileFilters,
    detailedFileFilterText,
    selectedIndustryDetails,
    selectedEnablementWaveDetails,
    insertDetailedFileRecords,
    pendingChanges,
    defineScopeLoading,
    setResultSummaryDetails,
    setTopDetails,
    getSelectedColumn,
    addSelectedColumn,
    removeColumnsForRequest,
    selectedCapIqIdDetails,
    getVendorFiles,
    insertReportLogDetails,
    //getReportLogDetails
} from 'actions/spendActions'
import TextEditor from 'UI/TextEditor';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data, Filters, Editors, Menu } from "react-data-grid-addons";
import Button from 'UI/Button';
import TextfilterRenderer from 'UI/TextFilterRenderer';
import NumericFilterRenderer from 'UI/NumericFilterRenderer';
import UserCommentsPopUp from '../UserCommentsPopUp'
import IndustryOverridePopUp from '../IndustryOverridePopUp'
import EnablementWavePopUp from '../EnablementWavePopUp'
import ColumnFields from '../ColumnFields'
import Popup from "reactjs-popup";
import VideoHelp from 'components/UI/VideoHelp'
import { PopupE,SelectE } from 'UI/CustomComponents/Controls';
import CapIQSearch from './CapIQSearch';
import UploadUserDefinedFields from './UploadUserDefinedFields'
import VendorUploadFilesModalContainer from './VendorUploadFilesModalContainer';
const changeBackdropStyle = Object.assign({}, backdropStyle);
changeBackdropStyle.zIndex = '1050';
const alertModalStyle = Object.assign({}, exportOptionsModalStyle);
const downloadThreshold = 13000;
alertModalStyle.zIndex = '1060';

const { ContextMenu, MenuItem, SubMenu, ContextMenuTrigger } = Menu;

const selectors = Data.Selectors;
const {
    NumericFilter,
    AutoCompleteFilter,
    MultiSelectFilter,
    SingleSelectFilter
} = Filters;

class SpendFilesContainer extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isError: 'No',
            responseMsg: '',
            finalSpendData: [],
            RequestIds: [],
            selectedScopes: '',
            fileDetails: [],
            scopes: [],
            pageCount: 1,
            filters: {},
            perPage: 5000,
            paginatedRowFrom: 0,
            paginatedRowTo: 5000,
            columnDefs: [],
            rowData: [],
            filteredRows: [],
            columns: [],
            exportNonSensitiveColumns: [],
            exportEnablementInsightColumns: [],
            exportMatchAndClassificationColumns: [],
            exportAllColumns: [],
            ndlColumnsDoNotShareWithCustomer: [],
            ndlColumns: [],
            ndlColumnsEnhanced: [],
            ndlParentAccountInfoColumns : [],
            ndlCustFacingColumns : [],
            ndlData: [],
            exportCustomerViewColumns: [],
            userDefinedColumns: [],
            pageCountOption: [{ label: '1,000', value: 1000 }, { label: '5,000', value: 5000 }, { label: '10,000', value: 10000 }, { label: '15,000', value: 15000 }, { label: '30,000', value: 30000 }],
            selectedPageCount: 50,
            filteredRows: [],
            cellUpdateCss: 'bisque',
            open: false,
            openUserCommentsPopUp: false,
            openEnablementInsightPopUp: false,
            userComments: '',
            userCommentsRowNo: -1,
            userCommentInsideCell: '',
            userCommentOverrides: {},
            industryOverrideRowNo: -1,
            pendingRecords: [],
            warningRecords: [],
            errorRecords: [],
            exclusionReason_directSpendUpdates: [],
            warningDiv: true,
            errorDiv: true,
            csvRows: [],
            isFilterVisible: false,
            cleanIndustryOverrides: {},
            confirmedflagTrans: {},
            isCapIQLicesend: false,
            filterArray: [],
            filterDiv: [],
            filterFlag: false,
            summationArray: {},
            filteredSummationArray: {},
            userSelectedColumns: [],
            selectedSupplier: -1,
            exportAction: 'customerViewColumns',
            isSaveInProgress: false,
            openCapIq: false,
            capIqIdOverrideRowNo: -1,
            capIQIdOverrides: {},
            currentCapIqId: '',
            dummyScroll: false,
            actualScroll: false,
            enablementWaveOverrides: {},
            enablementWaveInputValue: null,
            headerToColumn: {},
            exportMC_Cloumns: false,
            sortByOption:[
                { value: "AVATAR_WAVE_SCORE", label: "Buyer Value"},
                { value: "COMBINED_WIZARD_PRIORITY_SCORE", label: "Enablement Strategy Value"},
                { value: "ENABLEMENT_WAVE", label: "Enablement Wave"},
                { value: "REF_NUMBER", label: "Original Order"},
          ],
          selectedSortBy: "AVATAR_WAVE_SCORE",
          sortByName: "Intelligent Enablement Match Spend & Document Score",
          gridSortBy: "AVATAR_WAVE_SCORE",
          gridSortDirection : 'ASC',
        }
        this.formatSpendData = this.formatSpendData.bind(this)
        this.ndlDownload = this.ndlDownload.bind(this)
        this.openNdlPopUp = this.openNdlPopUp.bind(this)   
        this.openDownloadPopup = this.openDownloadPopup.bind(this)  
        this.handlePageClick = this.handlePageClick.bind(this)
        this.getValidFilterValues = this.getValidFilterValues.bind(this)
        this.handleFilterChange = this.handleFilterChange.bind(this)
        this.handlePageCountChange = this.handlePageCountChange.bind(this)
        this.industryOverride = this.industryOverride.bind(this)
        this.closePopUp = this.closePopUp.bind(this)
        this.outsidePopupClick = this.outsidePopupClick.bind(this)
        this.addUserComments = this.addUserComments.bind(this)
        this.clearUserComments = this.clearUserComments.bind(this)
        this.closeUserCommentsPopUp = this.closeUserCommentsPopUp.bind(this)
        this.outsideUserCommentsPopupClick = this.outsideUserCommentsPopupClick.bind(this)
        this.findRowIndex = this.findRowIndex.bind(this)
        this.filterValidationRecords = this.filterValidationRecords.bind(this)
        this.warningToggle = this.warningToggle.bind(this)
        this.errorToggle = this.errorToggle.bind(this)
        this.isCellEditable = this.isCellEditable.bind(this)
        this.onSave = this.onSave.bind(this)
        this.onColumnSelection = this.onColumnSelection.bind(this)
        this.hasRecordErrors = this.hasRecordErrors.bind(this)
        this.filterChange = this.filterChange.bind(this)
        this.setColumnHelpTextAndHardRefresh = this.setColumnHelpTextAndHardRefresh.bind(this)
        this.setHeaderHelpText = this.setHeaderHelpText.bind(this)
        this.columnResize = this.columnResize.bind(this)
        this.exportCsv = this.exportCsv.bind(this)
        this.exportExcel = this.exportExcel.bind(this)        
        this.handleClick = this.handleClick.bind(this)
        this.clearIndustryOverride = this.clearIndustryOverride.bind(this)
        this.closeColumnFieldPopup = this.closeColumnFieldPopup.bind(this)
        this.filterToggle = this.filterToggle.bind(this)
        this.removeFilter = this.removeFilter.bind(this)
        this.expandFilter = this.expandFilter.bind(this)
        this.getRows = this.getRows.bind(this)
        this.hideEmptyColumns = this.hideEmptyColumns.bind(this)
        this.radioButtonChecked = this.radioButtonChecked.bind(this)
        this.capIqRadioButtonClicked = this.capIqRadioButtonClicked.bind(this)
        this.capIqDataCleared = this.capIqDataCleared.bind(this)
        this.capIqIdOverride = this.capIqIdOverride.bind(this)
        this.closeCapIqPopUp = this.closeCapIqPopUp.bind(this)
        this.outsideCapIqIdPopupClick = this.outsideCapIqIdPopupClick.bind(this)
        this.closeEnablementInsightPopUp = this.closeEnablementInsightPopUp.bind(this)
        this.outsideEnablementInsightPopupClick = this.outsideEnablementInsightPopupClick.bind(this)
        this.enablementWaveInput = this.enablementWaveInput.bind(this);
        this.clearEnablementWaveOverride = this.clearEnablementWaveOverride.bind(this);
        this.actions = this.getApplicationFeatureActions()
        this.getHeaderGroups = this.getHeaderGroups.bind(this)
    }

    setColumnHelpTextAndHardRefresh(paginatedRows, columnDefs, isFilterVisible = this.state.isFilterVisible) {
        this.timer = setInterval(() => {
            this.setHeaderHelpText(columnDefs)
        }, 5000);


        if (paginatedRows.length === 0) {
            var grid = this.myOpenGrid;
            if (grid) {
                return grid.base.props.columns
            }
        } else {
            columnDefs = columnDefs.map((col, i) => ({
                ...col,
                hash: new Date().getTime()
            }));
        }

        return columnDefs
    }


    setHeaderHelpText(columnDefs) {
        var grid = this.myOpenGrid;
        if (grid === null || grid === undefined) {
            return;
        }

        var gridCells = ReactDOM.findDOMNode(grid).querySelectorAll('.react-grid-HeaderCell');
        gridCells.forEach((item) => {
            if (item.classList.length > 1) {
                return
            }
            const headerText = item.innerText;
            var col = columnDefs.findIndex((colDef) => {
                return colDef.name === headerText
            })

            if (col !== -1) {
                item.setAttribute('title', columnDefs[col].helpText)
                if (columnDefs[col].editable) {
                    item.style.backgroundColor = '#ff8c00';
                }
            }
        })

        // const canvas = document.getElementsByClassName('react-grid-Canvas')[0]
        // const dummyScroll = document.getElementsByClassName('scroll-dummy-child')[0]
        // let columnDefLength = this.state.columnDefs.length;
        // dummyScroll.style.width = (columnDefLength * (columnDefLength > 14 ? 160 : 120)) + 'px'
        // const dummyElement = document.getElementsByClassName('scroll-dummy')[0]

        // if (this.actions.indexOf(routes.businessValue) > -1) {
        //     dummyElement.style.width = '90vw'

        // } else {
        //     dummyElement.style.width = '96vw'
        // }

        // canvas.onscroll = () => {
        //     if (this.state.dummyScroll) {
        //         this.setState({ dummyScroll: false })
        //         return
        //     }

        //     this.setState({ actualScroll: true }, () => {
        //         const dummy = document.getElementsByClassName('scroll-dummy')[0]
        //         const length = this.state.columnDefs.length * 120
        //         const dummyScrollRatio = document.documentElement.clientWidth / length
        //         dummy.scrollLeft = canvas.scrollLeft// * dummyScrollRatio
        //     })
        // }
    }

    filterChange() {
        const isFilterVisible = this.state.isFilterVisible
        this.filteredColumnValues = []

        this.setState({ isFilterVisible: !isFilterVisible })
    }

    hasRecordErrors() {
        const errorRecords = this.state.errorRecords
        var errorRecordSize = 0;
        Object.values(errorRecords).forEach((item) => {
            errorRecordSize += item.length;
        })

        return errorRecordSize > 0
    }

    onColumnSelection(param) {
        if ((Array.isArray(param) && param.length > 0) || !isNaN(parseInt(param))) {
            this.props.addSelectedColumn(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Id, param).then((response) => {
                let selectedCols = response.data.Result;
                let columns = this.state.columns;
                let columnDefs = [];
                columns.map((column) => {
                    if (selectedCols.indexOf(column.id) > -1) {
                        let col = column.value;
                        col.hash = new Date().getTime();
                        column.isSelected = true;
                        columnDefs.push(col);
                    } else {
                        column.isSelected = false;
                    }
                })
                this.originalColumns = cloneDeep(columns);
                const headerToColumn = this.getHeaderGroups(columnDefs)

                this.setState({
                    columns: columns,
                    columnDefs: columnDefs.length === 0 ? [] : columnDefs.sort((a, b) => { return a.screenSeq - b.screenSeq }),
                    headerToColumn: headerToColumn
                }, this.removeFilter(this.state.filters, undefined, true))
            });
        } else if (param === "DEFAULT") {
            this.props.removeColumnsForRequest(this.props.user.user.Id, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.spendDetails.currentActiveRequest.DMC_ID).then(
                (response) => {
                    let columns = this.state.columns;
                    let paramTosave = [];
                    let columnDefs = [];
                    columns.forEach(column => {
                        if (column.is_default === "Yes" || column.internalEnablementInsightView == 'Yes') {
                            let col = column.value;
                            col.hash = new Date().getTime();
                            column.isSelected = true;
                            columnDefs.push(col);
                            paramTosave.push(column.id)
                        } else {
                            column.isSelected = false;
                        }
                    });

                    if (paramTosave.length > 0) {
                        this.props.addSelectedColumn(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Id, paramTosave)
                    }

                    const headerToColumn = this.getHeaderGroups(columnDefs)

                    this.setState({
                        columns: columns,
                        columnDefs: columnDefs.length === 0 ? [] : columnDefs.sort((a, b) => { return a.screenSeq - b.screenSeq }),
                        filters: {},
                        headerToColumn: headerToColumn
                    }, this.getRows([...this.state.rowData], {}))

                },
                (error) => { console.log(error) }
            )
        } else if (param === "FlightPlanning") {
            this.props.removeColumnsForRequest(this.props.user.user.Id, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.spendDetails.currentActiveRequest.DMC_ID).then(
                (response) => {
                    let columns = this.state.columns;
                    let paramTosave = [];
                    let columnDefs = [];
                    columns.forEach(column => {
                        if (column.columnCategory === "Enablement Insight" || column.id === "VENDOR_ID" || column.id === "VENDOR_NAME" || column.id === "AN_MATCH_CRITERIA" || column.id === "MATCH_CONFIDENCE" || column.id === "PROPOSED_ENABLEMENT_MODE") {
                            let col = column.value;
                            col.hash = new Date().getTime();
                            column.isSelected = true;
                            columnDefs.push(col);
                            paramTosave.push(column.id)
                        } else {
                            column.isSelected = false;
                        }
                    });
                    if (paramTosave.length > 0) {
                        this.props.addSelectedColumn(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Id, paramTosave)
                    }

                    const headerToColumn = this.getHeaderGroups(columnDefs)

                    this.setState({
                        columns: columns,
                        columnDefs: columnDefs.length === 0 ? [] : columnDefs.sort((a, b) => { return a.screenSeq - b.screenSeq }),
                        filters: {},
                        headerToColumn: headerToColumn
                    }, this.getRows([...this.state.rowData], {}))

                },
                (error) => { console.log(error) }
            )
        } else if (param === "InternalEnablementInsightView") {
            this.props.removeColumnsForRequest(this.props.user.user.Id, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.spendDetails.currentActiveRequest.DMC_ID).then(
                (response) => {
                    let columns = this.state.columns;
                    let paramTosave = [];
                    let columnDefs = [];
                    columns.forEach(column => {
                        if (column.internalEnablementInsightView == 'Yes') {
                            let col = column.value;
                            col.hash = new Date().getTime();
                            column.isSelected = true;
                            columnDefs.push(col);
                            paramTosave.push(column.id)
                        } else {
                            column.isSelected = false;
                        }
                    });
                    if (paramTosave.length > 0) {
                        this.props.addSelectedColumn(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Id, paramTosave)
                    }

                    const headerToColumn = this.getHeaderGroups(columnDefs)

                    this.setState({
                        columns: columns,
                        columnDefs: columnDefs.length === 0 ? [] : columnDefs.sort((a, b) => { return a.screenSeq - b.screenSeq }),
                        filters: {},
                        headerToColumn: headerToColumn
                    }, this.getRows([...this.state.rowData], {}))

                },
                (error) => { console.log(error) }
            )
        } else if (param === "ExternalEnablementInsightView") {
            this.props.removeColumnsForRequest(this.props.user.user.Id, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.spendDetails.currentActiveRequest.DMC_ID).then(
                (response) => {
                    let columns = this.state.columns;
                    let paramTosave = [];
                    let columnDefs = [];
                    columns.forEach(column => {
                        if (column.externalEnablementInsightView == 'Yes') {
                            let col = column.value;
                            col.hash = new Date().getTime();
                            column.isSelected = true;
                            columnDefs.push(col);
                            paramTosave.push(column.id)
                        } else {
                            column.isSelected = false;
                        }
                    });
                    if (paramTosave.length > 0) {
                        this.props.addSelectedColumn(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Id, paramTosave)
                    }

                    const headerToColumn = this.getHeaderGroups(columnDefs)

                    this.setState({
                        columns: columns,
                        columnDefs: columnDefs.length === 0 ? [] : columnDefs.sort((a, b) => { return a.screenSeq - b.screenSeq }),
                        filters: {},
                        headerToColumn: headerToColumn
                    }, this.getRows([...this.state.rowData], {}))

                },
                (error) => { console.log(error) }
            )
        } else if (param === "CategoryInsightsView") {
            this.props.removeColumnsForRequest(this.props.user.user.Id, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.spendDetails.currentActiveRequest.DMC_ID).then(
                (response) => {
                    let columns = this.state.columns;
                    let paramTosave = [];
                    let columnDefs = [];
                    columns.forEach(column => {
                        if (column.categoryInsightsView == 'Yes') {
                            let col = column.value;
                            col.hash = new Date().getTime();
                            column.isSelected = true;
                            columnDefs.push(col);
                            paramTosave.push(column.id)
                        } else {
                            column.isSelected = false;
                        }
                    });
                    if (paramTosave.length > 0) {
                        this.props.addSelectedColumn(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Id, paramTosave)
                    }

                    const headerToColumn = this.getHeaderGroups(columnDefs)

                    this.setState({
                        columns: columns,
                        columnDefs: columnDefs.length === 0 ? [] : columnDefs.sort((a, b) => { return a.screenSeq - b.screenSeq }),
                        filters: {},
                        headerToColumn: headerToColumn
                    }, this.getRows([...this.state.rowData], {}))

                },
                (error) => { console.log(error) }
            )
        } else if (param === "MandCView") {
            this.props.removeColumnsForRequest(this.props.user.user.Id, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.spendDetails.currentActiveRequest.DMC_ID).then(
                (response) => {
                    let columns = this.state.columns;
                    let paramTosave = [];
                    let columnDefs = [];
                    columns.forEach(column => {
                        if (column.matchAndClassification_export == 'Yes') {
                            let col = column.value;
                            col.hash = new Date().getTime();
                            column.isSelected = true;
                            columnDefs.push(col);
                            paramTosave.push(column.id)
                        } else {
                            column.isSelected = false;
                        }
                    });
                    if (paramTosave.length > 0) {
                        this.props.addSelectedColumn(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Id, paramTosave)
                    }

                    const headerToColumn = this.getHeaderGroups(columnDefs)

                    this.setState({
                        columns: columns,
                        columnDefs: columnDefs.length === 0 ? [] : columnDefs.sort((a, b) => { return a.screenSeq - b.screenSeq }),
                        filters: {},
                        headerToColumn: headerToColumn
                    }, this.getRows([...this.state.rowData], {}))

                },
                (error) => { console.log(error) }
            )
        } else if (param === "EnterpriseEnablementEstimatesView") {
            this.props.removeColumnsForRequest(this.props.user.user.Id, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.spendDetails.currentActiveRequest.DMC_ID).then(
                (response) => {
                    let columns = this.state.columns;
                    let paramTosave = [];
                    let columnDefs = [];
                    columns.forEach(column => {
                        if (column.enterpriseEnablementEstimatesView == 'Yes') {
                            let col = column.value;
                            col.hash = new Date().getTime();
                            column.isSelected = true;
                            columnDefs.push(col);
                            paramTosave.push(column.id)
                        } else {
                            column.isSelected = false;
                        }
                    });
                    if (paramTosave.length > 0) {
                        this.props.addSelectedColumn(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Id, paramTosave)
                    }

                    const headerToColumn = this.getHeaderGroups(columnDefs)

                    this.setState({
                        columns: columns,
                        columnDefs: columnDefs.length === 0 ? [] : columnDefs.sort((a, b) => { return a.screenSeq - b.screenSeq }),
                        filters: {},
                        headerToColumn: headerToColumn
                    }, this.getRows([...this.state.rowData], {}))

                },
                (error) => { console.log(error) }
            )
        } 
        else if (param === "SccEnableInsightView") {
            this.props.removeColumnsForRequest(this.props.user.user.Id, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.spendDetails.currentActiveRequest.DMC_ID).then(
                (response) => {
                    let columns = this.state.columns;
                    let paramTosave = [];
                    let columnDefs = [];
                    columns.forEach(column => {
                        if (column.sccEnableInsightView == 'Yes') {
                            let col = column.value;
                            col.hash = new Date().getTime();
                            column.isSelected = true;
                            columnDefs.push(col);
                            paramTosave.push(column.id)
                        } else {
                            column.isSelected = false;
                        }
                    });
                    if (paramTosave.length > 0) {
                        this.props.addSelectedColumn(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Id, paramTosave)
                    }

                    const headerToColumn = this.getHeaderGroups(columnDefs)

                    this.setState({
                        columns: columns,
                        columnDefs: columnDefs.length === 0 ? [] : columnDefs.sort((a, b) => { return a.screenSeq - b.screenSeq }),
                        filters: {},
                        headerToColumn: headerToColumn
                    }, this.getRows([...this.state.rowData], {}))

                },
                (error) => { console.log(error) }
            )
        }
        else if (param === "SccView") {
            this.props.removeColumnsForRequest(this.props.user.user.Id, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.spendDetails.currentActiveRequest.DMC_ID).then(
                (response) => {
                    let columns = this.state.columns;
                    let paramTosave = [];
                    let columnDefs = [];
                    columns.forEach(column => {
                        if (column.sccView == 'Yes') {
                            let col = column.value;
                            col.hash = new Date().getTime();
                            column.isSelected = true;
                            columnDefs.push(col);
                            paramTosave.push(column.id)
                        } else {
                            column.isSelected = false;
                        }
                    });
                    if (paramTosave.length > 0) {
                        this.props.addSelectedColumn(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Id, paramTosave)
                    }

                    const headerToColumn = this.getHeaderGroups(columnDefs)

                    this.setState({
                        columns: columns,
                        columnDefs: columnDefs.length === 0 ? [] : columnDefs.sort((a, b) => { return a.screenSeq - b.screenSeq }),
                        filters: {},
                        headerToColumn: headerToColumn
                    }, this.getRows([...this.state.rowData], {}))

                },
                (error) => { console.log(error) }
            )
        }
        else if (param === "AllSccView") {
            this.props.removeColumnsForRequest(this.props.user.user.Id, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.spendDetails.currentActiveRequest.DMC_ID).then(
                (response) => {
                    let columns = this.state.columns;
                    let paramTosave = [];
                    let columnDefs = [];
                    columns.forEach(column => {
                        if (column.allSccView == 'Yes') {
                            let col = column.value;
                            col.hash = new Date().getTime();
                            column.isSelected = true;
                            columnDefs.push(col);
                            paramTosave.push(column.id)
                        } else {
                            column.isSelected = false;
                        }
                    });
                    if (paramTosave.length > 0) {
                        this.props.addSelectedColumn(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Id, paramTosave)
                    }

                    const headerToColumn = this.getHeaderGroups(columnDefs)

                    this.setState({
                        columns: columns,
                        columnDefs: columnDefs.length === 0 ? [] : columnDefs.sort((a, b) => { return a.screenSeq - b.screenSeq }),
                        filters: {},
                        headerToColumn: headerToColumn
                    }, this.getRows([...this.state.rowData], {}))

                },
                (error) => { console.log(error) }
            )
        }
        else if (param === "HideEmptyColumns") {
            this.props.removeColumnsForRequest(this.props.user.user.Id, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.spendDetails.currentActiveRequest.DMC_ID).then(
                (response) => {
                    let modifiedColumns = this.hideEmptyColumns()
                    const headerToColumn = this.getHeaderGroups(modifiedColumns.defaultColumns)
                    this.setState({
                        columns: modifiedColumns.columns,
                        columnDefs: modifiedColumns.defaultColumns.length === 0 ? [] : modifiedColumns.defaultColumns.sort((a, b) => { return a.screenSeq - b.screenSeq }),
                        filters: {},
                        headerToColumn: headerToColumn
                    }, this.getRows([...this.state.rowData], {}))

                },
                (error) => { console.log(error) }
            )
        }
    }

    handlePageCountChange(value) {
        var result = this.state.pageCountOption.find(page => page.value === value)
        var pageCount = this.state.filteredRows.length / result.value
        this.setState({ perPage: value, paginatedRowFrom: 0, paginatedRowTo: result.value, pageCount: pageCount })
    }

    isCellEditable(e) {
        const editProperty = e.column.editProperty;

        if(e.column.key === 'EXCLUSION_REASON')
            return true;

        if (
            getNonEmptyValue(e.row['EXCLUSION_REASON']) === ''
            &&
            (e.column.key === 'SAP_ARIBA_BUY_PROCESS'
                || e.column.key === 'SOURCING_ELIGIBLE'
                || e.column.key === 'BUYING_INVOICING_ELIGIBLE'
                || e.column.key === 'REQUISITIONABLE'
                || e.column.key === 'REQUISITION_TYPE'
                || e.column.key === 'CONTRACT_INVOICING_ELIGIBLE'
                || e.column.key === 'COMMERCE_AUTOMATION_ELIGIBLE'
                || e.column.key === 'SUPP_CHAIN_COLLAB_ELIGIBLE'
                || e.column.key === 'SAP_FIELDGLASS_ELIGIBLE'
                || e.column.key === 'SAP_CONCUR_ELIGIBLE'
                || e.column.key === 'DISCOUNT_ELIGIBLE'
                || e.column.key === 'SPOT_BUY_ELIGIBLE'
                || e.column.key === 'PRIMARY_BUY_PROCESS_DELIVERED'
                || e.column.key === 'SECONDARY_BUY_PROCESS_DELIVERED'
                || e.column.key === 'OTHER_BUY_PROCESS_DELIVERED')) {
            return true;
        }

        if (e.column.key === 'OVERRIDE_INDUSTRY_ID' && this.state.open)
            return false;

        if (e.column.key === 'CAPIQ_COMPANY_ID' && this.state.openCapIq)
            return false;

        if (e.column.key === 'USER_COMMENT' && this.state.openUserCommentsPopUp)
            return false;

        if (e.column.key === 'ENABLEMENT_WAVE' && this.state.openEnablementInsightPopUp)
            return false;

        if (e.row['DATA_COLLECTIONS_SKEY'] == '')
            return false

        if (editProperty === 'Yes' && !this.props.readOnly) {
            return true;
        }

        if (editProperty === 'Override' && e.column.key === 'EXCLUSION_REASON')
            return e.row['SCOPE_SOURCE'] === 'Override'

        if (editProperty === 'OverrideWithExclusionReason' && getNonEmptyValue(e.row['EXCLUSION_REASON']) === '')
            return e.row['SCOPE_SOURCE'] === 'Override'


        return false;
    }

    handleSortByChange(data) {
        // var finalFilteredRows = [];
        // var finalRows = this.state.filteredRows;
        // finalRows.sort((a, b) => { return a['REF_NUMBER'] - b['REF_NUMBER'] });
        // if (data.value == 'ENABLEMENT_WAVE') {
        //     finalRows.sort(function (a, b) {
        //         return ('' + a[data.value]).localeCompare(b[data.value]);
        //     })
        // }
        // else
        //     finalRows.sort((a, b) => { return a[data.value] - b[data.value] });
        // finalFilteredRows = [...finalRows];
        // this.setState({ filteredRows: finalFilteredRows, selectedSortBy: data.value });
        
        // var finalFilteredRows = [];
        // var finalRows = this.state.filteredRows;
        // var comparer = this.downloadSortedRecords(data.value,'ASC');
        // finalRows = finalRows.sort(comparer);
        // finalFilteredRows = [...finalRows];
        // this.setState({ filteredRows: finalFilteredRows, selectedSortBy: data.value });


        //this.sortRows('REF_NUMBER','ASC');
        this.sortRows(data.value,'ASC');
        this.setState({ selectedSortBy: data.value });
    }

    columnResize(idx, width) {
        let column = this.state.columnDefs;
        const headerToColumn = { ...this.state.headerToColumn }
        column.map((value, index) => {
            if (index == (Number(idx))) {
                value.width = Number(width)
                const keys = Object.keys(headerToColumn)
                for (let idx2 = 0; idx2 < keys.length; idx2++) {
                    const key = keys[idx2]
                    const val = headerToColumn[key]
                    if (val.name === null && key === value.name) {
                        val.width = value.width
                        break
                    } else if (val instanceof Array) {
                        for (let idx1 = 0; idx1 < val.length; idx1++) {
                            let item = val[idx1]
                            if (item.name === value.name) {
                                item.width = value.width
                                break
                            }
                        }
                    }
                }
                return;
            }
        })

        this.setState({ columnDefs: column, headerToColumn: headerToColumn }, this.forceUpdate())
    }

    findRowIndex(array, value) {
        var low = 0,
            high = array.length;

        while (low < high) {
            var mid = (low + high) >>> 1;
            if (array[mid] === value) return -1
            if (array[mid] < value) low = mid + 1;
            else high = mid;
        }

        return low;
    }

    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        if (Object.values(updated)[0] === 'DUMMY' && Object.keys(updated)[0] === 'OVERRIDE_INDUSTRY_ID') {
            return
        }
        if (Object.values(updated)[0] === 'DUMMY' && Object.keys(updated)[0] === 'CAPIQ_COMPANY_ID') {
            return
        }
        if (Object.keys(updated)[0] === 'USER_DATE_FIELD' && Object.values(updated)[0] !== null
            && (Object.values(updated)[0].toLowerCase() === 'invalid date' || Object.values(updated)[0].length !== 10)) {
            return
        }
        fromRow -= 2; toRow -= 2;

        this.setState(state => {
            const filteredRows = state.filteredRows;
            const rowData = state.rowData;

            const pendingRecords = state.pendingRecords;
            pendingRecords.sort((a, b) => {
                return a - b;
            })

            const colIdx = state.columnDefs.findIndex((item) => {
                return item.key === Object.keys(updated)[0]
            })

            const warningRecords = this.state.warningRecords;
            const errorRecords = this.state.errorRecords;
            const exclusionReason_directSpendUpdates = this.state.exclusionReason_directSpendUpdates;
            var userCommentOverrides = this.state.userCommentOverrides
            var cleanIndustryOverrides = this.state.cleanIndustryOverrides
            var confirmedflagTrans = this.state.confirmedflagTrans
            var capIQIdOverrides = this.state.capIQIdOverrides
            var enablementWaveOverrides = this.state.enablementWaveOverrides

            for (var idx = state.paginatedRowFrom + fromRow, len = (state.paginatedRowFrom + toRow); idx <= len; idx++) {
                const editable = { column: state.columnDefs[colIdx], row: filteredRows[idx] }
                if (!this.isCellEditable(editable) && Object.keys(updated)[0] !== 'OVERRIDE_INDUSTRY_ID' && Object.keys(updated)[0] !== 'USER_COMMENT'
                    && Object.keys(updated)[0] !== 'CAPIQ_COMPANY_ID' && Object.keys(updated)[0] !== 'ENABLEMENT_WAVE') {
                    continue;
                }
                if (Object.keys(updated)[0] === 'USER_COMMENT' && moment(Object.values(updated)[0], 'MM/DD/YYYY', true).isValid() === true) {
                    continue;
                }
                if (filteredRows[idx][editable.column.key] == Object.values(updated)[0])
                    continue;

                var exclusionReason = filteredRows[idx]['EXCLUSION_REASON']
                var directSpend = filteredRows[idx]['DIRECT_SPEND']
                let skey = filteredRows[idx]['DATA_COLLECTIONS_SKEY']

                if (editable.column.key === 'EXCLUSION_REASON') {
                    if(filteredRows[idx]['EXCLUSION_REASON'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                    }
                }

                if (editable.column.key === 'SAP_ARIBA_BUY_PROCESS') {
                    if(filteredRows[idx]['SAP_ARIBA_BUY_PROCESS'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                        filteredRows[idx]['EXCLUSION_REASON'] = '';
                    }
                }
                if (editable.column.key === 'SOURCING_ELIGIBLE') {
                    if(filteredRows[idx]['SOURCING_ELIGIBLE'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                        filteredRows[idx]['EXCLUSION_REASON'] = '';
                    }
                }
                if (editable.column.key === 'BUYING_INVOICING_ELIGIBLE') {
                    if(filteredRows[idx]['BUYING_INVOICING_ELIGIBLE'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                        filteredRows[idx]['EXCLUSION_REASON'] = '';
                    }
                }
                if (editable.column.key === 'REQUISITIONABLE') {
                    if(filteredRows[idx]['REQUISITIONABLE'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                        filteredRows[idx]['EXCLUSION_REASON'] = '';
                    }
                }
                if (editable.column.key === 'REQUISITION_TYPE') {
                    if(filteredRows[idx]['REQUISITION_TYPE'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                        filteredRows[idx]['EXCLUSION_REASON'] = '';
                    }
                }
                if (editable.column.key === 'CONTRACT_INVOICING_ELIGIBLE') {
                    if(filteredRows[idx]['CONTRACT_INVOICING_ELIGIBLE'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                        filteredRows[idx]['EXCLUSION_REASON'] = '';
                    }
                }
                if (editable.column.key === 'COMMERCE_AUTOMATION_ELIGIBLE') {
                    if(filteredRows[idx]['COMMERCE_AUTOMATION_ELIGIBLE'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                        filteredRows[idx]['EXCLUSION_REASON'] = '';
                    }
                }
                if (editable.column.key === 'SUPP_CHAIN_COLLAB_ELIGIBLE') {
                    if(filteredRows[idx]['SUPP_CHAIN_COLLAB_ELIGIBLE'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                        filteredRows[idx]['EXCLUSION_REASON'] = '';
                    }
                }
                if (editable.column.key === 'SAP_FIELDGLASS_ELIGIBLE') {
                    if(filteredRows[idx]['SAP_FIELDGLASS_ELIGIBLE'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                        filteredRows[idx]['EXCLUSION_REASON'] = '';
                    }
                }
                if (editable.column.key === 'SAP_CONCUR_ELIGIBLE') {
                    if(filteredRows[idx]['SAP_CONCUR_ELIGIBLE'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                        filteredRows[idx]['EXCLUSION_REASON'] = '';
                    }
                }
                if (editable.column.key === 'DISCOUNT_ELIGIBLE') {
                    if(filteredRows[idx]['DISCOUNT_ELIGIBLE'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                        filteredRows[idx]['EXCLUSION_REASON'] = '';
                    }
                }
                if (editable.column.key === 'SPOT_BUY_ELIGIBLE') {
                    if(filteredRows[idx]['SPOT_BUY_ELIGIBLE'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                        filteredRows[idx]['EXCLUSION_REASON'] = '';
                    }
                }
                if (editable.column.key === 'PRIMARY_BUY_PROCESS_DELIVERED') {
                    if(filteredRows[idx]['PRIMARY_BUY_PROCESS_DELIVERED'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                        filteredRows[idx]['EXCLUSION_REASON'] = '';
                    }
                }
                if (editable.column.key === 'SECONDARY_BUY_PROCESS_DELIVERED') {
                    if(filteredRows[idx]['SECONDARY_BUY_PROCESS_DELIVERED'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                        filteredRows[idx]['EXCLUSION_REASON'] = '';
                    }
                }
                if (editable.column.key === 'OTHER_BUY_PROCESS_DELIVERED') {
                    if(filteredRows[idx]['OTHER_BUY_PROCESS_DELIVERED'] != Object.values(updated)[0])
                    {
                        filteredRows[idx]['SCOPE_SOURCE'] = 'Override';
                        updated.SCOPE_SOURCE = 'Override';
                        filteredRows[idx]['EXCLUSION_REASON'] = '';
                    }
                }

                if (editable.column.key === 'OVERRIDE_INDUSTRY_ID') {
                    if (cleanIndustryOverrides[skey] === undefined) {
                        cleanIndustryOverrides[skey] = { old: filteredRows[idx]['OVERRIDE_INDUSTRY_ID'], new: Object.values(updated)[0] }
                    } else {
                        cleanIndustryOverrides[skey] = { old: cleanIndustryOverrides[skey].old, new: Object.values(updated)[0] }
                    }
                }

                if (editable.column.key === 'USER_COMMENT') {
                    if (userCommentOverrides[skey] === undefined) {
                        userCommentOverrides[skey] = { old: filteredRows[idx]['USER_COMMENT'], new: Object.values(updated)[0] }
                    } else {
                        userCommentOverrides[skey] = { old: userCommentOverrides[skey].old, new: Object.values(updated)[0] }
                    }
                }

                if (editable.column.key === 'CONFIRMED') {
                    if (confirmedflagTrans[skey] === undefined) {
                        confirmedflagTrans[skey] = { old: filteredRows[idx]['CONFIRMED'], new: Object.values(updated)[0] }
                    } else {

                        confirmedflagTrans[skey] = { old: confirmedflagTrans[skey].old, new: Object.values(updated)[0] }
                    }
                }

                if (editable.column.key === 'CAPIQ_COMPANY_ID') {
                    if (capIQIdOverrides[skey] === undefined) {
                        capIQIdOverrides[skey] = { old: filteredRows[idx]['CAPIQ_COMPANY_ID'], new: Object.values(updated)[0] }
                    } else {
                        capIQIdOverrides[skey] = { old: capIQIdOverrides[skey].old, new: Object.values(updated)[0] }
                    }
                }

                if (editable.column.key === 'ENABLEMENT_WAVE') {
                    updated.ENABLEMENT_WAVE_OVERRIDE = updated.ENABLEMENT_WAVE !== "" ? 'Override' : 'Calculated'
                    //also pass FP_FLAG_EIEDITED                    
                    if (enablementWaveOverrides[skey] === undefined) {
                        enablementWaveOverrides[skey] = { old: filteredRows[idx]['ENABLEMENT_WAVE'], new: Object.values(updated)[0] }
                    } else {
                        enablementWaveOverrides[skey] = { old: enablementWaveOverrides[skey].old, new: Object.values(updated)[0] }
                    }
                }

                if (editable.column.key === 'SAP_ARIBA_BUY_ENABLE_MTHD_RECMD') {
                    updated.ENABLEMENT_RECOMMENDATION_OVERRIDE = 'Override'
                }

                if (editable.column.key === 'ONBOARDING_WORKSTREAM_DETAILS') {
                    updated.ONBOARDING_WORKSTREAM_DETAILS = updated.ONBOARDING_WORKSTREAM_DETAILS === "Null" ? '' : updated.ONBOARDING_WORKSTREAM_DETAILS
                    updated.ONBOARDING_WORKSTREAM_DETAILS_OVERRIDE = 'Override'
                }

                filteredRows[idx] = { ...filteredRows[idx], ...updated };

                rowData.sort((a, b) => { return a['DATA_COLLECTIONS_SKEY'] - b['DATA_COLLECTIONS_SKEY'] })  // binary search work with sorted array only
                var rowIdx = binarySearchIndex(rowData, skey, 'DATA_COLLECTIONS_SKEY')
                rowData[rowIdx] = { ...rowData[rowIdx], ...updated };


                var recordIdx = this.findRowIndex(pendingRecords, skey);
                if (recordIdx >= 0) {
                    pendingRecords.splice(recordIdx, 0, skey);
                }

                //Check for Exclusion reason and Direct Spend update
                if ((Object.keys(updated)[0] === "EXCLUSION_REASON" && Object.values(updated)[0] !== exclusionReason)
                    || (Object.keys(updated)[0] === "DIRECT_SPEND" && Object.values(updated)[0] !== directSpend)) {
                    if (!exclusionReason_directSpendUpdates.includes(skey))
                        exclusionReason_directSpendUpdates.push(skey)
                }

                this.validateForWarning(filteredRows[idx], warningRecords);
                this.validateForError(filteredRows[idx], errorRecords);
            }
            return {
                responseMsg: '',
                confirmedflagTrans: confirmedflagTrans,
                cleanIndustryOverrides: cleanIndustryOverrides,
                userCommentOverrides: userCommentOverrides,
                capIQIdOverrides: capIQIdOverrides,
                enablementWaveOverrides: enablementWaveOverrides,
                rowData: rowData, filteredRows: filteredRows, fromRow: fromRow, toRow: toRow, updated: updated,
                pendingRecords: pendingRecords, warningRecords: warningRecords, errorRecords: errorRecords, exclusionReason_directSpendUpdates
            };
        });
    };

    validateForWarning(record, warningRecords) {
        const warningMessages = this.props.content.validationMessage.warningMessage;

        var exclusionReason = record['EXCLUSION_REASON']
        if (getNonEmptyValue(exclusionReason) !== '') {
            this.addWarningErroredRecord(warningMessages.exclusionReasonWarning, record, warningRecords, true)
        } else {
            this.addWarningErroredRecord(warningMessages.exclusionReasonWarning, record, warningRecords, false)
        }

        var buyingInvoicing = record['BUYING_INVOICING_ELIGIBLE'];
        var fieldGlassEligible = record['SAP_FIELDGLASS_ELIGIBLE']
        if (getNonEmptyValue(buyingInvoicing) === 'Yes' && getNonEmptyValue(fieldGlassEligible) === 'Yes') {
            this.addWarningErroredRecord(warningMessages.buyingInvoicingFieldGlassEligibleWarning, record, warningRecords, true)
        } else {
            this.addWarningErroredRecord(warningMessages.buyingInvoicingFieldGlassEligibleWarning, record, warningRecords, false)
        }

        var supplyChainEligible = record['SUPP_CHAIN_COLLAB_ELIGIBLE']
        if (getNonEmptyValue(buyingInvoicing) === 'Yes' && getNonEmptyValue(supplyChainEligible) === 'Yes') {
            this.addWarningErroredRecord(warningMessages.buyingInvoicingSupplyChainEligibleWarning, record, warningRecords, true)
        } else {
            this.addWarningErroredRecord(warningMessages.buyingInvoicingSupplyChainEligibleWarning, record, warningRecords, false)
        }

        var concurEligible = record['SAP_CONCUR_ELIGIBLE']
        if (getNonEmptyValue(buyingInvoicing) === 'Yes' && getNonEmptyValue(concurEligible) === 'Yes') {
            this.addWarningErroredRecord(warningMessages.buyingInvoicingConcurEligibleWarning, record, warningRecords, true)
        } else {
            this.addWarningErroredRecord(warningMessages.buyingInvoicingConcurEligibleWarning, record, warningRecords, false)
        }

        if (getNonEmptyValue(supplyChainEligible) === 'Yes' && getNonEmptyValue(fieldGlassEligible) === 'Yes') {
            this.addWarningErroredRecord(warningMessages.fieldGlassSCCEligibleWarning, record, warningRecords, true)
        } else {
            this.addWarningErroredRecord(warningMessages.fieldGlassSCCEligibleWarning, record, warningRecords, false)
        }

        var discountEligible = record['DISCOUNT_ELIGIBLE']
        var termRationalizationEligible = record['TERMS_RATIONALIZATION']
        var workingCaptialSegmentation = record['WORKING_CAPTIAL_SEGMENTATION'].trim()
        if (getNonEmptyValue(discountEligible) === 'Yes' && getNonEmptyValue(termRationalizationEligible) === 'Yes') {
            this.addWarningErroredRecord(warningMessages.discountAndTermRationalizationEligibleWarning, record, warningRecords, true)
        } else {
            this.addWarningErroredRecord(warningMessages.discountAndTermRationalizationEligibleWarning, record, warningRecords, false)
        }

        if (getNonEmptyValue(discountEligible) === 'Yes' && getNonEmptyValue(termRationalizationEligible) === 'Yes') {
            this.addWarningErroredRecord(warningMessages.discountAndTermRationalizationEligibleWarning, record, warningRecords, true)
        } else {
            this.addWarningErroredRecord(warningMessages.discountAndTermRationalizationEligibleWarning, record, warningRecords, false)
        }

        if (getNonEmptyValue(discountEligible) === 'Yes'
            && discountingVals.indexOf(getNonEmptyValue(workingCaptialSegmentation)) == -1) {
            this.addWarningErroredRecord(warningMessages.discountEligibleWarning, record, warningRecords, true)
        } else {
            this.addWarningErroredRecord(warningMessages.discountEligibleWarning, record, warningRecords, false)
        }

        if (getNonEmptyValue(termRationalizationEligible) === 'Yes'
            && termRationalizationVals.indexOf(getNonEmptyValue(workingCaptialSegmentation)) == -1
        ) {
            this.addWarningErroredRecord(warningMessages.termRationalizationWarning, record, warningRecords, true)
        } else {
            this.addWarningErroredRecord(warningMessages.termRationalizationWarning, record, warningRecords, false)
        }

    }

    validateForError(record, errorRecords) {
        const errorMessages = this.props.content.validationMessage.errorMessage;

        var buyingInvoicing = record['BUYING_INVOICING_ELIGIBLE'];
        var requisitionable = record['REQUISITIONABLE'];
        var contractInvoicing = record['CONTRACT_INVOICING_ELIGIBLE'];

        if ((getNonEmptyValue(buyingInvoicing) === 'Yes' && getNonEmptyValue(requisitionable) === 'Yes' && getNonEmptyValue(contractInvoicing) === 'Yes') ||
            (getNonEmptyValue(buyingInvoicing) === 'Yes' && getNonEmptyValue(requisitionable) === 'No' && getNonEmptyValue(contractInvoicing) === 'No')) {
            this.addWarningErroredRecord(errorMessages.buyingInvoicingError, record, errorRecords, true)
        } else {
            this.addWarningErroredRecord(errorMessages.buyingInvoicingError, record, errorRecords, false)
        }

        var requisitionableType = record['REQUISITION_TYPE'];
        if (getNonEmptyValue(requisitionable) === 'Yes' && getNonEmptyValue(requisitionableType) === 'N/A') {
            this.addWarningErroredRecord(errorMessages.requisitionableTypeError1, record, errorRecords, true)
        } else {
            this.addWarningErroredRecord(errorMessages.requisitionableTypeError1, record, errorRecords, false)
        }

        if (getNonEmptyValue(requisitionable) === 'No' && getNonEmptyValue(requisitionableType) !== 'N/A') {
            this.addWarningErroredRecord(errorMessages.requisitionableTypeError2, record, errorRecords, true)
        } else {
            this.addWarningErroredRecord(errorMessages.requisitionableTypeError2, record, errorRecords, false)
        }

        if (getNonEmptyValue(requisitionable) === 'Yes' && getNonEmptyValue(buyingInvoicing) === 'No') {
            this.addWarningErroredRecord(errorMessages.requisitionableTypeError3, record, errorRecords, true)
        } else {
            this.addWarningErroredRecord(errorMessages.requisitionableTypeError3, record, errorRecords, false)
        }

        if (getNonEmptyValue(contractInvoicing) === 'Yes' && getNonEmptyValue(buyingInvoicing) === 'No') {
            this.addWarningErroredRecord(errorMessages.contractInvoicingError, record, errorRecords, true)
        } else {
            this.addWarningErroredRecord(errorMessages.contractInvoicingError, record, errorRecords, false)
        }

        var supplyChain = record['SUPP_CHAIN_COLLAB_ELIGIBLE'];
        var commerceAutomation = record['COMMERCE_AUTOMATION_ELIGIBLE'];

        if (getNonEmptyValue(supplyChain) === 'Yes' && getNonEmptyValue(commerceAutomation) === 'Yes') {
            this.addWarningErroredRecord(errorMessages.supplyCommerceError, record, errorRecords, true)
        } else {
            this.addWarningErroredRecord(errorMessages.supplyCommerceError, record, errorRecords, false)
        }
    }

    addWarningErroredRecord(reason, record, records, add) {
        var arr = records[reason];
        if (getNonEmptyValue(arr) === '') {
            arr = []
        }

        var eltIdx = arr.indexOf((record['DATA_COLLECTIONS_SKEY']));
        if (eltIdx === -1 && add) {
            arr.push(record['DATA_COLLECTIONS_SKEY'])
        } else if (eltIdx !== -1 && !add) {
            arr.splice(eltIdx, 1)
        }

        records[reason] = arr;
    }

    radioButtonChecked(value) {
        if (value.target.value == 'nonSensitiveColumns') {
            this.setState({ exportAction: 'nonSensitiveColumns' })
        } else if (value.target.value == 'enablementInsightColumns') {
            this.setState({ exportAction: 'enablementInsightColumns' })
        } else if (value.target.value == 'currentViewColumns') {
            this.setState({ exportAction: 'currentViewColumns' })
        } else if (value.target.value == 'matchAndClassificationColumns') {
            this.setState({ exportAction: 'matchAndClassificationColumns' })
        } else if (value.target.value == 'customerViewColumns') {
            this.setState({ exportAction: 'customerViewColumns' })
        } else if (value.target.value == 'matchAndClassificationColumnsCSV') {
            this.setState({ exportAction: 'matchAndClassificationColumnsCSV' })
        } else if (value.target.value == 'customerViewColumnsCSV') {
            this.setState({ exportAction: 'customerViewColumnsCSV' })
        } else if (value.target.value == 'matchedOutputDemo') {
            this.setState({ exportAction: 'matchedOutputDemo' })   
        } else if (value.target.value == 'matchedOutputDemoEnhanced') {
            this.setState({ exportAction: 'matchedOutputDemoEnhanced' })            
        } else if (value.target.value == 'custFacingMatchedOutputDemo') {
            this.setState({ exportAction: 'custFacingMatchedOutputDemo' })          
        } else {
            this.setState({ exportAction: 'allColumns' })
        }
    }

    onSave() {
        const pendingRecords = this.state.pendingRecords;
        if (pendingRecords.length === 0) {
            return;
        }

        this.props.defineScopeLoading(true)
        this.setState({ isLoading: true, isError: 'No', responseMsg: '', isSaveInProgress: true })
        const rowData = this.state.rowData
        const exclusionReason_directSpendUpdates = this.state.exclusionReason_directSpendUpdates
        const recordsToInsert = [];
        const cleanIndustryOverrides = this.state.cleanIndustryOverrides
        const userCommentOverrides = this.state.userCommentOverrides
        const confirmedflagTrans = this.state.confirmedflagTrans
        const capIQIdOverrides = this.state.capIQIdOverrides
        const enablementWaveOverrides = this.state.enablementWaveOverrides

        pendingRecords.forEach((item) => {
            rowData.sort((a, b) => { return a['DATA_COLLECTIONS_SKEY'] - b['DATA_COLLECTIONS_SKEY'] })  // binary search work with sorted array only
            var rowIdx = binarySearchIndex(rowData, item, 'DATA_COLLECTIONS_SKEY')
            const row = rowData[rowIdx]
            const dkeyRecord = cleanIndustryOverrides[item]
            const skeyRecord = userCommentOverrides[item]
            const confirmedFlagRecord = confirmedflagTrans[item]
            const capIqIdRecord = capIQIdOverrides[item]
            const enablementWaveRecord = enablementWaveOverrides[item]
            const record = {
                VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID, DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID, DATA_COLLECTIONS_SKEY: item,
                USER_ID: this.props.user.user.Email,
                FLAG_SOLUTION: row["SCOPE_SOURCE"] === "Override" ? "Yes" : "No",
                FLAG_INDUSTRY: ((dkeyRecord && dkeyRecord.old !== dkeyRecord.new) || row["SCOPE_SOURCE"] === "Industry") ? "Yes" : "No",
                FLAG_INP: exclusionReason_directSpendUpdates.includes(row["DATA_COLLECTIONS_SKEY"]) ? "Yes" : "No",
                FLAG_CONFIRMED: (confirmedFlagRecord && confirmedFlagRecord.old !== confirmedFlagRecord.new) ? "Yes" : "No",
                SCOPE_SOURCE: row["SCOPE_SOURCE"],
                OVERRIDE_GICS: row["OVERRIDE_INDUSTRY_ID"],
                CONFIRMED: row["CONFIRMED"],
                EXCLUSION_REASON: row["EXCLUSION_REASON"],
                GOODS_OR_SERVICE: row["GOODS_OR_SERVICE"],
                DIRECT_SPEND: row["DIRECT_SPEND"],
                SAP_ARIBA_BUY_PROCESS: row["SAP_ARIBA_BUY_PROCESS"],
                SOURCING_ELIGIBLE: row["SOURCING_ELIGIBLE"],
                BUYING_INV_ELIGIBLE: row["BUYING_INVOICING_ELIGIBLE"],
                REQUISITION_ABLE: row["REQUISITIONABLE"],
                REQUISITION_TYPE: row["REQUISITION_TYPE"],
                CONTRACT_INV_ELIGIBLE: row["CONTRACT_INVOICING_ELIGIBLE"],
                COMMERCE_AUTOMATION_ELIGIBLE: row["COMMERCE_AUTOMATION_ELIGIBLE"],
                SUPP_CHAIN_COLLAB_ELIGIBLE: row["SUPP_CHAIN_COLLAB_ELIGIBLE"],
                PAYABLES_ELIGIBLE: row["PAYABLES_ELIGIBLE"],
                SAP_FIELDGLASS_ELIGIBLE: row["SAP_FIELDGLASS_ELIGIBLE"],
                SAP_CONCUR_ELIGIBLE: row["SAP_CONCUR_ELIGIBLE"],
                SPOT_BUY_ELIGIBLE: row["SPOT_BUY_ELIGIBLE"],
                USER_DEF_TEXT_FIELD_1: row["USER_DEF_TEXT_FIELD_1"],
                USER_DEF_TEXT_FIELD_2: row["USER_DEF_TEXT_FIELD_2"],
                USER_DEF_TEXT_FIELD_3: row["USER_DEF_TEXT_FIELD_3"],
                USER_DEF_TEXT_FIELD_4: row["USER_DEF_TEXT_FIELD_4"],
                USER_DEF_TEXT_FIELD_5: row["USER_DEF_TEXT_FIELD_5"],
                USER_DEF_NUMBERIC_FIELD_1: row["USER_DEF_NUMBERIC_FIELD_1"],
                USER_DEF_NUMBERIC_FIELD_2: row["USER_DEF_NUMBERIC_FIELD_2"],
                USER_DEF_NUMBERIC_FIELD_3: row["USER_DEF_NUMBERIC_FIELD_3"],
                USER_DEF_NUMBERIC_FIELD_4: row["USER_DEF_NUMBERIC_FIELD_4"],
                USER_DEF_NUMBERIC_FIELD_5: row["USER_DEF_NUMBERIC_FIELD_5"],
                USER_COMMENT: row["USER_COMMENT"],
                USER_DATE_FIELD: row["USER_DATE_FIELD"],
                DPO: row["DPO"],
                STANDARD_TERM: row["STANDARD_TERM"],
                DISCOUNT_TERM: row["DISCOUNT_TERM"],
                DISCOUNT_PERCENTAGE: row["DISCOUNT_PERCENTAGE"],
                WORKING_CAPTIAL_SEGMENTATION: row["WORKING_CAPTIAL_SEGMENTATION"],
                TERMS_RATIONALIZATION: row["TERMS_RATIONALIZATION"],
                DISCOUNT_ELIGIBLE: row["DISCOUNT_ELIGIBLE"],
                COMPANY_ID: row["CAPIQ_COMPANY_ID"],
                BUYER_SUPPLIER_RELATIONSHIP: row["BUYER_SUPPLIER_RELATIONSHIP"],
                SAP_ARIBA_DOC_PROXY: row["SAP_ARIBA_DOC_PROXY"],
                SAP_ARIBA_SPEND_PROXY: row["SAP_ARIBA_SPEND_PROXY"],
                SAP_ARIBA_BUY_ENABLE_MTHD_RECMD: row["SAP_ARIBA_BUY_ENABLE_MTHD_RECMD"],
                ENABLEMENT_WAVE: row["ENABLEMENT_WAVE"],
                ENABLEMENT_RECOMMENDATION_OVERRIDE: row["ENABLEMENT_RECOMMENDATION_OVERRIDE"],
                ENABLEMENT_WAVE_OVERRIDE: row["ENABLEMENT_WAVE_OVERRIDE"],
                ONBOARDING_WORKSTREAM_DETAILS: row["ONBOARDING_WORKSTREAM_DETAILS"] == "" ? "": row["ONBOARDING_WORKSTREAM_DETAILS"],
                ONBOARDING_WORKSTREAM_DETAILS_OVERRIDE: row["ONBOARDING_WORKSTREAM_DETAILS_OVERRIDE"],
                ENABLE_MTHD_RECMD_OUT_SCOPE_REASON: row["ENABLE_MTHD_RECMD_OUT_SCOPE_REASON"],

                // SCC Fields
                ADD_SUPPLIER_NAME: row["ADD_SUPPLIER_NAME"],
                COMPANY_VENDOR_LIST: row["COMPANY_VENDOR_LIST"],
                CUSTOM_FIELD1: row["CUSTOM_FIELD1"],
                CUSTOM_FIELD2: row["CUSTOM_FIELD2"],
                ENABLEMENT_WAVE: row["ENABLEMENT_WAVE"],
                ERP_ASN_CNT: row["ERP_ASN_CNT"],
                ERP_OC_CNT: row["ERP_OC_CNT"],
                ERP_PO_LINE_ITEM_CNT: row["ERP_PO_LINE_ITEM_CNT"],
                GOBAL_SUPPLIER_REGION: row["GOBAL_SUPPLIER_REGION"],
                LST_TRANS_BUYER_ERP_DATE: row["LST_TRANS_BUYER_ERP_DATE"],
                PLANT_BUSS_VENDOR_ADDRESS: row["PLANT_BUSS_VENDOR_ADDRESS"],
                PLANT_BUSS_VENDOR_ID: row["PLANT_BUSS_VENDOR_ID"],
                SUPPLIER_TYPE: row["SUPPLIER_TYPE"],
                SCC_CONSIGMENT_COLLABRATION_IN_SCOPE: row["SCC_CONSIGMENT_COLLABRATION_IN_SCOPE"],
                SCC_FORECAST_IN_SCOPE: row["SCC_FORECAST_IN_SCOPE"],
                SCC_QUALITY_INSPECTION_IN_SCOPE: row["SCC_QUALITY_INSPECTION_IN_SCOPE"],
                SCC_QUALITY_NOTIFICATION_IN_SCOPE: row["SCC_QUALITY_NOTIFICATION_IN_SCOPE"],
                SCC_QUALITY_REVIEW_IN_SCOPE: row["SCC_QUALITY_REVIEW_IN_SCOPE"],
                SCC_SCHEDULE_AGGREMENT_IN_SCOPE: row["SCC_SCHEDULE_AGGREMENT_IN_SCOPE"],
                SCC_SMI_IN_SCOPE: row["SCC_SMI_IN_SCOPE"],
                SCC_SOLUTION_IN_SCOPE: row["SCC_SOLUTION_IN_SCOPE"],
                SCC_STD_ORDER_PROCESS_IN_SCOPE: row["SCC_STD_ORDER_PROCESS_IN_SCOPE"],
                SCC_SUBCONTRACTING_IN_SCOPE: row["SCC_SUBCONTRACTING_IN_SCOPE"],
                SAP_BUSS_NET_TRANS_MTHD: row["SAP_BUSS_NET_TRANS_MTHD"],
                RETURN_IN_SCOPE: row["RETURN_IN_SCOPE"],

                PRIMARY_BUY_PROCESS_DELIVERED: row["PRIMARY_BUY_PROCESS_DELIVERED"],
                SECONDARY_BUY_PROCESS_DELIVERED: row["SECONDARY_BUY_PROCESS_DELIVERED"],
                OTHER_BUY_PROCESS_DELIVERED: row["OTHER_BUY_PROCESS_DELIVERED"],
            };

            recordsToInsert.push(record);
        })

        this.props.insertDetailedFileRecords(recordsToInsert)
            .then((response) => {
                let spendData = response.data.Result.SpendData;
                let supplierComments = response.data.Result.SupplierComments;
                let flightPlanningData = response.data.Result.FlightPlanningData;
                const rowData = this.state.rowData;
                const filteredRows = this.state.filteredRows;
                var matched = 0;

                for (var i = 0; i < spendData.length; i++) {
                    const row = spendData[i];
                    if (row['OVERRIDE_INDUSTRY_ID'] === 0)
                        row['OVERRIDE_INDUSTRY_ID'] = ''

                    const skey = row['DATA_COLLECTIONS_SKEY'];
                    rowData.sort((a, b) => { return a['DATA_COLLECTIONS_SKEY'] - b['DATA_COLLECTIONS_SKEY'] })  // binary search work with sorted array only
                    var rowIdx = binarySearchIndex(rowData, skey, 'DATA_COLLECTIONS_SKEY')
                    rowData[rowIdx] = { ...rowData[rowIdx], ...row };

                    for (var idx = 0; idx < filteredRows.length; idx++) {
                        if (filteredRows[idx]['DATA_COLLECTIONS_SKEY'] === row['DATA_COLLECTIONS_SKEY']) {
                            filteredRows[idx] = { ...filteredRows[idx], ...row };
                            matched++;
                        }

                        if (matched === spendData.length) {
                            break;
                        }
                    }
                }

                matched = 0;
                for (var i = 0; i < supplierComments.length; i++) {
                    const commentRow = supplierComments[i];
                    const commentSkey = commentRow['DATA_COLLECTIONS_SKEY'];
                    rowData.sort((a, b) => { return a['DATA_COLLECTIONS_SKEY'] - b['DATA_COLLECTIONS_SKEY'] })  // binary search work with sorted array only
                    var rowIdx = binarySearchIndex(rowData, commentSkey, 'DATA_COLLECTIONS_SKEY')
                    rowData[rowIdx] = { ...rowData[rowIdx], ...commentRow };
                    for (var idx = 0; idx < filteredRows.length; idx++) {
                        if (filteredRows[idx]['DATA_COLLECTIONS_SKEY'] === commentRow['DATA_COLLECTIONS_SKEY']) {
                            filteredRows[idx] = { ...filteredRows[idx], ...commentRow };
                            matched++;
                        }
                        if (matched === supplierComments.length) {
                            break;
                        }
                    }
                }

                matched = 0;
                for (var i = 0; i < flightPlanningData.length; i++) {
                    const flightPlanningRow = flightPlanningData[i];
                    const flightPlanningRowSkey = flightPlanningRow['DATA_COLLECTIONS_SKEY'];
                    rowData.sort((a, b) => { return a['DATA_COLLECTIONS_SKEY'] - b['DATA_COLLECTIONS_SKEY'] })  // binary search work with sorted array only
                    var rowIdx = binarySearchIndex(rowData, flightPlanningRowSkey, 'DATA_COLLECTIONS_SKEY')
                    rowData[rowIdx] = { ...rowData[rowIdx], ...flightPlanningRow };
                    for (var idx = 0; idx < filteredRows.length; idx++) {
                        if (filteredRows[idx]['DATA_COLLECTIONS_SKEY'] === flightPlanningRow['DATA_COLLECTIONS_SKEY']) {
                            filteredRows[idx] = { ...filteredRows[idx], ...flightPlanningRow };
                            matched++;
                        }
                        if (matched === flightPlanningData.length) {
                            break;
                        }
                    }
                }

                this.props.defineScopeLoading(false)
                this.props.pendingChanges(null)

                if (spendData.length > 0) {
                    this.props.setResultSummaryDetails([])
                    this.props.setTopDetails([])
                }
                this.setState({ confirmedflagTrans: {}, cleanIndustryOverrides: {}, capIQIdOverrides: {}, enablementWaveOverrides: {}, filteredRows: filteredRows, rowData: rowData, pendingRecords: [], errorRecords: [], warningRecords: [], exclusionReason_directSpendUpdates: [], responseMsg: 'Record/s has been updated successfully', isError: 'No', isLoading: false, isSaveInProgress: false },
                    () => this.filteredColumnValues = [])
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false, isSaveInProgress: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false, isSaveInProgress: false })
                }
            })
    }

    /* This method can be used to highlight the cell values */
    RowRenderer = ({ renderBaseRow, ...props }) => {
        if (props.row.superHeader && Object.keys(props.row.superHeader).length > 0) {
            const value = props.row.superHeader
            let left = 0
            let headerGroupDiv = Object.keys(value).map((key, index) => {
                const val = value[key]
                let row = {}
                if (val.name === null) {
                    let cellValue = (<div className='react-grid-HeaderCell react-grid-HeaderCell-Group'
                        style={{ position: 'absolute', width: `${val.width}px`, height: '35px', left: `${left}px`, backgroundColor: '#ffffff', }}>
                        <div className='react-grid-HeaderCell-sortable'>
                        </div>
                    </div>)

                    left = left + val.width

                    return cellValue
                } else {
                    let width = 0
                    val.forEach(item => {
                        width = width + item.width
                    })
                    // const width = val.length * val.width
                    let cellValue = (<div className='react-grid-HeaderCell react-grid-HeaderCell-Group'
                        style={{ textAlign: 'left', position: 'absolute', width: `${width}px`, height: '35px', left: `${left}px`, backgroundColor: '#7f7f7f', color: '#ffffff', textOverflow: 'ellipsis', whiteSpace: 'nowrap', transform: 'none' }}>
                        <div title={key} className='react-grid-HeaderCell-sortable'>
                            {key}
                        </div>
                    </div>)

                    val.forEach(item => {
                        left = left + item.width
                    })

                    return cellValue
                }
            })

            return <div className='react-grid-HeaderRow' style={{ height: '35px', overflow: 'hidden' }}>{headerGroupDiv}</div>
        }


        if (this.state.pendingRecords.length === 0) {
            return renderBaseRow(props);
        }

        let backgroundColor = '';
        let textAlign = 'left';

        const pendingRecords = this.state.pendingRecords;
        const warningRecords = this.state.warningRecords;
        const errorRecords = this.state.errorRecords;

        Object.values(pendingRecords).every((item) => {
            if (item === props.row.DATA_COLLECTIONS_SKEY) {
                backgroundColor = 'lightgray'
                return false
            } else {
                return true
            }
        })

        Object.values(warningRecords).forEach((itemArr) => {
            itemArr.every(item => {
                if (item === props.row.DATA_COLLECTIONS_SKEY) {
                    backgroundColor = 'yellow'
                    return false
                } else {
                    return true
                }
            })
        })

        Object.values(errorRecords).forEach((itemArr) => {
            itemArr.every(item => {
                if (item === props.row.DATA_COLLECTIONS_SKEY) {
                    backgroundColor = 'red'
                    return false
                } else {
                    return true
                }
            })
        })

        return <div style={{ backgroundColor }}>{renderBaseRow(props)}</div>;

    };
    

    componentDidMount() {
        var sortByOption = this.state.sortByOption;
        var selectedSortBy = this.state.selectedSortBy;
        var gridSortBy = this.state.gridSortBy;
        var sortByName =  this.state.sortByName;
        if (this.props.spendDetails.solutionScope == 'Supply Chain Collaboration') {
            sortByOption[0].value = 'SCC_ENABLE_MATCH_SPND_DOC_SCORE';
            selectedSortBy = 'SCC_ENABLE_MATCH_SPND_DOC_SCORE';
            gridSortBy = 'SCC_ENABLE_MATCH_SPND_DOC_SCORE';
            sortByName = 'SCC Enablement Match Spend & Document Score';
        }
        else if (this.props.spendDetails.kpi_Model == 1) {
            sortByOption[0].value = 'AVATAR_WAVE_SCORE';
            selectedSortBy = 'AVATAR_WAVE_SCORE';
            gridSortBy = 'AVATAR_WAVE_SCORE';
            sortByName = 'Intelligent Enablement Match Spend & Document Score';
        }
        else if (this.props.spendDetails.kpi_Model == 2) {
            sortByOption[0].value = 'KPI_ENABLE_MATCH_SPD_DOC_SCORE';
            selectedSortBy = 'KPI_ENABLE_MATCH_SPD_DOC_SCORE';
            gridSortBy = 'KPI_ENABLE_MATCH_SPD_DOC_SCORE';
            sortByName = 'KPI Enablement Match Spend & Document Score';
        }

        this.props.defineScopeLoading(true)
        let matchAndClassificationColumnsFlag = this.props.user.user.applicationFeatures.findIndex(a => a.Action === 'exportMatchAndClassificationColumns') > -1 ? true : false
        this.loadData();
        this.loadVendorData()
        // if(this.props.spendDetails.currentActiveRequest.DMC_ID !== undefined && this.state.rowData.length == 0){
        //     this.loadVendorData()
        //   }       

        let fileExportOption = this.props.spendDetails.currentActiveRequest.Number_Of_Records > downloadThreshold ? 'customerViewColumnsCSV' : 'customerViewColumns';

        window.scrollTo(0, 0);
        this.setState({ exportAction: fileExportOption, exportMC_Cloumns: matchAndClassificationColumnsFlag, 
            isCapIQLicesend: this.props.user.user.applicationFeatures.findIndex(a => a.Action === this.props.content.capIQAction) > -1 ? true : false ,
            sortByOption: sortByOption, selectedSortBy:selectedSortBy, gridSortBy:gridSortBy, sortByName:sortByName
        })
    }

    getApplicationFeatureActions() {
        let actions = [];
        let applicationFeatures = this.props.user && this.props.user.user && this.props.user.user.applicationFeatures ? this.props.user.user.applicationFeatures : [];
        applicationFeatures.map(applicationFeat => {
            actions.push(applicationFeat.Action);
        })
        return actions;
    }

    getTableColumn(self, fieldName, fieldConfig) {
        let tableColumn = {};
        tableColumn = {
            name: fieldConfig.screen_field_name,
            key: fieldName,
            width: 120,
            sortable: true,
            editable: fieldConfig.is_editable !== 'No',
            resizable: true,
            filterable: true,
            screenSeq: fieldConfig.screen_seq,
            editProperty: fieldConfig.is_editable,
            formatter: formatter(fieldConfig.alignment),
            filterRenderer: filterRenderer(fieldConfig.filter_type),
            helpText: fieldConfig.screen_help_text,
            hash: (new Date).getTime(),
            headerGroup: fieldConfig.headerGroup,
            label: fieldConfig.excel_col_name
        }

        if (fieldConfig.editor) {
            tableColumn.editor = editorComponent(fieldConfig.editor, fieldConfig.editorValues);
        }

        if (fieldName == 'OVERRIDE_INDUSTRY_ID') {
            tableColumn.editProperty = 'Yes'
            tableColumn.events = {
                onDoubleClick: function (ev, args) {
                    if (args.rowIdx > 1)
                        self.setState({ open: true, industryOverrideRowNo: args.rowIdx })
                }
            }
        }
        else if (fieldName == 'CAPIQ_COMPANY_ID') {
            tableColumn.editProperty = 'Yes'
            tableColumn.events = {
                onDoubleClick: function (ev, args) {
                    //console.log("double click id ",self.state.filteredRows[(self.state.paginatedRowFrom + args.rowIdx) - 1]['CAPIQ_COMPANY_ID'])
                    var capIqId = self.state.filteredRows[(self.state.paginatedRowFrom + args.rowIdx) - 2]['CAPIQ_COMPANY_ID'];
                    var scopeSource = self.state.filteredRows[(self.state.paginatedRowFrom + args.rowIdx) - 2]['SCOPE_SOURCE'];
                    var finalIndustryDesc = self.state.filteredRows[(self.state.paginatedRowFrom + args.rowIdx) - 2]['FINAL_INDUSTRY_DESCRIPTION'];
                    var dmcIndustryId = self.state.filteredRows[(self.state.paginatedRowFrom + args.rowIdx) - 2]['DMC_INDUSTRY_ID']; // DMC_INDUSTRY_ID value is not overrided when we overried unclassified industry, so we can use this flag while opening capiq popup
                    if (args.rowIdx != 0)
                        self.setState({ openCapIq: true, capIqIdOverrideRowNo: args.rowIdx, currentCapIqId: capIqId, currentScopeSource: scopeSource, industryDesc: finalIndustryDesc, currentDMCIndstryId: dmcIndustryId })
                }
            }
        }
        else if (fieldName == 'VENDOR_NAME' && self.state.isCapIQLicesend) {
            tableColumn['formatter'] = VendorLinkFormatter
        }
        else if (fieldName == 'FINAL_INDUSTRY_DESCRIPTION' && self.state.isCapIQLicesend) {
            tableColumn['formatter'] = IndustryLinkFormatter
        }
        else if (fieldName == 'USER_COMMENT') {
            tableColumn.editable = true
            tableColumn.editProperty = 'Yes'
            tableColumn.events = {
                onDoubleClick: function (ev, args) {
                    var skey = self.state.filteredRows[(self.state.paginatedRowFrom + args.rowIdx) - 2]['DATA_COLLECTIONS_SKEY'];
                    var userComment = self.state.filteredRows[(self.state.paginatedRowFrom + args.rowIdx) - 2]['USER_COMMENT'];
                    if (args.rowIdx != 0)
                        self.setState({ selectedSupplier: skey, openUserCommentsPopUp: true, userCommentsRowNo: args.rowIdx, userCommentInsideCell: userComment })
                }
            }
        } else if (fieldName == 'ENABLEMENT_WAVE') {
            tableColumn.editProperty = 'Yes'
            tableColumn.events = {
                onDoubleClick: function (ev, args) {
                    if (args.rowIdx != 0)
                        self.setState({ openEnablementInsightPopUp: true, enablementWaveInputRowNo: args.rowIdx, enablementWaveInputValue: ev.target.innerText })
                }
            }
        }

        if (fieldConfig.field == 'SAP_ARIBA_BUY_ENABLE_MTHD_RECMD') {
            tableColumn["width"] = 380
        }

        if (fieldConfig.field == 'GANTT_CHART_WORKSTREAM_NAME') {
            tableColumn["width"] = 250
        }

        if (fieldConfig.field == 'BUYER_SUPPLIER_RELATIONSHIP') {
            tableColumn["editor"] = TextEditor
            tableColumn["max"] = 50
        }
        tableColumn.editable = tableColumn.editable && !this.props.readOnly

        return tableColumn;
    }

    breakNumberInSet(number, n) {
        const values = [];
        let times = number;
        while (times > 0) {
            if (times >= n)
                values.push(n);
            else
                values.push(times);
            times = times - n;
        }
        return values;
    }

    getSpendData = (recordNumber, index) => {
        return new Promise((resolve, reject) => {
            this.props.getDetailedFileSpendData(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID,
                this.props.user.user.Email, recordNumber, index)
                .then((response) => {
                    // console.log(index);
                    // console.log(response.data.Result);
                    return resolve(response.data.Result)
                })
                .catch(error => {
                    return reject(error.message)
                })
        })
    }

    async loadData() { 
        // console.log('Load Start');
        // console.log(+ new Date().getDate() + '/' + new Date().getMonth() + '/' + new Date().getFullYear() + '- ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds());
        let recordsPerSet = 40000;
        this.props.defineScopeLoading(true)
        this.setState({ finalSpendData: [],vufData : [], isLoading: true, responseMsg: '', isError: 'No' })
        // let firstRoundTrip = 1; let secondRoundTrip = 2;
        let requestFileRecordCount = this.props.spendDetails.currentActiveRequest.Number_Of_Records;

        let arrRecords = this.breakNumberInSet(requestFileRecordCount, recordsPerSet);
        let finalSpendData = [];
        let vufWaves = [];
        var procedureResult = [];
        var procedureNames;
        var procedureNextResult = [];
        let j = 0;

        //-----------------------

        //let results = [];
        let promises = [];
        //let param;

        for (j; j < arrRecords.length; j++) {

            let param = { 'page': j }
            let tmpPromise = this.getSpendData(arrRecords[j], j);
            promises.push(tmpPromise);

        }
        let resolved = await Promise.all(promises).then((arrayOfArrays) => {
            return [].concat.apply([], arrayOfArrays);
        });
        //resolved is an array of resolved promises, each with value returned by async call
        let indivResult = resolved.forEach((response, index) => {
            // console.log('resolve ' + index + ' start');
            // console.log(+ new Date().getDate() + '/' + new Date().getMonth() + '/' + new Date().getFullYear() + '- ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds());

            if (index == 0) {
                //var procedureResult = [];
                procedureNames = Object.keys(response)
                for (var i = 0; i < procedureNames.length; i++) {
                    procedureResult.push(response[procedureNames[i]])
                }
                finalSpendData = response[procedureNames[0]]
                for (var i = 1; i < procedureResult.length; i++) {
                    finalSpendData = mergeArrayByKey(finalSpendData, procedureResult[i], 'DATA_COLLECTIONS_SKEY')
                }
                let waves = finalSpendData.filter(item => Number.isInteger(Number(item.ENABLEMENT_WAVE)))
                //let vufWaves = []
                waves.forEach(item => vufWaves.push(item.ENABLEMENT_WAVE))
            }
            else {

                procedureNextResult = [];
                for (var i = 0; i < procedureNames.length; i++) {
                    procedureNextResult.push(response[procedureNames[i]])
                }
                var remainingSpendData = response[procedureNames[0]]
                for (var i = 1; i < procedureNextResult.length; i++) {
                    remainingSpendData = mergeArrayByKey(remainingSpendData, procedureNextResult[i], 'DATA_COLLECTIONS_SKEY')
                }
                finalSpendData = [...finalSpendData, ...remainingSpendData]
                remainingSpendData = null;
                //this.setState({ finalSpendData: finalSpendData, vufWaves: vufWaves }, () => this.formatSpendData())
                // console.log('resolve ' + index + ' end');
                // console.log(+ new Date().getDate() + '/' + new Date().getMonth() + '/' + new Date().getFullYear() + '- ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds());

            }
        });
        // console.log('API END');
        // console.log(+ new Date().getDate() + '/' + new Date().getMonth() + '/' + new Date().getFullYear() + '- ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds());
        finalSpendData = finalSpendData.sort((a, b) => a.DATA_COLLECTIONS_SKEY - b.DATA_COLLECTIONS_SKEY);
        // console.log('SORT END');
        // console.log(+ new Date().getDate() + '/' + new Date().getMonth() + '/' + new Date().getFullYear() + '- ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds());
        this.setState({ finalSpendData: finalSpendData, vufWaves: vufWaves }, () => this.formatSpendData());
        finalSpendData = [];
        vufWaves = [];
        procedureResult = [];
        procedureNextResult = [];
        resolved = null;
        // console.log('Set State End');
        // console.log(+ new Date().getDate() + '/' + new Date().getMonth() + '/' + new Date().getFullYear() + '- ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds());
    }

    openNdlPopUp(){
        this.setState({exportAction : 'matchedOutputDemo' },this.showModal('ndlAlert'));
    }

    openDownloadPopup(){
        var exportAction = this.state.exportAction === 'matchedOutputDemo' ? 'customerViewColumns' :  this.state.exportAction;
        this.setState({exportAction : exportAction },this.showModal('alert'));
    }

    const
    move = (array, from, to) => {
        const value = array[from];

        if (from < to) array.copyWithin(from, from + 1, to + 1);
        else array.copyWithin(to + 1, to, from + 1);
        array[to] = value;
        return array;
    };

    ndlDownload() {
        this.setState({ isLoading: true },this.forceUpdate);
        this.props.getNdlColumnsConfiguration().then((response) => {
            const colConfig = response.data.Result;
            const colConfigArr = [];
            var ndlColumns = [];
            var ndlColumnsEnhanced = [];
            var ndlParentAccountInfoColumns = [];
            var ndlCustFacingColumns = [];
            var ndlColumnsDoNotShareWithCustomer = [];
            
            colConfig.forEach((item, index) => {
                for (var key in item) {
                    if (key === 'field') {
                        //colConfigArr[item[key]] = item;
                        if(item.isNDL == 'Yes')
                        {
                            ndlColumns.push({ label: item.excel_col_name, key: item.field, seq: item.screen_seq, filter_type: item.filter_type, alignment: item.alignment })
                        }
                        if(item.isCustFacing == 'Yes')
                        {
                            ndlCustFacingColumns.push({ label: item.excel_col_name, key: item.field, seq: item.screen_seq, filter_type: item.filter_type, alignment: item.alignment })
                        }
                        if(item.isNdlEnhanced == 'Yes')
                        {
                            ndlColumnsEnhanced.push({ label: item.excel_col_name, key: item.field, seq: item.screen_seq, filter_type: item.filter_type, alignment: item.alignment })
                        }
                        if(item.isParentAccountInfo == 'Yes')
                            ndlParentAccountInfoColumns.push({ label: item.excel_col_name, key: item.field, seq: item.screen_seq, filter_type: item.filter_type, alignment: item.alignment })
                        break;
                    }
                }
            })

            ndlColumnsDoNotShareWithCustomer = [...ndlColumns];
            ndlColumnsDoNotShareWithCustomer = this.move(ndlColumnsDoNotShareWithCustomer,ndlColumnsDoNotShareWithCustomer.length-1,0);
            ndlColumnsDoNotShareWithCustomer = this.move(ndlColumnsDoNotShareWithCustomer,ndlColumnsDoNotShareWithCustomer.length-1,0);
            ndlColumnsDoNotShareWithCustomer = this.move(ndlColumnsDoNotShareWithCustomer,ndlColumnsDoNotShareWithCustomer.length-1,0);
            ndlColumnsDoNotShareWithCustomer = this.move(ndlColumnsDoNotShareWithCustomer,ndlColumnsDoNotShareWithCustomer.length-1,0);

            var detail = { DMCID: this.props.spendDetails.currentActiveRequest.DMC_ID, VSID: this.props.spendDetails.currentActiveRequest.VS_ID, Email: this.props.user.user.Id }
            var gridSortBy = this.state.gridSortBy;
            var gridSortDirection = this.state.gridSortDirection;
            this.props.getNdlReportData(detail).then((response) => {
                var ndlData = response.data.Result;
                var comparer = this.downloadSortedRecords(gridSortBy,gridSortDirection);
                ndlData = ndlData.sort((a, b) => { return a['REF_NUMBER'] - b['REF_NUMBER'] });
                ndlData = gridSortDirection == 'NONE'? ndlData : ndlData.sort(comparer);
                this.setState({ndlColumns: ndlColumns, ndlData: ndlData, ndlParentAccountInfoColumns:ndlParentAccountInfoColumns,ndlCustFacingColumns:ndlCustFacingColumns,ndlColumnsEnhanced : ndlColumnsEnhanced,ndlColumnsDoNotShareWithCustomer:ndlColumnsDoNotShareWithCustomer }, this.handleClick())
            });
        });
    }


    formatSpendData() {
        this.props.getSpendColumnsConfiguration()
            .then((response) => {
                const colConfig = response.data.Result;
                const colConfigArr = [];
                colConfig.forEach((item, index) => {
                    for (var key in item) {
                        if (key === 'field') {
                            colConfigArr[item[key]] = item;
                            break;
                        }
                    }
                })
                this.setState({ responseMsg: '', isError: 'No' })
                var self = this;
                let selectedColumns = [];

                this.props.getSelectedColumn(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Id).then((colResponse) => {
                    selectedColumns = colResponse.data.Result;

                    var column = [];
                    for (var i = 0; i < this.state.finalSpendData.length; i++) {
                        column = Object.keys(this.state.finalSpendData[0]);

                        if (i === 0)
                            break;
                    }

                    //removed INDUSTRY_SCOPE_TYPE temporarily
                    const fieldIdx = column.indexOf('INDUSTRY_SCOPE_TYPE')
                    if (fieldIdx != -1) {
                        column.splice(fieldIdx, 1)
                    }
                    if (column.indexOf('VS_ID') != -1) {
                        column.splice(column.indexOf('VS_ID'), 1)
                    }
                    if (column.indexOf('DMC_ID') != -1) {
                        column.splice(column.indexOf('DMC_ID'), 1)
                    }

                    if (column.length === 0) {
                        this.props.defineScopeLoading(false)
                        this.setState({ responseMsg: this.props.content.noData, isError: 'Yes', isLoading: false })
                    }
                    else {
                        var finalColumn = [];
                        var colOptions = [];
                        var exportNonSensitiveColumns = [];
                        var exportEnablementInsightColumns = [];
                        var exportMatchAndClassificationColumns = [];
                        var exportCustomerViewColumns = [];
                        var exportAllColumns = [];
                        var tableColumn = {};
                        var defaultScreenColumns = [];
                        var userDefinedColumns = [];

                        for (let index = 0; index < column.length; index++) {
                            const fieldName = column[index];

                            const fieldConfig = colConfigArr[fieldName];
                            if (!fieldConfig) {
                                this.setState({ responseMsg: 'Invalid Field Configuration: ' + fieldName, isError: 'Yes' })
                                return;
                            }
                            tableColumn = this.getTableColumn(self, fieldName, fieldConfig);
                            tableColumn.filter_type = fieldConfig.filter_type;
                            tableColumn.alignment = fieldConfig.alignment;
                            finalColumn.push(tableColumn);
                            
                            //if (fieldConfig.export_fl === 'Yes' || (fieldConfig.internalEnablementInsightView === 'Yes' && fieldConfig.enablementInsight_export === 'Yes')) {
                            if (fieldConfig.is_default === 'Yes' || (fieldConfig.internalEnablementInsightView === 'Yes')) {
                                exportNonSensitiveColumns.push({ label: fieldConfig.excel_col_name, key: fieldName, seq: fieldConfig.screen_seq,filter_type : fieldConfig.filter_type, alignment:fieldConfig.alignment })
                            }
                            if (fieldConfig.enablementInsight_export === 'Yes') {
                                exportEnablementInsightColumns.push({ label: fieldConfig.excel_col_name, key: fieldName, seq: fieldConfig.screen_seq,filter_type : fieldConfig.filter_type, alignment:fieldConfig.alignment })
                            }
                            if (fieldConfig.matchAndClassification_export === 'Yes') {
                                exportMatchAndClassificationColumns.push({ label: fieldConfig.excel_col_name, key: fieldName, seq: fieldConfig.screen_seq,filter_type : fieldConfig.filter_type, alignment:fieldConfig.alignment })
                            }
                            if (fieldConfig.customerView_export === 'Yes') {
                                exportCustomerViewColumns.push({ label: fieldConfig.excel_col_name, key: fieldName, seq: fieldConfig.screen_seq,filter_type : fieldConfig.filter_type, alignment:fieldConfig.alignment })
                            }                            
                            var self = this;
                            if (self.props.user.userprofile.Roles[0].indexOf("Export All_Spend Details") > -1) {
                                exportAllColumns.push({ label: fieldConfig.excel_col_name, key: fieldName, seq: fieldConfig.screen_seq,filter_type : fieldConfig.filter_type, alignment:fieldConfig.alignment })
                            }

                            let colOption = { id: fieldName, label: fieldConfig.screen_field_name, seq: fieldConfig.screen_seq, columnCategory: fieldConfig.column_category, value: tableColumn, is_default: fieldConfig.is_default, internalEnablementInsightView: fieldConfig.internalEnablementInsightView, externalEnablementInsightView: fieldConfig.externalEnablementInsightView, categoryInsightsView: fieldConfig.categoryInsightsView, enterpriseEnablementEstimatesView: fieldConfig.enterpriseEnablementEstimatesView, matchAndClassification_export: fieldConfig.matchAndClassification_export , sccEnableInsightView : fieldConfig.SccEnableInsightView , sccView : fieldConfig.SccView,allSccView : fieldConfig.allSccView };
                            if (selectedColumns.length > 0) {
                                if (selectedColumns.indexOf(fieldName) > -1) {
                                    defaultScreenColumns.push(tableColumn);
                                    colOption.isSelected = true;
                                } else {
                                    colOption.isSelected = false;
                                }
                            } else {
                                defaultScreenColumns.push(tableColumn);
                                colOption.isSelected = true;
                            }

                            colOptions.push(colOption);
                        }

                        // add Update flag
                        userDefinedColumns.push({ label: "Update Flag", key: "isUserDefinedRowUpdated", seq: column.length + 1 })

                        //Sequence of columns in Grid
                        if (defaultScreenColumns.length > 0)
                            defaultScreenColumns = defaultScreenColumns.sort((a, b) => { return a.screenSeq - b.screenSeq })
                        //Sequence of columns in popup
                        if (colOptions.length > 0)
                            colOptions = colOptions.sort((a, b) => { return a.seq - b.seq })
                        //Sequence of columns in Excel
                        if (exportNonSensitiveColumns.length > 0)
                            exportNonSensitiveColumns = exportNonSensitiveColumns.sort((a, b) => { return a.seq - b.seq })
                        if (exportEnablementInsightColumns.length > 0)
                            exportEnablementInsightColumns = exportEnablementInsightColumns.sort((a, b) => { return a.seq - b.seq })
                        if (exportMatchAndClassificationColumns.length > 0)
                            exportMatchAndClassificationColumns = exportMatchAndClassificationColumns.sort((a, b) => { return a.seq - b.seq })
                        if (exportCustomerViewColumns.length > 0)
                            exportCustomerViewColumns = exportCustomerViewColumns.sort((a, b) => { return a.seq - b.seq })                        
                        if (exportAllColumns.length > 0) {
                            exportAllColumns = exportAllColumns.sort((a, b) => { return a.seq - b.seq })
                        }

                        var finalRows = [];
                        var finalFilteredRows = [];
                        let isColumnEmpty = {}
                        for (let rowindex = 0; rowindex < this.state.finalSpendData.length; rowindex++) {
                            var row = { isCapIQLicesend: self.state.isCapIQLicesend, isUserDefinedRowUpdated: 'No' };
                            var combineVendorDetails = '';
                            var combineIndustryDetails = '';
                            for (let Cindex = 0; Cindex < finalColumn.length; Cindex++) {
                                const element = finalColumn[Cindex];

                                var item = element["key"]
                                var itemVal = this.state.finalSpendData[rowindex][item] === null ? '' : this.state.finalSpendData[rowindex][item];

                                isColumnEmpty[item] = isColumnEmpty[item] !== undefined ? isColumnEmpty[item] && itemVal === '' : itemVal === ''
                                row[item] = itemVal;
                            }
                            finalRows.push(row);
                        }

                        let emptyColumns = []

                        Object.keys(isColumnEmpty).forEach(key => {
                            if (isColumnEmpty[key] &&
                                selectedColumns.indexOf(key) === -1 &&
                                !(['EXCLUSION_REASON', 'OVERRIDE_INDUSTRY_ID', 'DIRECT_SPEND', 'USER_COMMENT'].includes(key))) {
                                emptyColumns.push(key)
                            }
                        })

                        for (const key of emptyColumns) {
                            let idx = defaultScreenColumns.findIndex(item => {
                                return item.key === key
                            })

                            if (idx !== -1) {
                                defaultScreenColumns.splice(idx, 1);
                            }

                            idx = colOptions.findIndex(item => {
                                return item.id === key
                            })

                            if (idx !== -1) {
                                colOptions[idx].isSelected = false;
                            }
                        }

                        //Create a summation row
                        let summationArray = this.sum(finalRows, defaultScreenColumns);
                        // if(this.props.spendDetails.kpi_Model == 1)
                        //     finalRows.sort((a, b) => { return b['AVATAR_WAVE_SCORE'] - a['AVATAR_WAVE_SCORE'] });
                        // else
                        //     finalRows.sort((a, b) => { return b['KPI_ENABLE_MATCH_SPD_DOC_SCORE'] - a['KPI_ENABLE_MATCH_SPD_DOC_SCORE'] });
                        // //finalRows.sort((a, b) => { return a['DATA_COLLECTIONS_SKEY'] - b['DATA_COLLECTIONS_SKEY'] })
                        
                       
                        // finalRows.sort((a, b) => { return a[sortBy] - b[sortBy] }) 
                        // finalFilteredRows = [...finalRows]

                        var gridSortBy = this.state.gridSortBy;
                        var gridSortDirection = this.state.gridSortDirection;
                        var comparer = this.downloadSortedRecords(gridSortBy,gridSortDirection);
                        finalRows = finalRows.sort((a, b) => { return a['REF_NUMBER'] - b['REF_NUMBER'] }) 
                        finalRows = gridSortDirection == 'NONE' ? finalRows : finalRows.sort(comparer);
                        finalFilteredRows = [...finalRows]

                        exportNonSensitiveColumns.sort((a, b) => {
                            var seq1 = isNaN(a.screenSeq) ? 9999 : a.screenSeq;
                            var seq2 = isNaN(b.screenSeq) ? 9999 : b.screenSeq;
                            return seq1 - seq2
                        })
                        exportEnablementInsightColumns.sort((a, b) => {
                            var seq1 = isNaN(a.screenSeq) ? 9999 : a.screenSeq;
                            var seq2 = isNaN(b.screenSeq) ? 9999 : b.screenSeq;
                            return seq1 - seq2
                        })
                        exportMatchAndClassificationColumns.sort((a, b) => {
                            var seq1 = isNaN(a.screenSeq) ? 9999 : a.screenSeq;
                            var seq2 = isNaN(b.screenSeq) ? 9999 : b.screenSeq;
                            return seq1 - seq2
                        })
                        exportCustomerViewColumns.sort((a, b) => {
                            var seq1 = isNaN(a.screenSeq) ? 9999 : a.screenSeq;
                            var seq2 = isNaN(b.screenSeq) ? 9999 : b.screenSeq;
                            return seq1 - seq2
                        })                        
                        exportAllColumns.sort((a, b) => {
                            var seq1 = isNaN(a.screenSeq) ? 9999 : a.screenSeq;
                            var seq2 = isNaN(b.screenSeq) ? 9999 : b.screenSeq;
                            return seq1 - seq2
                        })


                        var pageCount = finalRows.length / this.state.perPage
                        this.originalColumns = cloneDeep(colOptions);

                        const headerToColumn = this.getHeaderGroups(defaultScreenColumns)

                        this.props.defineScopeLoading(false)

                        this.setState({
                            exportNonSensitiveColumns: exportNonSensitiveColumns, exportEnablementInsightColumns: exportEnablementInsightColumns, exportMatchAndClassificationColumns: exportMatchAndClassificationColumns,exportCustomerViewColumns : exportCustomerViewColumns, exportAllColumns: exportAllColumns, columns: colOptions, columnDefs: defaultScreenColumns,
                            rowData: finalRows, filteredRows: finalFilteredRows, pageCount: pageCount, isError: 'No',
                            isLoading: false, summationArray: summationArray, filteredSummationArray: { ...summationArray },
                            userSelectedColumns: selectedColumns, finalColumn: finalColumn, headerToColumn: headerToColumn, userDefinedColumns: userDefinedColumns
                        }, selectedColumns.length == 0 ? this.onColumnSelection("ExternalEnablementInsightView") : null)// default load enablement insight view
                    }
                });
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                }
            })
    }

    hideEmptyColumns() {
        let isColumnEmpty = {}
        const rowData = this.state.rowData;
        const columns = this.state.columns;
        const defaultScreenColumns = []

        for (let rowindex = 0; rowindex < rowData.length; rowindex++) {
            for (let Cindex = 0; Cindex < columns.length; Cindex++) {
                const element = columns[Cindex];

                var item = element["id"]
                var itemVal = rowData[rowindex][item]

                isColumnEmpty[item] = isColumnEmpty[item] !== undefined ? isColumnEmpty[item] && itemVal === '' : itemVal === ''
            }
        }

        let emptyColumns = []

        Object.keys(isColumnEmpty).forEach(key => {
            if (isColumnEmpty[key] &&
                !(['EXCLUSION_REASON', 'OVERRIDE_INDUSTRY_ID', 'DIRECT_SPEND', 'USER_COMMENT'].includes(key))) {
                emptyColumns.push(key)
            }
        })

        columns.forEach(item => {
            let idx = emptyColumns.findIndex(key => {
                return item.id === key
            })

            if (idx !== -1) {
                item.isSelected = false;
            } else {
                defaultScreenColumns.push(item.value)
                item.isSelected = true;
            }
        })

        this.originalColumns = cloneDeep(columns);

        return { columns: columns, defaultColumns: defaultScreenColumns }
    }

    sortFilteredRows = (filteredRows) => {
        if (!this.sortedColumns) {
            return filteredRows
        }

        var comparerAsc = (sortColumn) => (a, b) => {
            return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
        }

        var comparerDesc = (sortColumn) => (a, b) => {
            return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
        }

        this.sortedColumns.forEach((item) => {
            let sortColumn = item.key;
            let sortDirection = item.direction
            if (sortDirection === 'NONE') {
                return
            }

            var comparer = comparerAsc(sortColumn);
            if (sortDirection === 'DESC') {
                comparer = comparerDesc(sortColumn)
            }


            filteredRows.sort(comparer);
        })

        return filteredRows;
    };

    sortRows = (sortColumn, sortDirection) => {
        if (!this.sortedColumns) {
            this.sortedColumns = []
        }

        let rowIdx = this.sortedColumns.findIndex((item) => {
            return item.key === sortColumn
        })

        if (rowIdx !== -1) {
            this.sortedColumns.splice(rowIdx, 1);
        }

        this.sortedColumns.push({ key: sortColumn, direction: sortDirection })

        if (sortDirection === 'NONE') {
            return
        }

        var comparer = function (a, b) {
            if (sortDirection === 'ASC') {
                return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
            } else if (sortDirection === 'DESC') {
                return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
            }
        }


        var filteredRows = this.state.filteredRows;
        let firstRow = {}
        filteredRows = filteredRows.sort((a, b) => { return a['REF_NUMBER'] - b['REF_NUMBER'] });
        var rows = sortDirection === 'NONE' ? filteredRows : filteredRows.sort(comparer);

        var rowData = this.state.rowData;
        rowData = rowData.sort((a, b) => { return a['REF_NUMBER'] - b['REF_NUMBER'] });
        var originalRows = sortDirection === 'NONE' ? rowData : rowData.sort(comparer);

        this.sortColumn = sortColumn;
        var pageCount = rows.length / this.state.perPage
        this.setState({ filteredRows: [], rowData:[],  gridSortBy : sortColumn,gridSortDirection :sortDirection }, () => this.setState({ filteredRows: rows,rowData : originalRows }))
    };

    downloadSortedRecords = (sortColumn, sortDirection) => {
        if (!this.sortedColumns) {
            this.sortedColumns = []
        }

        let rowIdx = this.sortedColumns.findIndex((item) => {
            return item.key === sortColumn
        })

        if (rowIdx !== -1) {
            this.sortedColumns.splice(rowIdx, 1);
        }

        this.sortedColumns.push({ key: sortColumn, direction: sortDirection })

        if (sortDirection === 'NONE') {
            return
        }

        var comparer = function (a, b) {
            if (sortDirection === 'ASC') {
                return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
            } else if (sortDirection === 'DESC') {
                return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
            }
        }

        return comparer;
    };

    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.perPage);
        this.setState({ paginatedRowFrom: offset, paginatedRowTo: (offset + this.state.perPage) });
    };

    handleFilterChange(filter) {
        var rows = [...this.state.rowData];
        this.props.detailedFileFilterText(filter)
        let newFilters = { ...this.state.filters };
        if (filter === null || filter.filterTerm == null) {
            this.setState({ filters: null, filteredRows: rows }, this.getRows(rows, {}))
            return;

        } else if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }

        this.props.detailedFileFilters(newFilters)
        this.setState({ filters: newFilters }, this.getRows(rows, newFilters))

        return newFilters;
    };

    getValidFilterValues(columnId) {
        var rows = this.state.rowData
        var isFilterVisible = this.state.isFilterVisible
        this.filteredColumnValues = this.filteredColumnValues ? this.filteredColumnValues : []
        if (this.filteredColumnValues[columnId] === undefined) {
            var rows_filter = [...new Set(rows.map(item => item[columnId] != null ? item[columnId] : ""))]
            rows_filter.splice(0, 0, 'Not Included')
            this.filteredColumnValues[columnId] = rows_filter
            return rows_filter
        } else {
            return this.filteredColumnValues[columnId]
        }
    }

    getRows(rows, filters, node) {
        let normalFilters = {}
        let globalFilters = {}
        for (const [key, value] of Object.entries(filters)) {
            if (value.type === 'global') {
                globalFilters[key] = value
                delete filters[key]
            }
        }

        let hasNormalFilters = Object.values(filters).length > 0
        let hasGlobalFilters = Object.values(globalFilters).length > 0

        let filteredRowsTemp = []
        //let globalFilters = Object.values(filters).filter(filter => filter.type === 'global')


        Object.values(globalFilters).forEach(filter => {
            filter.filteredRows.forEach(filteredRow => {
                let idx = filteredRowsTemp.findIndex(row => {
                    return row['DATA_COLLECTIONS_SKEY'] === filteredRow['DATA_COLLECTIONS_SKEY']
                })

                if (idx === -1) {
                    filteredRowsTemp.push(filteredRow)
                }
            })
        })

        let filteredRows = []
        if (hasNormalFilters && hasGlobalFilters) {
            rows = filteredRowsTemp
            filteredRows = selectors.getRows({ rows, filters })
        }
        else if (!hasNormalFilters && hasGlobalFilters)
            filteredRows = filteredRowsTemp
        else if (hasNormalFilters && !hasGlobalFilters)
            filteredRows = selectors.getRows({ rows, filters })
        else
            filteredRows = rows

        filteredRows = this.sortFilteredRows(filteredRows)
        var pageCount = filteredRows.length / this.state.perPage;
        //Summation row logic
        let sum = this.state.summationArray
        if (Object.keys(filters).length >= 1) {
            sum = this.sum(filteredRows)
        }

        for (const [key, value] of Object.entries(globalFilters)) {
            filters[key] = value
        }

        this.setState({ filteredSummationArray: sum, filteredRows: filteredRows, paginatedRowFrom: 0, paginatedRowTo: this.state.perPage, pageCount: pageCount, filters: filters });
    }

    exportCsv() {
        var rowData = this.state.rowData;
        var sortBy = this.state.selectedSortBy;

        // rowData.sort((a, b) => { return a['REF_NUMBER'] - b['REF_NUMBER'] });
        // if (sortBy == 'ENABLEMENT_WAVE') {            
        //     rowData.sort(function (a, b) {
        //         return ('' + a[sortBy]).localeCompare(b[sortBy]);
        //     })
        // }
        // else
        //     rowData.sort((a, b) => { return a[sortBy] - b[sortBy] });
            
        // //rowData = this.downloadSortedRecords('REF_NUMBEFR','ASC',rowData);
        // var comparer = this.downloadSortedRecords(this.state.gridSortBy,this.state.gridSortDirection);
        // rowData = this.state.gridSortDirection == 'NONE'? rowData : rowData.sort(comparer);

        var self = this;

        let nonSensetiveKeys = this.state.exportNonSensitiveColumns.map(item => { return item.key; });
        let enablementKeys = this.state.exportEnablementInsightColumns.map(item => { return item.key; });
        let filteredCurrentView = this.state.columnDefs.filter(item => nonSensetiveKeys.includes(item.key) || enablementKeys.includes(item.key));

        const exportData = this.state.exportAction === 'currentViewColumns' ?
            filteredCurrentView : this.state.exportAction === 'customerViewColumns' ?
            this.state.exportCustomerViewColumns: this.state.exportAction === 'nonSensitiveColumns' ?
                this.state.exportNonSensitiveColumns : this.state.exportAction === 'enablementInsightColumns' ?
                    this.state.exportEnablementInsightColumns : this.state.exportAction === 'matchAndClassificationColumns' ?
                        this.state.exportMatchAndClassificationColumns : this.state.exportAction === 'matchAndClassificationColumnsCSV' ?
                            this.state.exportMatchAndClassificationColumns : this.state.exportAction === 'customerViewColumnsCSV' ?
                                this.state.exportCustomerViewColumns : this.state.exportAllColumns;

        // //add file name to sheet
        const fileName = this.state.exportAction === 'currentViewColumns' ?
            'Current View (Internal Only)' : this.state.exportAction === 'customerViewColumns' ?
                'Customer View' : this.state.exportAction === 'customerViewColumnsCSV' ?
                    'Customer View CSV': this.state.exportAction === 'nonSensitiveColumns' ?
                        'Standard (Internal Only)' : this.state.exportAction === 'enablementInsightColumns' ?
                            'Enablement Insight' : this.state.exportAction === 'matchAndClassificationColumns' ?                        
                                'M&C Detail (Internal Only)' : this.state.exportAction === 'matchAndClassificationColumnsCSV' ?                        
                                    'M&C Detail CSV (Internal Only)': this.state.exportAction === 'matchedOutputDemo' ?
                                        'SE Match Output': this.state.exportAction === 'matchedOutputDemoEnhanced' ?
                                            'SE Match Output Enhanced': this.state.exportAction === 'custFacingMatchedOutputDemo' ?
                                                'Customer Facing SE Match Output':'All';
                                            
        const csvRows = [];
        rowData.forEach((item) => {
            var row = {}
            exportData.forEach((cols) => {
                row[cols.key] = item[cols.key]
            })

            if (row["ENTERPRISE_ESTIM_SUPP_YIELD"] !== undefined && row["ENTERPRISE_ESTIM_SUPP_YIELD"] !== null && row["ENTERPRISE_ESTIM_SUPP_YIELD"] !== '')
                row["ENTERPRISE_ESTIM_SUPP_YIELD"] = row["ENTERPRISE_ESTIM_SUPP_YIELD"] + "%"

            csvRows.push(row);
        })

        var keys = exportData.map((cols) => {
            return cols.key
        })

        var headers = exportData.map((cols) => {
            return cols.label
        })

        const options = {
            filename: fileName,
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            keys: keys,
            headers: headers
        };

        const csvExporter = new ExportToCsv(options);

        var finalRows = [];
        var finalFilteredRows = [];
        let isColumnEmpty = {}
        let finalColumn = this.state.finalColumn;
        for (let rowindex = 0; rowindex < csvRows.length; rowindex++) {
            var row = { isCapIQLicesend: self.state.isCapIQLicesend };
            var combineVendorDetails = '';
            var combineIndustryDetails = '';
            for (let Cindex = 0; Cindex < finalColumn.length; Cindex++) {
                const element = finalColumn[Cindex];

                var item = element["key"]
                var itemVal = csvRows[rowindex][item] === null ? '' : csvRows[rowindex][item];

                isColumnEmpty[item] = isColumnEmpty[item] !== undefined ? isColumnEmpty[item] && itemVal === '' : itemVal === ''
                row[item] = itemVal;
            }
            finalRows.push(row);
        }
        this.insertReportAudit('Download - Spend',fileName + '.csv');
        csvExporter.generateCsv(finalRows);
        this.hideModal('alert');
        // if (self.props.user.userprofile.Roles[0].indexOf("Export All_Spend Details") > -1)
        //     this.hideModal('alert');        
        this.setState({ isSaveInProgress: false, isLoading: false });
    }

    initializeColumns(data) {
        let columns = []
        for (let index = 0; index < data.length; index++) {
            const fieldConfig = data[index];
            const editableColumns = ExcelUploadColumns.filter(x=>{
                return x.field == fieldConfig.key
            });
            var is_editable  = editableColumns.length > 0 ? editableColumns[0].is_editable : "No";
    
            let tableColumn = {
                header: fieldConfig.label,
                key: fieldConfig.key,
                width: fieldConfig.width,
                sortable: fieldConfig.sortable,
                editable: is_editable,
                isNumeric : false,
                // isTextWithLengtLimit : fieldConfig.data_type === 'text' && (!!fieldConfig.textLength),
                // textLength : fieldConfig.textLength,
                screenSeq: fieldConfig.screen_seq,
                mandatory: fieldConfig.mandatory === 'Yes',
                // exportFl: fieldConfig.export_fl === 'Y',
                editProperty: fieldConfig.editable,
                helpText: fieldConfig.helpText,
            }
            columns.push(tableColumn)
        }
    
        return columns;
    }
    handleClick(){
        // var excelButton = document.getElementsByClassName("button--accent")[0].parentNode;
        // excelButton.style.display = 'none';        
        this.setState({ isLoading: true },this.forceUpdate);

        setTimeout(() => {
            //this.exportExcel()
            if(this.state.exportAction === 'matchAndClassificationColumnsCSV' || this.state.exportAction === 'customerViewColumnsCSV')
                this.exportCsv();
            else
                this.exportExcel()
        }, 2000);
        //this.exportExcel()
    }
    
    createDynamicWorkSheet(workSheetName, Columns, workbook, rowData, firstRowLabel) {
        let dynamicWorksheet = workbook.addWorksheet(workSheetName);
        let exportData = Columns;
        const columnDetails = this.initializeColumns(exportData);
        dynamicWorksheet.columns = columnDetails;

        dynamicWorksheet.spliceRows(1, 0, []);
        dynamicWorksheet.spliceRows(1, 0, []);
        dynamicWorksheet.spliceRows(1, 0, []);

        let rowDataNew; 
        if (workSheetName === "Parent Account Info") {
            rowDataNew = rowData.filter(o => o.DEDUP_KEY.toLowerCase().includes('dedup'));
            rowDataNew.sort((a, b) => a['DEDUP_KEY'].toLowerCase() < b['DEDUP_KEY'].toLowerCase() ? -1 : 1);
        }
        else
            rowDataNew = rowData;

        rowDataNew.forEach((row, rowIndex) => {
            dynamicWorksheet.addRow(row);
            if (exportData.length > 0) {
                exportData.forEach((key, colIndex) => {
                    //console.log("key : " + key + " - value : " + row[key]);
                    if (exportData[colIndex].key != 'REF_NUMBER' && exportData[colIndex].filter_type == 'NumericFilterRenderer') {
                        var formatter = this.excelformatter(exportData[colIndex].alignment);
                        dynamicWorksheet.getRow(rowIndex + 5).getCell(colIndex + 1).numFmt = formatter;
                    }
                    var textFormatter = this.excelTextFormatter(exportData[colIndex].alignment);
                    dynamicWorksheet.getRow(rowIndex + 5).getCell(colIndex + 1).alignment = {
                        horizontal: textFormatter,
                    }
                });
            }
        });

        dynamicWorksheet.getRow(1).values = [firstRowLabel];

        const nonEditableHeader = columnDetails.filter(i => i.editable == "No")
        const editableHeader = columnDetails.filter(i => i.editable == "Yes")

        dynamicWorksheet.columns.forEach((column, index) => {
            //Set Column Width
            const lengths = column.values.map(v => v.toString().length);
            const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
            column.width = maxLength + 4;
        });

        nonEditableHeader.map(item => {
            dynamicWorksheet.getRow(4).getCell(item.key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '0B64A0' }
            };
            dynamicWorksheet.getRow(4).getCell(item.key).font = {
                name: 'BentonSans Book',
                size: 9,
                color: { argb: 'FFFFFF' },
                bold: true,
            };

            for (var rowNo = 3; rowNo <= 4; rowNo++) {
                dynamicWorksheet.getRow(rowNo).getCell(item.key).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            }
        });

        const textColor = (this.state.exportAction === 'enablementInsightColumns'  || 
            this.state.exportAction === 'customerViewColumns' ) ?
                'FFFFFF':'e9ff21';

        editableHeader.map(item => {
            dynamicWorksheet.getRow(4).getCell(item.key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '0B64A0' }
            };
            dynamicWorksheet.getRow(4).getCell(item.key).font = {
                name: 'BentonSans Book',
                size: 9,
                color: { argb: textColor },
                bold: true,
            };

            for (var rowNo = 3; rowNo <= 4; rowNo++) {
                dynamicWorksheet.getRow(rowNo).getCell(item.key).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            }
        }
        );
    }
        
    copyExcelWorkSheet(sheetNumber,sourceWorkBook,targetWorkBook,sheetName) {      

        let sheetToClone = sourceWorkBook.getWorksheet(sheetNumber);
        let copySheet = targetWorkBook.addWorksheet("Sheet");
        copySheet.model = sheetToClone.model;
        if(sheetName=='')
            copySheet.name = sheetToClone.name;
        else
            copySheet.name = sheetName
    };

    exportExcel() {
        // this.setState({ isLoading: true });
        //const rowData1 = this.state.rowData;
        var downloadType = 'Spend';
        var rowData1 = [];
        var sortBy = this.state.selectedSortBy;
        if (this.state.exportAction === 'matchedOutputDemo' || this.state.exportAction === 'custFacingMatchedOutputDemo' || this.state.exportAction === 'matchedOutputDemoEnhanced')
        {
            rowData1 = this.state.ndlData;
            downloadType = 'NDL';
        }
        else
            rowData1 = this.state.rowData;

        // rowData1.sort((a, b) => { return a['REF_NUMBER'] - b['REF_NUMBER'] });
        // if (sortBy == 'ENABLEMENT_WAVE') {            
        //     rowData1.sort(function (a, b) {
        //         return ('' + a[sortBy]).localeCompare(b[sortBy]);
        //     })
        // }
        // else
        //     rowData1.sort((a, b) => { return a[sortBy] - b[sortBy] });

        // //rowData1 = this.downloadSortedRecords('REF_NUMBEFR','ASC',rowData1);
        // rowData1 = this.downloadSortedRecords(this.state.gridSortBy,this.state.gridSortDirection,rowData1);
            

        const rowData = JSON.parse(JSON.stringify(rowData1));
        var self = this;

        let nonSensetiveKeys = this.state.exportNonSensitiveColumns.map(item => { return item.key; });
        let enablementKeys = this.state.exportEnablementInsightColumns.map(item => { return item.key; });
        //let filteredCurrentView = this.state.columnDefs.filter(item => nonSensetiveKeys.includes(item.key) || enablementKeys.includes(item.key));
        let filteredCurrentView = this.state.columnDefs;
        
        const exportData = this.state.exportAction === 'currentViewColumns' ?
            filteredCurrentView : this.state.exportAction === 'customerViewColumns' ?
                this.state.exportCustomerViewColumns : this.state.exportAction === 'nonSensitiveColumns' ?
                    this.state.exportNonSensitiveColumns : this.state.exportAction === 'enablementInsightColumns' ?
                        this.state.exportEnablementInsightColumns : this.state.exportAction === 'matchAndClassificationColumns' ?
                            this.state.exportMatchAndClassificationColumns : this.state.exportAction === 'matchedOutputDemo' ?
                                this.state.ndlColumns : this.state.exportAction === 'matchedOutputDemoEnhanced' ?
                                    this.state.ndlColumnsEnhanced : this.state.exportAllColumns;


        var headers = exportData.map((cols) => {
            return cols.label
        })

        this.setState({ responseMsg: "" })
        //create new excel work book
        
        // //add name to sheet
        const workSheetName = this.state.exportAction === 'currentViewColumns' ?
            'Current View' : this.state.exportAction === 'customerViewColumns' ?
                'Customer View' : this.state.exportAction === 'nonSensitiveColumns' ?
                    'Standard' : this.state.exportAction === 'enablementInsightColumns' ?
                        'Enablement Insight' : this.state.exportAction === 'matchAndClassificationColumns' ?                        
                            'M&C Detail' : this.state.exportAction === 'matchedOutputDemo' ?
                                'Match Output' : this.state.exportAction === 'matchedOutputDemoEnhanced' ?
                                    'Match Output': 'All';

        // //add file name to sheet
        const fileName = this.state.exportAction === 'currentViewColumns' ?
            'Current View (Internal Only)' : this.state.exportAction === 'customerViewColumns' ?
                'Customer View' : this.state.exportAction === 'customerViewColumnsCSV' ?
                    'Customer View CSV': this.state.exportAction === 'nonSensitiveColumns' ?
                        'Standard (Internal Only)' : this.state.exportAction === 'enablementInsightColumns' ?
                            'Enablement Insight' : this.state.exportAction === 'matchAndClassificationColumns' ?                        
                                'M&C Detail (Internal Only)' : this.state.exportAction === 'matchAndClassificationColumnsCSV' ?                        
                                    'M&C Detail CSV (Internal Only)': this.state.exportAction === 'matchedOutputDemo' ?
                                        'SE Match Output': this.state.exportAction === 'custFacingMatchedOutputDemo' ?
                                            'Customer Facing SE Match Output' : this.state.exportAction === 'matchedOutputDemoEnhanced' ?
                                                'SE Match Output Enhanced' :'All';

        

        //---------------------Copy Worksheet From Template---------------------        
            axios.get(path + 'Sample_SE_Match_Output.xlsx', { responseType: 'arraybuffer' })
                .then(response => {
                    const buffer = response.data;
                    const workbook1 = new Workbook();
                    return workbook1.xlsx.load(buffer);
                })
                .then(workbook1 => {
                    
                    let workbook = new Workbook();
                    let worksheet = workbook.addWorksheet(workSheetName);

                    // let sheetToClone = workbook1.getWorksheet(4);
                    // let copySheet = workbook.addWorksheet("Sheet");
                    // copySheet.model = sheetToClone.model;
                    // copySheet.name = sheetToClone.name;
                    if(this.state.exportAction === 'matchedOutputDemo' || this.state.exportAction === 'matchedOutputDemoEnhanced'){
                        this.copyExcelWorkSheet(1,workbook1,workbook,'');
                        this.copyExcelWorkSheet(2,workbook1,workbook,'');                        
                    }

        const columnDetails = this.initializeColumns(exportData);
        worksheet.columns = columnDetails;

        // for (const headerRow of exportData) {
        //     worksheet.addRow(headerRow.label);
        // }

        //    let summationArray = this.sum(rowData);
        //    worksheet.addRow(summationArray);
        worksheet.spliceRows(1, 0, []);
        worksheet.spliceRows(1, 0, []);
        worksheet.spliceRows(1, 0, []);

        let summationArrayKeys = ['AGGREGATE_INVOICE_COUNT', 'AGGREGATE_INVOICE_SPEND', 'AGGREGATE_PO_COUNT', 'AGGREGATE_PO_SPEND', 'AGREEGATE_PAYMENT_COUNT',
            'ERP_INVOICE_COUNT', 'ERP_PO_COUNT', 'PAYMENT_COUNT', "SAP_ARIBA_DOC_PROXY", "SAP_ARIBA_SPEND_PROXY", "SAP_ARIBA_SPEND_PROXY_USD", "AN_SMP_FEE_EST_BUYER_CURR", "ERP_INV_SPEND_IN_STD_CURR", "ENTERPRISE_ESTIM_SUPP_YIELD", "ENTERPRISE_ESTIM_SPEND_YIELD", "ENTERPRISE_ESTIM_DOC_YIELD", "ERP_DOCUMENT_CNT", "ERP_SPEND","ESTI_CAPTURABLE_NET_TRNS_FEE_REVENUE"]

                      
        rowData.forEach((row, rowIndex) => {
            worksheet.addRow(row);
            if (exportData.length > 0) {
                exportData.forEach((key, colIndex) => {
                    //console.log("key : " + key + " - value : " + row[key]);
                    if (exportData[colIndex].key != 'REF_NUMBER' && exportData[colIndex].filter_type == 'NumericFilterRenderer') {
                        var formatter = this.excelformatter(exportData[colIndex].alignment);
                        worksheet.getRow(rowIndex + 5).getCell(colIndex + 1).numFmt = formatter;
                    }
                    var textFormatter = this.excelTextFormatter(exportData[colIndex].alignment);
                    worksheet.getRow(rowIndex + 5).getCell(colIndex + 1).alignment = {
                        horizontal: textFormatter,
                    }
                });
            }
        });



                    if (this.state.exportAction != 'matchedOutputDemo' && this.state.exportAction != 'custFacingMatchedOutputDemo' && this.state.exportAction != 'matchedOutputDemoEnhanced') {
                        var headerRowMergeRowStart = 1;
                        var headerValue = '';
                        var printHeader = true;
                        var lastColumnHeaderValue = this.state.finalColumn.filter(item => item.key == exportData[exportData.length - 1].key)[0].headerGroup;
                        //var lastColumnHeaderValue = exportData[exportData.length-1].headerGroup;


                        exportData.forEach((key, index) => {
                            //Add header and merge header row.
                            //headerValue = exportData[index].headerGroup;
                            headerValue = self.state.finalColumn.filter(item => item.key == exportData[index].key)[0].headerGroup;
                            if (index == 0 && printHeader)                                                  // First Header
                                worksheet.getRow(3).getCell(index + 1).value = headerValue;
                            else if (headerValue != self.state.finalColumn.filter(item => item.key == exportData[index - 1].key)[0].headerGroup && printHeader) {
                                worksheet.getRow(3).getCell(index + 1).value = headerValue;
                                worksheet.mergeCells(3, headerRowMergeRowStart, 3, index);
                                headerRowMergeRowStart = index + 1;
                            }
                            else if (lastColumnHeaderValue == headerValue && printHeader)  // Last Header
                            {
                                worksheet.mergeCells(3, headerRowMergeRowStart, 3, exportData.length);
                                printHeader = false;
                            }
                            //--------------------------------


                            let colName = this.getExcelColumnName(index + 1);
                            if (exportData[index].key != 'REF_NUMBER' && summationArrayKeys.includes(exportData[index].key) && exportData[index].filter_type == 'NumericFilterRenderer') {
                                worksheet.getCell(colName + '2').value = { formula: 'SUM(' + colName + '5:' + colName + '100000)' };

                                var formatter = this.excelformatter(exportData[index].alignment);
                                worksheet.getRow(2).getCell(index + 1).numFmt = formatter;
                                var textFormatter = this.excelTextFormatter(exportData[index].alignment);
                                worksheet.getRow(2).getCell(index + 1).alignment = {
                                    horizontal: textFormatter,
                                }
                            }
                            else if (exportData[index].key == 'REF_NUMBER') {
                                worksheet.getCell(colName + '2').value = 'Totals';
                            }

                        });
                    }
        let fname = fileName; //'spend' ;//this.props.content.uploadTemplateFile

        let nonEditableHeader = columnDetails.filter(i => i.editable == "No")
        let editableHeader = columnDetails.filter(i => i.editable == "Yes")

        if (this.state.exportAction === 'matchedOutputDemo' || this.state.exportAction === 'custFacingMatchedOutputDemo' || this.state.exportAction === 'matchedOutputDemoEnhanced') {
            
            nonEditableHeader = columnDetails;
            editableHeader = [];
        }

        worksheet.columns.forEach((column,index) => {
            
            //Set Column Width
            const lengths = column.values.map(v => v.toString().length);
            const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
            column.width = maxLength + 4;


            //Set Font and Colour
            worksheet.getRow(1).getCell(index + 1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF' },
            };
            worksheet.getRow(1).getCell(index + 1).font = {
                name: 'BentonSans Book',
                size: 11,
                color: { argb: '000000' },
                bold: true
            };


            if (this.state.exportAction != 'matchedOutputDemo' && this.state.exportAction != 'custFacingMatchedOutputDemo' && this.state.exportAction != 'matchedOutputDemoEnhanced') {
                worksheet.getRow(2).getCell(index + 1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'D9E1F2' }
                };
                worksheet.getRow(2).getCell(index + 1).font = {
                    name: 'BentonSans Book',
                    size: 10,
                    color: { argb: '000000' },
                    bold: true,
                };

                worksheet.getRow(3).getCell(index + 1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '8EA9DB' }
                };
                worksheet.getRow(3).getCell(index + 1).font = {
                    name: 'BentonSans Book',
                    size: 10,
                    color: { argb: 'FFFFFF' },
                    bold: true,
                };
            }
        });

        //let summationArray = this.sum(rowData);
        ////worksheet.getRow(2).values = summationArray; 

        const currentRowIdx = worksheet.rowCount; // Find out how many rows are there currently
        const endColumnIdx = worksheet.columnCount; // Find out how many columns are in the worksheet 


        if (this.state.exportAction === 'matchedOutputDemo' || this.state.exportAction === 'matchedOutputDemoEnhanced') 
            worksheet.getRow(1).values = ["Some information in this sheet is Internal Only.  Share only the Customer Facing Template tab with external users."];
        else if (this.state.exportAction != 'customerViewColumns')
            worksheet.getRow(1).values = ["The data contained in this file is for internal use only. Do not distribute outside of SAP/Ariba."];
        // merge by start row, start column, end row, end column
        //worksheet.mergeCells(1, 1, 1, 10);

        // worksheet.getRow(3).values = ["Customer Input Data"];
        // // merge by start row, start column, end row, end column
        // worksheet.mergeCells(3, 1, 3, 10);


        //worksheet.getRow(1).height = 200;       
        

        nonEditableHeader.map(item => {
            worksheet.getRow(4).getCell(item.key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '0B64A0' }
            };
            worksheet.getRow(4).getCell(item.key).font = {
                name: 'BentonSans Book',
                size: 9,
                color: { argb: 'FFFFFF' },
                bold: true,
            };

            for (var rowNo = 3; rowNo <= 4; rowNo++) {
                worksheet.getRow(rowNo).getCell(item.key).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            }
        });

        const textColor = (this.state.exportAction === 'enablementInsightColumns'  || 
            this.state.exportAction === 'customerViewColumns' ) ?
                'FFFFFF':'e9ff21';

        editableHeader.map(item => {
            worksheet.getRow(4).getCell(item.key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '0B64A0' }
            };
            worksheet.getRow(4).getCell(item.key).font = {
                name: 'BentonSans Book',
                size: 9,
                color: { argb: textColor },
                bold: true,
            };

            for (var rowNo = 3; rowNo <= 4; rowNo++) {
                worksheet.getRow(rowNo).getCell(item.key).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            }

            // // make header row readonly 
            // worksheet.getRow(4).getCell(item.key).protection = {
            //     locked: false,
            //     hidden: false,
            // };

            //          // Add autofilter to all column
            //    worksheet.autoFilter = 'A1:V1';


            //worksheet.getCell('E2').value = { formula:'E5+E6'};  

        }
        );

        // to make header row frozen
        worksheet.views = [
            { state: 'frozen', ySplit: 1 }
        ];

                    if (this.state.exportAction === 'custFacingMatchedOutputDemo') { 
                        this.copyExcelWorkSheet(8,workbook1,workbook,'READ ME');
                        this.copyExcelWorkSheet(2,workbook1,workbook,'');        
                        workbook.removeWorksheet(1);                       
                        // -----   Code to add Customer Facing Template tab Start
                        var rowDataCFT = [...rowData];

                        rowDataCFT.map(a => {
                            if (a.EXPOSE_SUPP_SEARCH_RESULT == 'N'
                                || a.SUPP_ALLOW_BUYER_CONTACT == 'N'
                                || a.SUPP_ALLOW_SEARCH_BUYER == 'No'
                                || a.EXPOSE_ADMIN_PERSONAL_CONTACT_INFO == 'No') {
                                a.EMAIL = '';
                                a.CONTACTNAME = '';
                                a.CONTACTPHONE = ''
                            }
                            return a;
                        });
                        this.createDynamicWorkSheet('Customer Facing Template', this.state.ndlCustFacingColumns, workbook, rowDataCFT, 'Customer Facing Template');

                        //this.createDynamicWorkSheet('Customer Facing Template',this.state.ndlCustFacingColumns,workbook,rowData,'Customer Facing Template');                      
                        
                    }


                    if (this.state.exportAction === 'matchedOutputDemo' || this.state.exportAction === 'matchedOutputDemoEnhanced') {
                        let sheetToClone = workbook1.getWorksheet(2);
                        let copySheet = workbook.addWorksheet("Sheet");                        
                        // Copy Newly created M&C Worksheet in Existing worksheet
                        copySheet.model = worksheet.model;
                        //Delete Newly created M&C Worksheet
                        // workbook.removeWorksheet(1);
                        //Rename Existing worksheet
                        copySheet.name = workSheetName; 
                        
                        //this.copyExcelWorkSheet(4,workbook1,workbook,'');

                        // // // let copySheet1 = workbook.addWorksheet("Sheet");                        
                        // // // // Copy Newly created M&C Worksheet in Existing worksheet
                        // // // copySheet1.model = worksheet.model;
                        //Delete Newly created M&C Worksheet
                        workbook.removeWorksheet(1);
                        // // // //Rename Existing worksheet
                        // // // copySheet1.name = 'Customer Facing Template Remove';
                        
                         // -----   Code to add Do-Not-Share Suppliers tab Start
                         var rowDataDNS = [...rowData];
                         rowDataDNS = rowDataDNS.filter(a=> a.EXPOSE_SUPP_SEARCH_RESULT == 'N'
                         || a.SUPP_ALLOW_BUYER_CONTACT == 'N'
                         || a.SUPP_ALLOW_SEARCH_BUYER == 'No'
                         || a.EXPOSE_ADMIN_PERSONAL_CONTACT_INFO == 'No');
                         this.createDynamicWorkSheet('Do-Not-Share Suppliers',this.state.ndlColumnsDoNotShareWithCustomer,workbook,rowDataDNS,'This sheet contains protected PII.  Data contained in this sheet is for internal use only.');                      
                        

                        //----------------------------------------------------------

                        // -----   Code to add Customer Facing Template tab Start

                        var rowDataCFT = [...rowData];

                        rowDataCFT.map(a => {
                            if (a.EXPOSE_SUPP_SEARCH_RESULT == 'N'
                                || a.SUPP_ALLOW_BUYER_CONTACT == 'N'
                                || a.SUPP_ALLOW_SEARCH_BUYER == 'No'
                                || a.EXPOSE_ADMIN_PERSONAL_CONTACT_INFO == 'No') {
                                a.EMAIL = '';
                                a.CONTACTNAME = '';
                                a.CONTACTPHONE = ''
                            }
                            return a;
                        });
                        this.createDynamicWorkSheet('Customer Facing Template', this.state.ndlCustFacingColumns, workbook, rowDataCFT, 'Customer Facing Template');


                        // // // workbook.removeWorksheet(6);

                        //------   Code to add Customer Facing Template tab End

                        // -----   Code to add Parent Account Info tab Start
                        this.createDynamicWorkSheet('Parent Account Info',this.state.ndlParentAccountInfoColumns,workbook,rowData,'Parent Account Info');                       

                        // Add Input Data Quality worksheet
                        workbook.addWorksheet("Input Data Quality");

                        

                        // -----   Code to add Parent Account Info tab End.
                    }

        // // below column used internally 		
        // worksheet.getColumn('SAP_ARIBA_DOC_PROXY').hidden = true;    
        // worksheet.getColumn('SAP_ARIBA_SPEND_PROXY').hidden = true;     
        // worksheet.getColumn('V').hidden = true;

        //add data and file name and download
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, fname + '.xlsx');
            this.setState({ isSaveInProgress: false,isLoading: false });
            // var excelButton = document.getElementsByClassName("button--accent")[0].parentNode;
            // excelButton.style.display = 'block';
            if(this.state.exportAction === 'matchedOutputDemo' || this.state.exportAction === 'custFacingMatchedOutputDemo' || this.state.exportAction === 'matchedOutputDemoEnhanced')
                this.hideModal('ndlAlert');
            else
                this.hideModal('alert');
            this.insertReportAudit('Download - ' + downloadType,fname + '.xlsx');
        });

        console.log('reading Excel done');
                })
                .catch(error => {
                    console.error('Error reading Excel file:', error);
                });
        //}.bind(this), 10000)
        //----------------------------------------------------
    }

    getExcelColumnName(columnNumber) {
        // To store result (Excel column name)
        let columnName = [];

        while (columnNumber > 0) {
            // Find remainder
            let rem = columnNumber % 26;

            // If remainder is 0, then a
            // 'Z' must be there in output
            if (rem == 0) {
                columnName.push("Z");
                columnNumber = Math.floor(columnNumber / 26) - 1;
            }
            else // If remainder is non-zero
            {
                columnName.push(String.fromCharCode((rem - 1) + 'A'.charCodeAt(0)));
                columnNumber = Math.floor(columnNumber / 26);
            }
        }

        // Reverse the string
        return columnName.reverse().join("")
    }

    excelformatter = (formatterName) => {
        switch (formatterName) {
            case 'LeftAlignFormatter':
                return '#,##0'
            case 'AlignFormatter':
                return '#,##0'//formatThousandsWithBlank(roundFixWithBlank(value, 0))
            case 'RoundFormatter':
                return '#,##0.0000'//formatThousandsWithBlank(roundFixWithBlank(value, 4))
            case 'CenterAlignFormatter':
                return '#,##0'
            case 'RightAlignFormatter':
                return '#,##0'
            case 'AlignCurrencyFormatter':
                return '#,##0'//formatThousandsWithBlank(roundFixWithBlank(value, 0))
            case 'RoundCurrencyFormatter':
                return '#,##0.0000'//formatThousandsWithBlank(roundFixWithBlank(value, 4))
            case 'LinkFormatter':
                return '#,##0'
            case 'RoundFormatter2':
                return '#,##0.00'//formatThousandsWithBlank(roundFixWithBlank(value, 2))
            case 'IntegralpartFormatter':
                return '#,##0'//vformatThousandsWithBlank(integralPartWithBlank(value))
            case 'IntegralpartPercentFormatter':
                return '#,##0'//formatThousandsWithBlank(integralPartWithBlank(value))
            default:
                return undefined;
        }
    }

    excelTextFormatter = (formatterName) => {
        switch (formatterName) {
            case 'LeftAlignFormatter':
                return 'left'
            case 'AlignFormatter':
                return 'right'
            case 'RoundFormatter':
                return 'right'
            case 'CenterAlignFormatter':
                return 'center'
            case 'RightAlignFormatter':
                return 'right'
            case 'AlignCurrencyFormatter':
                return 'right'
            case 'RoundCurrencyFormatter':
                return 'right'
            case 'LinkFormatter':
                return 'left'
            case 'RoundFormatter2':
                return 'right'
            case 'IntegralpartFormatter':
                return 'right'
            case 'IntegralpartPercentFormatter':
                return 'right'
            default:
                return 'right';
        }
    }

    industryOverride() {
        if (this.props.overridedIndustryDetails !== undefined && this.props.overridedIndustryDetails !== null
            && this.props.overridedIndustryDetails.INDUSTRY_ID !== undefined && this.props.overridedIndustryDetails.INDUSTRY_ID !== null) {
            this.onGridRowsUpdated({ fromRow: this.state.industryOverrideRowNo, toRow: this.state.industryOverrideRowNo, updated: { 'OVERRIDE_INDUSTRY_ID': this.props.overridedIndustryDetails.INDUSTRY_ID } })
            this.props.selectedIndustryDetails(null)//Clear the previous selection
            this.setState({ open: !this.state.open })
        }
    }

    clearIndustryOverride() {
        this.onGridRowsUpdated({ fromRow: this.state.industryOverrideRowNo, toRow: this.state.industryOverrideRowNo, updated: { 'OVERRIDE_INDUSTRY_ID': '' } })
        this.setState({ open: !this.state.open })
    }

    closePopUp() {
        this.setState({ open: !this.state.open })
    }

    outsidePopupClick() {
        this.setState({ open: false })
    }

    enablementWaveInput() {
        if (this.props.enablementWaveInputDetails !== undefined && this.props.enablementWaveInputDetails !== null) {
            this.onGridRowsUpdated({ fromRow: this.state.enablementWaveInputRowNo, toRow: this.state.enablementWaveInputRowNo, updated: { 'ENABLEMENT_WAVE': this.props.enablementWaveInputDetails == '' || this.props.enablementWaveInputDetails == 0 ? 1 : this.props.enablementWaveInputDetails } })
            this.props.selectedEnablementWaveDetails(null)//Clear the previous selection
            //this.setState({ openEnablementInsightPopUp: !this.state.openEnablementInsightPopUp })
        }
        this.setState({ openEnablementInsightPopUp: !this.state.openEnablementInsightPopUp })
    }

    clearEnablementWaveOverride() {
        this.onGridRowsUpdated({ fromRow: this.state.enablementWaveInputRowNo, toRow: this.state.enablementWaveInputRowNo, updated: { 'ENABLEMENT_WAVE': '' } })
        this.setState({ openEnablementInsightPopUp: !this.state.openEnablementInsightPopUp })
    }

    closeEnablementInsightPopUp() {
        this.setState({ openEnablementInsightPopUp: !this.state.openEnablementInsightPopUp })
    }

    outsideEnablementInsightPopupClick() {
        this.setState({ openEnablementInsightPopUp: false })
    }

    addUserComments(comments) {
        this.onGridRowsUpdated({ fromRow: this.state.userCommentsRowNo, toRow: this.state.userCommentsRowNo, updated: { 'USER_COMMENT': comments } })
        this.setState({ openUserCommentsPopUp: !this.state.openUserCommentsPopUp })
    }

    clearUserComments() {
        if (moment(this.state.userCommentInsideCell, 'MM/DD/YYYY', true).isValid() === false) {
            this.onGridRowsUpdated({ fromRow: this.state.userCommentsRowNo, toRow: this.state.userCommentsRowNo, updated: { 'USER_COMMENT': '' } })
        }
        this.setState({ openUserCommentsPopUp: !this.state.openUserCommentsPopUp })
    }

    closeUserCommentsPopUp() {
        this.setState({ openUserCommentsPopUp: !this.state.openUserCommentsPopUp })
    }

    outsideUserCommentsPopupClick() {
        this.setState({ openUserCommentsPopUp: false })
    }

    capIqIdOverride() {
        //console.log("from details file props data: ", this.props.overridedCapIqIdDetails)
        if (this.props.overridedCapIqIdDetails !== undefined && this.props.overridedCapIqIdDetails !== null
            && this.props.overridedCapIqIdDetails.COMPANY_ID !== undefined && this.props.overridedCapIqIdDetails.COMPANY_ID !== null) {
            this.onGridRowsUpdated({
                fromRow: this.state.capIqIdOverrideRowNo, toRow: this.state.capIqIdOverrideRowNo,
                updated: { 'CAPIQ_COMPANY_ID': this.props.overridedCapIqIdDetails.COMPANY_ID }
            })
            this.props.selectedCapIqIdDetails(null)// Clear the previous selection
            this.setState({ openCapIq: !this.state.openCapIq })
        }
    }

    clearCapIqIdOverride() {
        this.onGridRowsUpdated({ fromRow: this.state.capIqIdOverrideRowNo, toRow: this.state.capIqIdOverrideRowNo, updated: { 'CAPIQ_COMPANY_ID': '' } })
        this.setState({ openCapIq: !this.state.openCapIq })
    }

    closeCapIqIdPopUp() {
        this.setState({ openCapIq: !this.state.openCapIq })
    }

    outsideCapIqIdPopupClick() {
        this.setState({ openCapIq: false })
    }

    filterValidationRecords(e, skeys, recordType) {
        const rowData = this.state.rowData;
        const filteredRows = [];
        skeys.forEach((item) => {
            rowData.sort((a, b) => { return a['DATA_COLLECTIONS_SKEY'] - b['DATA_COLLECTIONS_SKEY'] })  // binary search work with sorted array only
            var rowIdx = binarySearchIndex(rowData, item, 'DATA_COLLECTIONS_SKEY')
            var idx = filteredRows.findIndex((item) => {
                return item['DATA_COLLECTIONS_SKEY'] === rowData[rowIdx]['DATA_COLLECTIONS_SKEY']
            })

            if (rowIdx !== -1 && idx === -1) {
                filteredRows.push(rowData[rowIdx]);
            }
        })
        let newFilters = { ...this.state.filters };
        newFilters[recordType] = { filteredRows: [...filteredRows], filterTerm: 'Yes', 'rawValue': skeys.length, type: 'global', column: { name: recordType, key: recordType } };

        this.props.detailedFileFilters(newFilters)

        this.setState({ filters: newFilters }, () => {
            this.getRows(this.state.rowData, newFilters)
        })
        e.stopPropagation();
    }

    //Remove this after ticket 319 is done
    // snapFilters() {
    //     this.getAppliedFilters()
    // }

    // clearFilter(e){
    //     let filter = this.state.filters
    //     let rows = [...this.state.rowData];
    //     console.log(e.target.value)
    //     let key = e.target.value;
    //     delete filter[key];
    //     this.setState({filter: filter}, this.getRows(rows, filter))
    // }

    // getAppliedFilters() {
    //     let filters = this.state.filters;
    //     let filterArray = [];
    //     const colConfig = this.props.content.detailedFileFields;
    //     const colConfigArr = [];
    //     colConfig.forEach((item, index) => {
    //         for (var key in item) {
    //             if (key === 'field') {
    //                 colConfigArr[item[key]] = item;
    //                 break;
    //             }
    //         }
    //     })

    //     for (let [key, value] of Object.entries(filters)) {
    //         if (typeof (value.filterTerm) != 'string') {
    //             filterArray.push({ "key": colConfigArr[key].screen_field_name, "value": value.filterTerm[0].value })
    //         } else {
    //             filterArray.push({ "key": colConfigArr[key].screen_field_name, "value": value.filterTerm })
    //         }
    //     }//(`${key}: ${value}`)
    //     let result = []
    //     if (filterArray.length > 0) {
    //         result = (<React.Fragment>
    //             <div onClick={this.filterToggle} className="float-left plus-icon medium-1">
    //                 {!this.state.filterFlag ? <img width="10" src="images/minus-inactive.svg" /> : <img width="10" src="images/add.svg" />}
    //             </div>
    //             <p>{"Applied filters are: "}</p>
    //             {this.state.filterFlag ? null :
    //                 filterArray.map(item => (
    //                     <div>
    //                         <ol>
    //                             <li>{item.key}: {item.value}</li>
    //                             {/* <button value={item.key} onClick={e => this.clearFilter(e)}>clear</button> */}
    //                         </ol>
    //                     </div>
    //                 )
    //                 )}
    //         </React.Fragment>)
    //     } else {
    //         result = (<React.Fragment>
    //             <div id="col1">{"No filters are applied"}</div>
    //         </React.Fragment>)
    //     }
    //     this.setState({ filterDiv: result })
    // }

    filterToggle() {
        this.setState((previousState) => { return { filterFlag: !previousState.filterFlag } })
    }

    getValidationDiv() {
        const pendingRecords = this.state.pendingRecords;
        const warningRecords = this.state.warningRecords;
        const errorRecords = this.state.errorRecords;
        if (pendingRecords.length === 0) {
            this.props.pendingChanges(null)
            return '';
        } else {
            this.props.pendingChanges(pendingRecords)
        }

        var warningRecordSize = 0;
        var totalWarningRecords = []
        Object.values(warningRecords).forEach((item) => {
            totalWarningRecords = totalWarningRecords.concat(item);
        })
        var warningDiv = Object.keys(warningRecords).map((key, index) => (
            warningRecords[key].length !== 0 ?
                <div className="grid-x medium-12 message-sub-row hand-cursor" onClick={(e) => this.filterValidationRecords(e, warningRecords[key], 'warning(s)')}>
                    <span>
                        <img width="10" src="images/Dot.svg" />
                    </span>
                    <div className="medium-10">
                        {key} {this.props.content.validationForText}
                        <span className="review-warning"> {formatThousands(warningRecords[key].length)} {this.props.content.validationRowsText}
                        </span>
                    </div>
                </div> : ''
        ))

        var errorRecordSize = 0;
        var totalErrorRecords = []
        Object.values(errorRecords).forEach((item) => {
            totalErrorRecords = totalErrorRecords.concat(item);
        })

        var errorDiv = Object.keys(errorRecords).map((key, index) => (
            errorRecords[key].length !== 0 ?
                <div className="grid-x medium-12 message-sub-row hand-cursor" onClick={(e) => this.filterValidationRecords(e, errorRecords[key], 'error(s)')}>
                    <span>
                        <img width="10" src="images/Dot.svg" />
                    </span>
                    <div className="medium-10">
                        {key} {this.props.content.validationForText}
                        <span className="review-errors"> {formatThousands(errorRecords[key].length)} {this.props.content.validationRowsText}
                        </span>
                    </div>
                </div> : ''
        ))

        var validationDiv = (
            <div className="validation-messages medium-12">
                <div className="grid-x">
                    <div className="medium-12 message-row hand-cursor" onClick={(e) => this.filterValidationRecords(e, pendingRecords, 'pending')}>
                        <span className="pending-changes">
                            <img width="15" src="images/Dot.svg" />{formatThousands(pendingRecords.length)} {this.props.content.validationRecordText} </span>
                        {this.props.content.pendingChangeMessage}
                    </div>
                    {totalWarningRecords.length > 0 ?
                        <div className="grid-x medium-12 message-row">
                            <div className="medium-11 hand-cursor" onClick={(e) => this.filterValidationRecords(e, totalWarningRecords, 'warning(s)')}>
                                <span className="review-warning">
                                    <img width="15" src="images/Dot.svg" />{formatThousands(totalWarningRecords.length)} {this.props.content.validationWarningText} </span>{this.props.content.warningChangeMessage}
                            </div>
                            <div onClick={this.warningToggle} className="float-right plus-icon medium-1">
                                {this.state.warningDiv ? <img width="10" src="images/minus-inactive.svg" /> : <img width="10" src="images/add.svg" />}
                            </div>
                            {this.state.warningDiv ? warningDiv : ''}
                        </div> : ''}
                    {totalErrorRecords.length > 0 ?
                        <div className="grid-x medium-12 message-row">
                            <div className="medium-11 hand-cursor" onClick={(e) => this.filterValidationRecords(e, totalErrorRecords, 'error(s)')}>
                                <span className="review-errors">
                                    <img width="15" src="images/Dot.svg" />{formatThousands(totalErrorRecords.length)} {this.props.content.validationErrorText} </span>{this.props.content.validationThatText}<span className="font-bold"> {this.props.content.validationMustText} </span>{this.props.content.errorChangeMessage}
                            </div>
                            <div onClick={this.errorToggle} className="float-right plus-icon medium-1">
                                {this.state.errorDiv ? <img width="10" src="images/minus-inactive.svg" /> : <img width="10" src="images/add.svg" />}
                            </div>
                            {this.state.errorDiv ? errorDiv : ''}
                        </div> : ''}
                </div>
            </div>)

        return validationDiv;
    }

    warningToggle() {
        this.setState((previousState) => { return { warningDiv: !previousState.warningDiv } })
    }

    errorToggle() {
        this.setState((previousState) => { return { errorDiv: !previousState.errorDiv } })
    }

    showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
        this.refs[action].hide()
    }

    closeColumnFieldPopup() {
        this.setState({ columns: cloneDeep(this.originalColumns) });
        this.hideModal('ColumnFields');
    }

    closeUploadFieldPopup() {
        this.setState({ columns: cloneDeep(this.originalColumns) });
        this.hideModal('UploadUserDefinedFields');
    }

    removeFilter(filter, item1, removeAll = false) {
        var breadCrumbsArray = this.state.filters;
        if (breadCrumbsArray) {
            Object.values(breadCrumbsArray).forEach(item => {
                if (item.type !== NumericFilterRenderer && item.type !== TextfilterRenderer && item.type !== 'global') {
                    if (item.clear) {
                        item.clear()
                    }

                    if (removeAll) {
                        item.filterTerm.length = 0;
                        return;
                    }
                    var idx = item.filterTerm.findIndex(t => t.value === item1 && item.column.key === filter.column.key)
                    if (idx !== -1) {
                        if (item.clear) {
                            item.clear()
                        }
                        item.filterTerm.splice(idx, 1)

                        if (item.filterTerm.length === 0) {
                            delete breadCrumbsArray[filter.column.key]
                        }
                    }
                } else if (removeAll || (item.column.key === filter.column.key && item.rawValue === item1)) {
                    if (item.clear) {
                        item.clear()
                    }

                    if (!removeAll)
                        delete breadCrumbsArray[filter.column.key]
                }
            })
        }

        if (removeAll) {
            breadCrumbsArray = {}
        }


        var rows = [...this.state.rowData];
        this.setState({ filters: breadCrumbsArray }, () => {
            this.getRows(rows, breadCrumbsArray)
        })
    }

    expandFilter(item) {
        let filters;
        (item.type === NumericFilterRenderer || item.type === TextfilterRenderer || item.type === 'global') ?
            filters = (<div className='breadcrumbsClose float-left'
                content={item.rawValue} onClick={() => this.removeFilter(item, item.rawValue)}
                color
            >{item.rawValue}
                <img className="ml5" width="10" src="images/close_inactive.svg" />
            </div>) :
            filters = item.filterTerm.map((item1) => (
                (<div className='breadcrumbsClose float-left'
                    content={item1.value} onClick={() => this.removeFilter(item, item1.value)}
                    color
                >{item1.value}
                    <img className="ml5" width="10" src="images/close_inactive.svg" />
                </div>)
            ))

        return filters
    }

    sum(finalRows, colDefs = this.state.columnDefs) {
        if (finalRows.length >= 1) {
            let arr2 = cloneDeep(finalRows[0]);
            for (const key in arr2) {
                arr2[key] = ''
            }

            arr2['REF_NUMBER'] = 'Total :'

            let summationArrayKeys = ['AGGREGATE_INVOICE_COUNT', 'AGGREGATE_INVOICE_SPEND', 'AGGREGATE_PO_COUNT', 'AGGREGATE_PO_SPEND', 'AGREEGATE_PAYMENT_COUNT',
                'ERP_INVOICE_COUNT', 'ERP_PO_COUNT', 'PAYMENT_COUNT', "SAP_ARIBA_DOC_PROXY", "SAP_ARIBA_SPEND_PROXY", "SAP_ARIBA_SPEND_PROXY_USD","AN_SMP_FEE_EST_BUYER_CURR","ERP_INV_SPEND_IN_STD_CURR","ENTERPRISE_ESTIM_SUPP_YIELD","ENTERPRISE_ESTIM_SPEND_YIELD","ENTERPRISE_ESTIM_DOC_YIELD","ERP_DOCUMENT_CNT","ERP_SPEND","ESTI_CAPTURABLE_NET_TRNS_FEE_REVENUE"]

            summationArrayKeys.forEach(key => arr2[key] = 0)

            finalRows.forEach(item => {
                summationArrayKeys.forEach(key => {
                    arr2[key] += (item[key] === '' ? 0 : item[key])
                })
            })

            arr2['ENTERPRISE_ESTIM_SUPP_YIELD'] = arr2['ENTERPRISE_ESTIM_SUPP_YIELD'] / 100;
            return arr2;
        }
    }

    capIqDataCleared() {
        var array2 = [];
        let currencyCodes = this.props.discovery.currencyCodes;
        for (let i = 1; i < currencyCodes.length; i++) {
            let obj = { label: currencyCodes[i], value: currencyCodes[i].split(' - ')[0] }
            array2.push(obj);
        }
        this.setState({ currencyOption: array2 });
        let idx = array2.findIndex((element) => {
            return element.value === 'USD'
        })


        let selectedCurrencySym = this.props.currencySymbols[array2[idx].value];

        this.getCurrency({ label: selectedCurrencySym, value: array2[idx].value })


        //this.dispatchUpdateCurrency(selectedCurrencySym);

        this.setState({ selectedCurrencySymbol: selectedCurrencySym });
        // set default value for currency 
        this.setState({ selectedCurrency: array2[idx] });
        this.props.dispatchCapIQChange('clear')
        this.setState({ capIQId: '', selectedCapIQCompanyId: '', selectedLocation: this.props.discovery.locations[0], selectedIndustry: this.props.discovery.industries[0] })
    }

    capIqRadioButtonClicked(data) {
        this.props.userData.CapIQId = data.COMPANY_ID
        this.props.userData.CapIQCompany = data.COMPANY_NAME
        this.props.userData.Organization = data.COMPANY_NAME
        //this.bindIndustryAndLocation()
        //this.formDispatch(actions.change('discovery.Organization', data.COMPANY_NAME));
        if (this.props.userData.CapIQCompany) {
            this.setState({ selectedCapIQCompanyId: this.props.userData.CapIQCompany + ' | ' + this.props.userData.CapIQId, organization: data.COMPANY_NAME }, this.callCapIQProcedure);
        } else {
            this.setState({ selectedCapIQCompanyId: '' })
        }

        this.props.userData.AnnualRevenue = data.COMPANY_REVENUE ? data.COMPANY_REVENUE + '' : null;
        //this.dispatchAddRevenue(this.props.userData.AnnualRevenue)
        //this.props.userData.Location = data.COUNTRY_NAME
        let idx = this.props.discovery.locations.findIndex(item => data.COUNTRY_NAME.toLowerCase() === item.toLowerCase())
        if (idx !== -1) {
            this.props.userData.Location = idx;
            this.setState({ selectedLocation: this.props.discovery.locations[idx] });
        } else {
            this.setState({ selectedLocation: this.props.discovery.locations[0] })
        }

        if (data.AVATAR_INDUSTRY) {
            const industryIdx = this.props.discovery.industries.findIndex(item => data.AVATAR_INDUSTRY.toLowerCase() === item.toLowerCase());
            if (industryIdx !== -1) {
                this.props.userData.Industry = industryIdx;
                //this.formDispatch(actions.change('discovery.Industry', this.props.content.industries[industryIdx]));
                this.setState({ selectedIndustry: this.props.discovery.industries[industryIdx] });
            } else {
                this.setState({ selectedIndustry: this.props.discovery.industries[0] });
            }
        }
    }

    closeCapIqPopUp() {
        this.setState({ openCapIq: !this.state.openCapIq })
    }

    listenScrollEvent() {
        if (this.state.actualScroll) {
            this.setState({ actualScroll: false })
            return
        }

        this.setState({ dummyScroll: true }, () => {
            const canvas = document.getElementsByClassName('react-grid-Canvas')[0]
            const dummy = document.getElementsByClassName('scroll-dummy')[0]
            const length = this.state.columnDefs.length * 120
            const dummyScrollRatio = length / document.documentElement.clientWidth
            canvas.scrollLeft = dummy.scrollLeft //* dummyScrollRatio
        })
    }

    getHeaderGroups(columnDefs) {
        let headerToColumn = {}
        columnDefs.forEach(item => {
            if (item.headerGroup in headerToColumn) {
                let columns = headerToColumn[item.headerGroup]
                columns.push({ name: item.name, width: item.width })
            } else {
                if (item.headerGroup === null) {
                    headerToColumn[item.name] = { name: null, width: item.width }
                } else {
                    headerToColumn[item.headerGroup] = [{ name: item.name, width: item.width }]
                }
            }
        })

        return headerToColumn
    }

    // reload grid data when user defined excel upload
    reloadGrid() {
        this.loadData()
    }

    componentWillUnmount()
    {
        
    }
    //excel export

    loadVendorData() {
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
        let user = this.props.user.user.Email;
        // this.props.defineScopeLoading(true)
        this.props.getVendorFiles(dmcId, vsId, user)
            .then((response) => {
                if (response.data.Result.length > 0) {
                    this.setState({ vufData: response.data.Result })
                } else {
                    // this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: 'No result found. Please try reimporting the file.', isError: 'Yes', isLoading: false })
                }

            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    // this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    // this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                }
            })
    }

    insertReportAudit(logType,fileName) {
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
        let user = this.props.user.user.Email;
        this.props.insertReportLogDetails(dmcId, vsId, user,logType,fileName)
            .then((response) => {
                this.setState({ isLoading: false });
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                }
            })
    }

    exportCsvForVUF(rowData) {

        var keys = Object.keys(rowData[0]).filter((cols) => {
            return cols
        })

        var headers = Object.keys(rowData[0]).map((cols) => {
            return cols.includes(',') ? cols.replace(",", "?") : cols
            //return cols.label
        })

        const options = {
            filename: 'Vendor Details',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            keys: keys,
            headers: headers
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(rowData);
        this.setState({ exportFlag: true })
        this.insertReportAudit('Download - VUF','Vendor Details.csv');
    }

    render() {
        const paginatedRows = this.state.filteredRows.slice(this.state.paginatedRowFrom, this.state.paginatedRowTo);
        paginatedRows.splice(0, 0, { superHeader: this.state.headerToColumn })
        paginatedRows.splice(1, 0, this.state.filteredSummationArray)
        const validationDiv = this.getValidationDiv();
        var columnDefs = this.setColumnHelpTextAndHardRefresh(paginatedRows, this.state.columnDefs)
        var filter = this.state.filters ? Object.entries(cloneDeep(this.state.filters)) : []
        var self = this;
        return (
            <div className='medium-12 detailed-file-grid'>
                <div className="grid-x grid-padding-x">
                    <div className="medium-12">
                        {(this.state.isLoading) ?
                            <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', paddingTop: '5px' }} src={path + `images/ajax-loader.gif`} alt="" />
                            : null
                        }
                        {(this.state.isError === 'Yes') ?
                            <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                            : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                        }
                    </div>
                </div>
                <div className='grid-x grid-padding-x'>
                    <div className='medium-11'>
                        
                        <div className="tab-title">
                            <Popup className="report-tooltip"
                                trigger={<img style={{ marginLeft: '5px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                position="bottom left"
                                on="hover"
                            >
                                <div>
                                    {this.props.content.subHeader}
                                </div>
                            </Popup>
                            {this.props.content.subTitle}{<VideoHelp screen={{ module: 'Spend', screenName: 'Spend File Details' }} />}   </div>

                        {/* <div className="tab-description width-auto">{this.props.content.subHeader}</div> */}
                        
                    </div>
                    {/* <div className="medium-5">
                        {validationDiv} 
                    </div> */}
                    <div style={{ paddingRight: '27px', fontSize: '12px' }} className="medium-1 legend2 defineScope text-align-right">
                        <span className="key-dot outScope"></span>{this.props.content.editableColumns}
                    </div>
                    <div className="medium-12">
                        <div className="grid-x hideBorder">
                            <div className="medium-1 large-1">
                                <PopupE className="override-large-popup"
                                    position="right center"
                                    open={this.state.open}
                                    onClose={this.outsidePopupClick}
                                    closeOnDocumentClick
                                >
                                    <div>
                                        <IndustryOverridePopUp
                                            close={this.closePopUp}
                                        />
                                    </div>
                                    <div className="popupOkBtn">
                                        <Button onClick={this.industryOverride} float type="submit" content={this.props.ui.ok} />
                                        <Button onClick={this.clearIndustryOverride} type="submit" content={this.props.content.clearOverridenIndustry} />
                                    </div>
                                </PopupE>
                            </div>
                            <div className="medium-1 large-1">
                                <PopupE className="override-large-popup"
                                    position="right center"
                                    open={this.state.openUserCommentsPopUp}
                                    onClose={this.outsideUserCommentsPopupClick}
                                    closeOnDocumentClick
                                >
                                    <div>
                                        <UserCommentsPopUp
                                            close={this.closeUserCommentsPopUp}
                                            addUserComments={this.addUserComments}
                                            clearUserComments={this.clearUserComments}
                                            userCommentInsideCell={this.state.userCommentInsideCell}
                                            DMC_ID={this.props.spendDetails.currentActiveRequest.DMC_ID}
                                            VS_ID={this.props.spendDetails.currentActiveRequest.VS_ID}
                                            SKEY={this.state.selectedSupplier}
                                        />
                                    </div>
                                </PopupE>
                            </div>
                            <div className="medium-1 large-1">
                                <PopupE className="override-large-popup2"
                                    position="right center"
                                    open={this.state.openEnablementInsightPopUp}
                                    onClose={this.outsideEnablementInsightPopupClick}
                                    closeOnDocumentClick
                                >
                                    <div>
                                        <EnablementWavePopUp
                                            enablementWaveInputValue={this.state.enablementWaveInputValue}
                                            close={this.closeEnablementInsightPopUp}
                                        />
                                    </div>
                                    <div className="popupOkBtn">
                                        <Button onClick={this.enablementWaveInput} float type="submit" content={this.props.ui.ok} />
                                        <Button onClick={this.clearEnablementWaveOverride} type="submit" content={this.props.ui.clearOverride} />
                                    </div>
                                </PopupE>
                            </div>
                            {this.state.currentScopeSource === "Industry" && this.state.industryDesc !== "Unclassified" && (this.state.currentDMCIndstryId !== null || this.state.currentDMCIndstryId >= 1000) ?
                                <div className="medium-1 large-1">
                                    <PopupE className="override-large-popup"
                                        position="right center"
                                        open={this.state.openCapIq}
                                        onClose={this.outsideCapIqIdPopupClick}
                                        closeOnDocumentClick
                                    >
                                        <div>
                                            <CapIQSearch
                                                contentData={this.props.discovery}
                                                capIqRadioButtonClicked={this.capIqRadioButtonClicked}
                                                capIqDataCleared={this.capIqDataCleared}
                                                close={this.closeCapIqPopUp}
                                                capIqIdOverride={this.capIqIdOverride}
                                                doubleCapIqId={this.state.currentCapIqId}
                                            />
                                        </div>
                                    </PopupE>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>

                <div className='grid-x grid-padding-x'>
                    <div className='medium-3'>
                        {/* <div style = {{float: 'left',	width: '20%'}}> */}
                            <div className="font-bold underline">{this.props.content.currentFilterSelection}</div>
                            <div className="defineScope">
                                {filter.length === 0 ? 'No filter currently selected' :
                                    filter.map((item) => {
                                        return (
                                            (item.length === 0 || item[1].filterTerm.length === 0) ? '' :
                                                <div className="grid-x"><span className="font-small font-bold float-left pr10">{item[1].column.name}: </span><div className="medium-9">{this.expandFilter(item[1])}</div></div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {/* <div style = {{float: 'left',	width: '40%'}}> */}
                        <div className='medium-3'>
                            {this.state.rowData.length === 0 ? '' :
                                <div><span className="font-bold underline">{this.props.content.currentFilterRows}</span> {formatThousands(this.state.filteredRows.length)}/{formatThousands(this.state.rowData.length)} {this.props.content.totalText}</div>}

                        </div>
                        {/* <div style = {{float: 'left',	width: '40%'}}> */}
                        <div className='medium-6'>
                            <div className="grid-x medium-12 pr15">
                                <div className="medium-12">
                                {validationDiv}
                                    {/* <div className="dropDownHeader font-bold underline" style={{ width: '20%', float: 'left' }}>{"Sort By :"} </div>

                                    <div className="control-wrapper" style={{ width: '80%', float: 'right' }}>
                                        <SelectE
                                            className="border-with-DD"
                                            autocomplete='true'
                                            //placeholder={this.props.content.notConfirmed}
                                            value={this.state.sortByOption.filter(
                                                option => option.value === this.state.selectedSortBy
                                            )}
                                            noOptionsMessage={() => null}
                                            onChange={value => this.handleSortByChange(value)}
                                            options={this.state.sortByOption}
                                            isSearchable={false}
                                            styles={dropdownColourStyles}
                                            isDisabled={this.state.isLoading}
                                        />
                                    </div> */}



                                </div>
                            </div>
                        </div>
                </div>
                <div className="grid-x grid-padding-x">
                    <div class='medium-12 clearfix'>
                        <div className="float-left pt10">
                            <Select
                                className="border-with-DD page-number-dd dropDownContent"
                                placeholder="Page Count"
                                value={this.state.pageCountOption.filter(option => option.value === this.state.perPage)}
                                noOptionsMessage={() => null}
                                onChange={(value) => this.handlePageCountChange(value.value)}
                                options={this.state.pageCountOption}
                                isSearchable={false}
                            />
                        </div>
                        <div className="float-left pt10">
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                                pageClassName={'page-li'}
                                previousClassName={'previousClassName'}
                                nextClassName={'nextClassName'}
                                pageLinkClassName={'pageLinkClassName'}
                                activeLinkClassName={'activeLinkClassName'}
                            />
                        </div>
                         <div className="float-left pt10" style={{width: '500px', paddingLeft: '80px',marginLeft:'500px'}} >
                                <div className="dropDownHeader font-bold" style={{ width: '20%', float: 'left',paddingTop:'8px' }}>{"Sort By :"} </div>
                                 
                                <div  title="1)Buyer Value               : Sort by Value to the Buyer (Intelligent Enablement Match, Spend & Document Score)
2)Enablement Strategy Value : Sort by Value to Buyer including Enablement Strategy Wizard (Intelligent Combined Priority Score)
3)Enablement Wave           : Sort by Enablement Wave (Enablement Wave)
4)Original Order            : Sort by Spend File Order (Ref. #)" className="control-wrapper" style={{ width: '80%', float: 'right' }}>
                                    <SelectE
                                        className="border-with-DD"
                                        autocomplete='true'
                                        //placeholder={this.props.content.notConfirmed}
                                        value={this.state.sortByOption.filter(
                                            option => option.value === this.state.selectedSortBy
                                        )}
                                        noOptionsMessage={() => null}
                                        onChange={value => this.handleSortByChange(value)}
                                        options={this.state.sortByOption}
                                        isSearchable={false}
                                        styles={dropdownColourStyles}
                                        isDisabled={this.state.isLoading}
                                    />
                                </div>

                            </div>
                        <div className="float-right width435 pt10">
                            <div className="float-right pr70 pt10" >
                                <button onClick={() => this.showModal('ColumnFields')} title={this.props.content.columnSelect} >{<img className="hand-cursor" width="20" src="images/column_active.svg" />}</button>
                                <Modal className="column-field-modal"
                                    ref={'ColumnFields'} backdropStyle={backdropStyle}
                                    keyboard={() => this.callback()}
                                    modalStyle={columnFieldModalStyle}
                                    contentStyle={suggestedValueContentStyle}
                                >
                                    <ColumnFields
                                        columnOptions={this.state.columns}
                                        onColumnSelection={this.onColumnSelection}
                                        closePopup={() => this.closeColumnFieldPopup()} />
                                    <button onClick={() => this.closeColumnFieldPopup()} className='close' />
                                </Modal>
                            </div>

                            <div className="float-right pr25 pt10" >
                                <button onClick={() => this.showModal('UploadUserDefinedFields')} title={this.props.content.uploadExcel} >{<img className="hand-cursor" width="20" src="images/export_active.svg" />}</button>
                                <Modal className="column-field-modal"
                                    ref={'UploadUserDefinedFields'} backdropStyle={backdropStyle}
                                    keyboard={() => this.callback()}
                                    modalStyle={columnFieldModalStyle}
                                    contentStyle={suggestedValueContentStyle}
                                >
                                    <UploadUserDefinedFields
                                        columnData={this.state.finalColumn}
                                        rowData={this.state.rowData}
                                        reloadGrid={() => this.reloadGrid()}
                                        closePopup={() => this.closeUploadFieldPopup()} />
                                    <button onClick={() => this.closeUploadFieldPopup()} className='close' />
                                </Modal>
                            </div>

                            <div>
                                <Modal
                                    ref="alert"
                                    modalStyle={alertModalStyle}
                                    contentStyle={changeModalContentStyle}
                                    backdropStyle={changeBackdropStyle}
                                    // className="exportModal"
                                >
                                    <div className="grid-x change-popup">
                                        <div className="medium-12">
                                            <button onClick={() => this.hideModal("alert")} className="close" />
                                        </div>
                                        <div className="medium-12 pt10" style={{ marginBottom : '10px'}}>
                                            { this.props.spendDetails.currentActiveRequest.Number_Of_Records < downloadThreshold ?
                                              this.props.content.exportAlertMessage
                                            : this.props.content.exportAlertMessageAboveThreshold
                                            }
                                        </div>
                                        {/* <div className="medium-12 pt20">
                                            <div className='grid-x'> */}
                                            { this.props.spendDetails.currentActiveRequest.Number_Of_Records < downloadThreshold ?
                                               <div className="medium-12" style={{ marginBottom : '5px'}}>
                                                        <div className='grid-x'>
                                                            <div className="medium-12">

                                                                <div className='grid-x'>
                                                                    <div className="medium-1" ><RadioButton style={{ paddingLeft: '5px', marginTop: '-2px' }} className='radioButton'
                                                                        checked={this.state.exportAction === 'customerViewColumns'}
                                                                        id={'customerViewColumns'}
                                                                        value='customerViewColumns'
                                                                        onClick={value => this.radioButtonChecked(value)}
                                                                        color="default"
                                                                    ></RadioButton></div>
                                                                    <div className="medium-11">{this.props.content.customerViewColumns}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null }
                                                    <div className="medium-12" style={{ marginBottom : '5px'}}>
                                                        <div className='grid-x'>
                                                            <div className="medium-12">

                                                                <div className='grid-x'>
                                                                    <div className="medium-1" ><RadioButton style={{ paddingLeft: '5px', marginTop: '-2px' }} className='radioButton'
                                                                        checked={this.state.exportAction === 'customerViewColumnsCSV'}
                                                                        id={'customerViewColumnsCSV'}
                                                                        value='customerViewColumnsCSV'
                                                                        onClick={value => this.radioButtonChecked(value)}
                                                                        color="default"
                                                                    ></RadioButton></div>
                                                                    <div className="medium-11">Customer View CSV</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { this.props.spendDetails.currentActiveRequest.Number_Of_Records < downloadThreshold ?                 
                                                <div className="medium-12" style={{ marginBottom : '5px'}}>
                                                    <div className='grid-x'>
                                                        <div className="medium-12">
                                                            <div className='grid-x'>
                                                                <div className="medium-1" ><RadioButton style={{ paddingLeft: '5px', marginTop: '-2px' }} className='radioButton'
                                                                    checked={this.state.exportAction === 'enablementInsightColumns'}
                                                                    id={'enablementInsightColumns'}
                                                                    value='enablementInsightColumns'
                                                                    onClick={value => this.radioButtonChecked(value)}
                                                                    color="default"
                                                                ></RadioButton></div>
                                                                <div className="medium-11">{this.props.content.enablementInsightColumns}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null }
                                            {/* </div>
                                        </div> */}
                                        {/* <div className="medium-12 pt20">
                                            <div className='grid-x'> */}
                                            { this.props.spendDetails.currentActiveRequest.Number_Of_Records < downloadThreshold ?
                                                <div className="medium-12" style={{ marginBottom : '5px'}}>
                                                    <div className='grid-x'>
                                                        <div className="medium-12">
                                                        {(self.props.user.userprofile.Roles[0].indexOf("Standard Internal User") > -1
                                    || self.props.user.userprofile.Roles[0].indexOf("Admin") > -1)?
                                                            <div className='grid-x'>
                                                                <div className="medium-1"><RadioButton style={{ paddingLeft: '5px', marginTop: '-2px' }} className='radioButton'
                                                                    checked={this.state.exportAction === 'nonSensitiveColumns'}
                                                                    id={'nonSensitiveColumns'}
                                                                    value='nonSensitiveColumns'
                                                                    onClick={value => this.radioButtonChecked(value)}
                                                                    color="default"
                                                                ></RadioButton></div>
                                                                <div className="medium-11">{this.props.content.nonSensetiveColumns}</div>
                                                            </div>
                                                            : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                : null }
                                                { this.props.spendDetails.currentActiveRequest.Number_Of_Records < downloadThreshold ?
                                                <div className="medium-12" style={{ marginBottom : '5px'}}>
                                                    <div className='grid-x'>
                                                        <div className="medium-12">
                                                        {(self.props.user.userprofile.Roles[0].indexOf("Standard Internal User") > -1
                                    || self.props.user.userprofile.Roles[0].indexOf("Admin") > -1)?
                                                            <div className='grid-x'>
                                                                <div className="medium-1" ><RadioButton style={{ paddingLeft: '5px', marginTop: '-2px' }} className='radioButton'
                                                                    checked={this.state.exportAction === 'currentViewColumns'}
                                                                    id={'currentViewColumns'}
                                                                    value='currentViewColumns'
                                                                    onClick={value => this.radioButtonChecked(value)}
                                                                    color="default"
                                                                ></RadioButton></div>
                                                                <div className="medium-11">{this.props.content.currentViewColumns}</div>
                                                            </div>
                                                            : null}
                                                        </div>
                                                    </div>
                                                </div>    
                                                : null }                                         
                                                {this.props.spendDetails.currentActiveRequest.Number_Of_Records < downloadThreshold && self.state.exportMC_Cloumns ?
                                                    <div className="medium-12" style={{ marginBottom : '5px'}}>
                                                        <div className='grid-x'>
                                                            <div className="medium-12">
                                                            {(self.props.user.userprofile.Roles[0].indexOf("Standard Internal User") > -1
                                    || self.props.user.userprofile.Roles[0].indexOf("Admin") > -1)?
                                                                <div className='grid-x'>
                                                                    <div className="medium-1" ><RadioButton style={{ paddingLeft: '5px', marginTop: '-2px' }} className='radioButton'
                                                                        checked={this.state.exportAction === 'matchAndClassificationColumns'}
                                                                        id={'matchAndClassificationColumns'}
                                                                        value='matchAndClassificationColumns'
                                                                        onClick={value => this.radioButtonChecked(value)}
                                                                        color="default"
                                                                    ></RadioButton></div>
                                                                    <div className="medium-11">{this.props.content.matchAndClassificationColumns}</div>
                                                                </div>
                                                                : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                                {self.state.exportMC_Cloumns ?
                                                    <div className="medium-12" style={{ marginBottom : '5px'}}>
                                                        <div className='grid-x'>
                                                            <div className="medium-12">
                                                            {(self.props.user.userprofile.Roles[0].indexOf("Standard Internal User") > -1
                                    || self.props.user.userprofile.Roles[0].indexOf("Admin") > -1)?
                                                                <div className='grid-x'>
                                                                    <div className="medium-1" ><RadioButton style={{ paddingLeft: '5px', marginTop: '-2px' }} className='radioButton'
                                                                        checked={this.state.exportAction === 'matchAndClassificationColumnsCSV'}
                                                                        id={'matchAndClassificationColumnsCSV'}
                                                                        value='matchAndClassificationColumnsCSV'
                                                                        onClick={value => this.radioButtonChecked(value)}
                                                                        color="default"
                                                                    ></RadioButton></div>
                                                                    <div className="medium-11">M&C Detail CSV (Internal Only)</div>
                                                                </div>
                                                                : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                            {/* </div>
                                        </div> */}
                                        {/* <div className="medium-12 pt20">
                                            <div className='grid-x'>                                                 */}
                                                    
                                                {/* <div className={self.state.exportMC_Cloumns ? "medium-6 pt20" : "medium-6"}> */}
                                                {this.props.spendDetails.currentActiveRequest.Number_Of_Records < downloadThreshold ?
                                                <div className="medium-12" style={{ marginBottom : '5px'}}>
                                                    <div className='grid-x'>
                                                        <div className="medium-12">
                                                            {(self.props.user.userprofile.Roles[0].indexOf("Export All_Spend Details") > -1
                                                            || self.props.user.userprofile.Roles[0].indexOf("Admin") > -1) ?
                                                                <div className='grid-x'>
                                                                    <div className="medium-1" ><RadioButton style={{ paddingLeft: '5px', marginTop: '-2px' }} className='radioButton'
                                                                        checked={this.state.exportAction === 'allColumns'}
                                                                        id={'allColumns'}
                                                                        value='allColumns'
                                                                        onClick={value => this.radioButtonChecked(value)}
                                                                        color="default"
                                                                    ></RadioButton></div>
                                                                    <div className="medium-11">{this.props.content.allColumns}</div>
                                                                </div> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                : null }
                                            {/* </div>
                                        </div> */}
                                        <div style={{ paddingRight: '15px' }} className="medium-12 popupOkBtn pt10">
                                            {(this.state.isLoading) ?
                                                <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', paddingTop: '5px' }} src={path + `images/ajax-loader.gif`} alt="" />
                                                : null
                                            }
                                            
                                            <div className="float-right">
                                                <div className="float-left"><Button
                                                    content={this.props.content.export}
                                                    disabled={this.state.isLoading} 
                                                    onClick={this.handleClick}
                                                    color /></div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                            {/* onClick={self.props.user.userprofile.Roles[0].indexOf("Export All_Spend Details") > -1 ? () => this.showModal('alert') : this.exportCsv} */}

                            <button className={classNames('float-right', 'pt10', 'pr25',
                                {
                                    'hand-cursor': this.state.pendingRecords.length === 0,
                                    'gray-scale': this.state.pendingRecords.length !== 0
                                })} onClick={() => this.openDownloadPopup()}
                                disabled={this.state.pendingRecords.length !== 0} title={this.props.content.export}>
                                {<img className="rotateimg180" width="20" src="images/export_active.svg" />}
                            </button>

                            {self.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ?                   
                            <div className={classNames('float-right', 'pt10', 'pr20', 'hand-cursor')} >
                                <button onClick={() => this.openNdlPopUp()}
                                    title={'Download NDL File'}>
                                    {<label className='vufClass'>NDL</label>}
                                </button>
                                <Modal
                                    ref="ndlAlert"
                                    modalStyle={alertModalStyle}
                                    contentStyle={changeModalContentStyle}
                                    backdropStyle={changeBackdropStyle}
                                >
                                    <div className="grid-x change-popup">
                                        <div className="medium-12">
                                            <button onClick={() => this.hideModal("ndlAlert")} className="close" />
                                        </div>
                                        <div className="medium-12 pt10" style={{ marginBottom: '10px' }}>
                                            {this.props.content.exportAlertMessage}
                                            {/* {this.props.spendDetails.currentActiveRequest.Number_Of_Records < downloadThreshold ?
                                                this.props.content.exportAlertMessage
                                                : this.props.content.exportAlertMessageAboveThreshold
                                            } */}
                                        </div>


                                        <div className="medium-12" style={{ marginBottom: '5px' }}>
                                            <div className='grid-x'>
                                                <div className="medium-12">

                                                    <div className='grid-x'>
                                                        <div className="medium-1" ><RadioButton style={{ paddingLeft: '5px', marginTop: '-2px' }} className='radioButton'
                                                            checked={this.state.exportAction === 'matchedOutputDemo'}
                                                            id={'matchedOutputDemo'}
                                                            value='matchedOutputDemo'
                                                            onClick={value => this.radioButtonChecked(value)}
                                                            color="default"
                                                        ></RadioButton></div>
                                                        <div className="medium-11">SE Match Output</div>
                                                    </div>
                                                    <div className='grid-x'>
                                                        <div className="medium-1" ><RadioButton style={{ paddingLeft: '5px', marginTop: '-2px' }} className='radioButton'
                                                            checked={this.state.exportAction === 'custFacingMatchedOutputDemo'}
                                                            id={'custFacingMatchedOutputDemo'}
                                                            value='custFacingMatchedOutputDemo'
                                                            onClick={value => this.radioButtonChecked(value)}
                                                            color="default"
                                                        ></RadioButton></div>
                                                        <div className="medium-11">Customer Facing SE Match Output</div>
                                                    </div>
                                                    <div className='grid-x'>
                                                        <div className="medium-1" ><RadioButton style={{ paddingLeft: '5px', marginTop: '-2px' }} className='radioButton'
                                                            checked={this.state.exportAction === 'matchedOutputDemoEnhanced'}
                                                            id={'matchedOutputDemoEnhanced'}
                                                            value='matchedOutputDemoEnhanced'
                                                            onClick={value => this.radioButtonChecked(value)}
                                                            color="default"
                                                        ></RadioButton></div>
                                                        <div className="medium-11">SE Match Output Enhanced</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ paddingRight: '15px' }} className="medium-12 popupOkBtn pt10">
                                            {(this.state.isLoading) ?
                                                <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', paddingTop: '5px' }} src={path + `images/ajax-loader.gif`} alt="" />
                                                : null
                                            }
                                            
                                            <div className="float-right">
                                                <div className="float-left"><Button
                                                    content={this.props.content.export}
                                                    disabled={this.state.isLoading} 
                                                    onClick={this.ndlDownload}
                                                    color /></div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                            : null}

                            <div className={classNames('float-right', 'pt10', 'pr20', 'hand-cursor')} >
                                <button onClick={() => this.showModal('vufModal')}
                                    title={'Download Vendor File'}>
                                    {<label className='vufClass'>VUF</label>}
                                </button>
                                <Modal className="column-field-modal vufModal"
                                    ref={'vufModal'}
                                    keyboard={() => this.callback()}
                                    modalStyle={changeModalStyle}
                                    contentStyle={changeModalContentStyle}
                                >
                                    <VendorUploadFilesModalContainer
                                        rowData={this.state.vufData}
                                        waves={this.state.vufWaves}
                                        exportCsvForVUF={this.exportCsvForVUF.bind(this)}
                                        closePopup={() => this.hideModal('vufModal')}
                                        content={this.props.content.export} />
                                    <button onClick={() => this.hideModal('vufModal')} className='close' />
                                </Modal>
                            </div>

                            <button className={classNames('float-right', 'pt10', 'pr20',
                                {
                                    'hand-cursor': this.state.isSaveInProgress ? false : !(this.hasRecordErrors() || this.state.pendingRecords.length === 0),
                                    'gray-scale': this.state.isSaveInProgress ? true : (this.hasRecordErrors() || this.state.pendingRecords.length === 0)
                                })}
                                onClick={this.onSave} disabled={this.state.isSaveInProgress ? true : this.hasRecordErrors() || this.state.pendingRecords.length === 0} title={this.props.ui.save} type="submit" float >
                                <img width="20" src="images/save_active.svg" />
                            </button>                           
                        </div>
                    </div> <div className="grid-x grid-padding-x">
                        <div
                            id="myGrid"
                            className='medium-12 react-data-grid bottom-space'>
                            <div class="scroll-dummy" onScroll={this.listenScrollEvent.bind(this)}>
                                {/* <div class="scroll-dummy-child"></div> */}
                            </div>
                            <ReactDataGrid
                                ref={(dataGrid) => { this.myOpenGrid = dataGrid; }}
                                columns={columnDefs}
                                minWidth={'100%'}
                                rowsCount={paginatedRows.length}
                                minHeight={500}
                                onGridRowsUpdated={this.onGridRowsUpdated}
                                enableCellSelect={true}
                                onGridSort={this.sortRows}
                                rowGetter={i => paginatedRows[i]}
                                toolbar={<Toolbar enableFilter onToggleFilter={() => this.handleFilterChange(null)} numberOfRows={this.state.rowData.length} filterRowsButtonText={<span onClick={this.filterChange}>{<img className="hand-cursor" title={this.props.content.filter} width="20" src="images/filter_active.svg" />}</span>} />}
                                onAddFilter={filter => this.handleFilterChange(filter)}
                                onClearFilters={() => this.handleFilterChange(null)}
                                onFilterChange={filter => this.handleFilterChange(filter)}
                                getValidFilterValues={this.getValidFilterValues}
                                RowsContainer={ContextMenuTrigger}
                                rowRenderer={this.RowRenderer}
                                onCheckCellIsEditable={this.isCellEditable}
                                onColumnResize={(idx, width) => this.columnResize(`${idx}`, `${width}`)}
                                headerRowHeight={70}
                            />
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        ui: state.content.ui,
        spendDetails: state.spendDetails,
        user: state.default,
        content: state.content.spendAnalysis.detailedFile,
        validation: state.content.validation,
        userData: state.valueStory,
        filter: state.detailedFileFilters.filter,
        filterText: state.detailedFileFilterText,
        overridedIndustryDetails: state.spendDetails.overridedIndustryDetails,
        enablementWaveInputDetails: state.spendDetails.enablementWaveInputDetails,
        spendDetails: state.spendDetails,
        discovery: state.content.discovery.form,
        overridedCapIqIdDetails: state.spendDetails.overridedCapIqIdDetails,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDetailedFileSuccess: message => dispatch(getDetailedFileSuccess(message)),
        getDetailedFileFailure: error => dispatch(getDetailedFileFailure(error)),
        getDetailedFileLoading: bool => dispatch(getDetailedFileLoading(bool)),
        getDetailedFileSpendData: (DMCId, VSId, userEmail, requestFileRecordCount, roundTripNumber) => dispatch(getDetailedFileSpendData(DMCId, VSId, userEmail, requestFileRecordCount, roundTripNumber)),
        getSpendColumnsConfiguration: () => dispatch(getSpendColumnsConfiguration()),
        getNdlColumnsConfiguration: () => dispatch(getNdlColumnsConfiguration()),
        getNdlReportData: (detail) => dispatch(getNdlReportData(detail)),
        insertDetailedFileRecords: (records) => dispatch(insertDetailedFileRecords(records)),
        detailedFileFilters: filter => dispatch(detailedFileFilters(filter)),
        detailedFileFilterText: filter => dispatch(detailedFileFilterText(filter)),
        selectedIndustryDetails: message => dispatch(selectedIndustryDetails(message)),
        selectedEnablementWaveDetails: message => dispatch(selectedEnablementWaveDetails(message)),
        pendingChanges: changes => dispatch(pendingChanges(changes)),
        defineScopeLoading: bool => dispatch(defineScopeLoading(bool)),
        setResultSummaryDetails: (data) => dispatch(setResultSummaryDetails(data)),
        setTopDetails: (data) => dispatch(setTopDetails(data)),
        getSelectedColumn: (DMCId, VSId, userId) => dispatch(getSelectedColumn(DMCId, VSId, userId)),
        addSelectedColumn: (DMCId, VSId, userId, ColumnsOrFavId) => dispatch(addSelectedColumn(DMCId, VSId, userId, ColumnsOrFavId)),
        removeColumnsForRequest: (UserId, VsId, DmcId) => dispatch(removeColumnsForRequest(UserId, VsId, DmcId)),
        selectedCapIqIdDetails: message => dispatch(selectedCapIqIdDetails(message)),
        getVendorFiles: (DMCId, VSId, userEmail) => dispatch(getVendorFiles(DMCId, VSId, userEmail)),
        insertReportLogDetails:(DMC_ID, VS_ID, USER_ID, LOG_TYPE, FILE_NAME) => dispatch(insertReportLogDetails(DMC_ID, VS_ID, USER_ID, LOG_TYPE, FILE_NAME))  
        //getReportLogDetails: () => dispatch(getReportLogDetails()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpendFilesContainer)
