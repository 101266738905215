import React, { Component } from 'react'
import { connect } from 'react-redux'
import RampScheduleFactor from "./RampScheduleFactor"
import GoLiveVsKickOffWeek from "./GoLiveVsKickOffWeek"

class RampSchedule extends Component {
    constructor(props) {
        super(props)
        this.state = {
            readOnly: props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? false : true
        }
    }


    render() {
        return (
            <div className="grid-x" >
                <RampScheduleFactor readOnly={this.state.readOnly}></RampScheduleFactor>
                <GoLiveVsKickOffWeek readOnly={this.state.readOnly}></GoLiveVsKickOffWeek>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.default,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RampSchedule); 

