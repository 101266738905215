import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Actions
import {
	signInUserSuccess_SSO,
	signInSSO,
	meFromToken,
	resetError
} from 'actions/userActions'

// Components
import BigButton from '../UI/BigButton'

// Utils
import { BreakLine, getParameterByName,getCookie } from '../../utils/Utils'

// Settings
const SSOUrl = '/saml/request.aspx'

class HomeContainer extends Component {

	constructor(props) {
		super(props)

		this.state = {
			visible: false
		}

		this.redirect = this.redirect.bind(this)
		this.SSO = this.SSO.bind(this)
	}

	SSO() {
		let ssoArray = [];
		if (getCookie('MyTestCookie') !== '' && getCookie('MyTestCookie') !== 'undefined') {
			console.log('MyTestCookie', getCookie('MyTestCookie'));
			ssoArray = getCookie('MyTestCookie').split('&');
		}

		if (ssoArray.length == 0) {
			var currentUrl = window.location.href;		
			var originalUrl = currentUrl.split('?')[0];
		    window.location.href = originalUrl;
		}
		else {

			// const name = getParameterByName('Name'),
			// 	email = getParameterByName('Email'),
			// 	fname = getParameterByName('Firstname'),
			// 	lname = getParameterByName('Lastname')

			const name = ssoArray[0],
				email = ssoArray[1],
				fname = ssoArray[2],
				lname = ssoArray[3]

			const values = {
				Name: name,
				Email: email,
				Firstname: fname,
				Lastname: lname
			}

			this.props.signInSSO(values)
				.then((result) => {
					this.props.signInUserSuccess_SSO(result)

					// Store JWT Token
					localStorage.setItem('jwtToken', result.data.Result.Token)
					localStorage.setItem('role', result.data.Result.Role)
					localStorage.setItem('loggedinUser', result.data.Result.Email)
					localStorage.setItem('id', result.data.Result.Id)
					localStorage.setItem('enablementInsightFlag', false)
					// Redirect to Start page
					this.redirect()

				}).catch((error) => {
					//this.props.signInUserFailure(error.response.data.Message)
				})
		}
	}

	redirect() {
		this.props.history.push(`/start`)
	}

	componentWillMount() {
		// Check for redirect from SSO login
		getParameterByName('Name') ? this.SSO() : this.setState({ visible: true })

		this.props.resetError()
	}

	render() {
		return (
			<div style={{width:'100%'}}>
				<div className={!this.state.visible ? 'hidden' : 'grid-container pt20'}>
				<img src="/images/avatar_logo.png" width="150" />
					{/* <div className="page-title">{BreakLine(this.props.content.title)}</div> */}
					<p className="home-description">{this.props.content.description}</p>
					<a className="no-underline inline-block" href={SSOUrl}>
						<BigButton content={this.props.content.SSOLoginButton} />
					</a>
					<Link className="no-underline inline-block"
						to={{ 'pathname': '/login', state: { 'loginType': 'partners' } }}
					>
						<BigButton content={this.props.content.partnersLoginButton} />
					</Link>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		content: state.content.home,
		user: state.default
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		resetError: () => dispatch(resetError()),
		signInSSO: (formValues) => { return dispatch(signInSSO(formValues)) },
		signInUserSuccess_SSO: (user) => dispatch(signInUserSuccess_SSO(user)),
		meFromToken: (tokenFromStorage, username) => dispatch(meFromToken(tokenFromStorage, username))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)