import React from 'react'
import{connect} from 'react-redux'
import EnablementInsightTabTable from './EnablementInsightTabTable'
import EnablementSupplierDetailsPopup from './EnablementSupplierDetailsPopup'
import ExpandableTable from './ExpandableTable'
import { path } from 'Constants'

// Settings
import { changeModalBorderStyle, drilldownModalBorderContentStyle, changeModalBorderRectStyle } from 'Constants'
import Modal from 'reboron/OutlineModal'

import {
    enablementInsightSuccess,
    enablementInsightFailure,
    enablementInsightLoading,    
    resetError,
    getEnablementDetails,
    getEnablementFiltersDetails
} from 'actions/spendActions'

class EnablementRecommendations extends React.Component{
    constructor(props)
    {
        super(props);
        this.state={
          enablementTypesJson: [],           
          grandTotal: [],
          isLoading: true,
          responseMsg: '',
          inputParam: {
            DMC_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.DMC_ID : 0
            , VS_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.VS_ID : 0,
            User_Email: this.props.spendDetails.currentActiveRequest !== undefined &&
                        this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester,
            dedupStats: 'Unique',
            enabledCustomer: 'all',
            transactingCustomer: 'all',
            buyerRegion: 'all',
            businessUnit: 'all',
            erpSystem: 'all',
            l4IndustryName: 'all',
            erpCode: 'all',
            erpDesc: 'all',
            sicCode: 'all',
            sicDesc: 'all',
           
            goods: 'all',
            invoiceCountry: 'all',
            legalInvoice: 'all',
            aribaNwEligible: 'all',
            column1Name: 'SAP_ARIBA_BUY_ENABLE_MTHD_RECMD',
            column1Value: '',
            tableName: '',
            valuesUpdated: false,
            metricsType: 'Invoice Spend',
            directSpend: 'all'
                 
        }, 
    }
        this.showEnablementSupplierDetailsModal = this.showEnablementSupplierDetailsModal.bind(this)
        this.setVisibility = this.setVisibility.bind(this)
        this.outsidePopupClick = this.outsidePopupClick.bind(this)
        this.onSave = this.onSave.bind(this)
        this.reloadEnablementWave= this.reloadEnablementWave.bind(this)
    }

    componentDidMount(){       
        //this.convertResponseToJson()
        this.GetEnablementDetails(this.state.inputParam)
    }

    reloadEnablementWave(){
        this.GetEnablementDetails(this.state.inputParam)
    }

    showEnablementSupplierDetailsModal(tableName, columnName, columnValue, columnValue2, columnValue3) {

        var inputParameter = this.state.inputParam
        inputParameter.tableName = tableName
        inputParameter.column1Name = columnName
        inputParameter.column1Value = columnValue

        inputParameter.column2Value = columnValue2

        inputParameter.column3Value = columnValue3
        
        this.setState({	openPopup: true, inputParam: inputParameter	})
    }

    onSave(value) {
        this.setState({valuesUpdated: value})
    }

    setVisibility(isVisible) {
        this.handlePoupClose()   
    }

    outsidePopupClick() {        
        this.handlePoupClose()
    }
    
    handlePoupClose() {
        this.setState({  open: false, openPopup: false }, () => {
            if(this.state.valuesUpdated) {
                this.setState({valuesUpdated: false}, this.GetEnablementDetails(this.state.inputParam))
            }
        })
    }

    showAdvanceFilter(){
        var advanceFilterDiv = document.getElementById('advanceFilter').style;        
        if (advanceFilterDiv.display === "none") {
            advanceFilterDiv.display = "block";
          } else {
            advanceFilterDiv.display = "none";
          }
    }

    GetEnablementDetails(inputParam) {
		
		this.props.getEnablementDetails(inputParam)
			.then((response) => {
				if (response !== undefined && response.status === 200 && response.data.Result !== undefined) {
                //	setTimeout(() => { this.props.defineScopeSuccess('') }, 2000)
                
                var enablementTypeData; var enablementTypeTotal;     
                var onBoardingworkstreamData;           

                var tables = response.data.Result.map(
                    function (table) {
                        if(table['SAP_ARIBA_BUY_ENABLE_MTHD_RECMD']!== undefined){
                            enablementTypeData = table['SAP_ARIBA_BUY_ENABLE_MTHD_RECMD']
                            enablementTypeTotal = table['GrandTotal']
                        }  
                        if(table['RECMD_ONBOARDING_WORKSTREAM_NAME']!== undefined){
                            onBoardingworkstreamData = table['RECMD_ONBOARDING_WORKSTREAM_NAME']
                        }                      
                    }
                )
  
                this.setState({enablementTypesJson: enablementTypeData, grandTotal: enablementTypeTotal,
                    enablementTypeData: this.convertResponseToJson(onBoardingworkstreamData, 'SAP_ARIBA_BUY_ENABLE_MTHD_RECMD',enablementTypeData),
                     isLoading: false });
              
                this.props.enablementInsightSuccess(true)
				}
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
					this.props.enablementInsightFailure('API service is down. Please contact administrator.')
				}
				else {
                    this.setState({ responseMsg: 'An error has occurred', isError: 'Yes' })
					this.props.enablementInsightFailure(this.props.content.error)
				}
			})
    }

    convertResponseToJson(enablementData, keyColumn, aggregatedData)
    {
        let data = []
        let total = []
        let waveToItem = []

        enablementData.forEach(item => {
            let datum = {
                GANTT_CHART_WORKSTREAM_NAME: item.GANTT_CHART_WORKSTREAM_NAME, 
                CNT_VENDOR_NAME: isNaN(item.CNT_VENDOR_NAME)? 0 : item.CNT_VENDOR_NAME,
                CNT_VENDOR_PER: isNaN(item.CNT_VENDOR_PER)? 0 :  Number.parseFloat(item.CNT_VENDOR_PER),
                CNT_UNIQ_VENDOR_AN_ID: isNaN(item.CNT_UNIQ_VENDOR_AN_ID)? 0 : Number.parseFloat(item.CNT_UNIQ_VENDOR_AN_ID),
                SUM_AGGR_SPEND: isNaN(item.SUM_AGGR_SPEND)? 0 : item.SUM_AGGR_SPEND,
                SUM_AGGR_SPEND_PER: isNaN(item.SUM_AGGR_SPEND_PER)? 0 : Number.parseFloat(item.SUM_AGGR_SPEND_PER),
               
                SUM_SAP_ARIBA_DOC_PROXY: isNaN(item.SUM_SAP_ARIBA_DOC_PROXY)? 0 : item.SUM_SAP_ARIBA_DOC_PROXY,
                SAP_ARIBA_DOC_PROXY_PER: isNaN(item.SAP_ARIBA_DOC_PROXY_PER)? 0 : Number.parseFloat(item.SAP_ARIBA_DOC_PROXY_PER)
            }

            datum[keyColumn] = item[keyColumn]

            data.push(datum)

            let datumCopy = {...datum}
            let datumCopy1 = {...datum}

            if(item[keyColumn] in total) {
                waveToItem[item[keyColumn]].push(datumCopy1)
                
                let partialTotal = total[item[keyColumn]]
                partialTotal.CNT_VENDOR_NAME = 0
                partialTotal.CNT_VENDOR_PER = 0
                partialTotal.CNT_UNIQ_VENDOR_AN_ID = 0
                partialTotal.SUM_AGGR_SPEND = 0
                partialTotal.SUM_AGGR_SPEND_PER = 0
                partialTotal.SUM_AGGR_PO_SPEND = 0
                partialTotal.SUM_AGGR_PO_SPEND_PER = 0
                partialTotal.SUM_SAP_ARIBA_DOC_PROXY = 0
                partialTotal.SAP_ARIBA_DOC_PROXY_PER = 0

            } else {
                
                total[item[keyColumn]] = datumCopy
                waveToItem[item[keyColumn]] = [datumCopy1]
            }
           
            // waveToItem[item.ENABLEMENT_WAVE].push(datumCopy1)
            // if(!item.ENABLEMENT_WAVE in total) {
            //         total[item.ENABLEMENT_WAVE] = {} 
            //     }

         })
         
         let enablementWaveDetails = {
             rows: []
         }
         
        
         for (const [key, value] of Object.entries(waveToItem)) {
             let collapseChild = []
             let idx = 0
            value.forEach((item, index) => {
                collapseChild.push({childContentId: index, content: item.GANTT_CHART_WORKSTREAM_NAME, catalogMatchType: key, data: item, expand: false})
            })

            enablementWaveDetails.rows.push({ 
                "content" : key,
                "contentId"  : idx++,
                "data": aggregatedData.filter(item=> item[keyColumn]===key )[0],
                expand: false,
                collapseChild : collapseChild
            });
          }

        return enablementWaveDetails
    }
    
    componentWillReceiveProps(nextProps, nextState) {
        if (this.state.Test !== nextProps.filterData && 'dedupStats' in nextProps.filterData) {

            var inputParameter = this.state.inputParam
            inputParameter.dedupStats = nextProps.filterData.dedupStats
            inputParameter.enabledCustomer = nextProps.filterData.enabledCustomer
            inputParameter.transactingCustomer = nextProps.filterData.transactingCustomer
            inputParameter.buyerRegion = nextProps.filterData.buyerRegion
            inputParameter.businessUnit = nextProps.filterData.businessUnit
            inputParameter.erpSystem = nextProps.filterData.erpSystem
            inputParameter.l4IndustryName = nextProps.filterData.l4IndustryName
            inputParameter.erpCode = nextProps.filterData.erpCode
            inputParameter.erpDesc = nextProps.filterData.erpDesc
            inputParameter.sicCode = nextProps.filterData.sicCode
            inputParameter.sicDesc = nextProps.filterData.sicDesc
            inputParameter.goods = nextProps.filterData.goods
            inputParameter.invoiceCountry = nextProps.filterData.invoiceCountry
            inputParameter.legalInvoice = nextProps.filterData.legalInvoice
            inputParameter.aribaNwEligible = nextProps.filterData.aribaNwEligible
            inputParameter.metricsType = nextProps.filterData.metricsType
            inputParameter.directSpend = nextProps.filterData.directSpend
            setTimeout(function () {
                this.GetEnablementDetails(inputParameter)
                this.setState({ Test: nextProps.filterData, inputParam: Object.keys(nextProps.filterData).length !== 0 ? nextProps.filterData : this.state.inputParam })
                //console.log("if enablemtn details filetr props-")
            }.bind(this), 1000);

        }
        else {
            //  console.log("else enablemtn details filetr props-")
        }

    }

    render()
    {
        return(
            <div className="grid-x" style={{ width: '100%'}}>
                
                    <div className="medium-12">
                        {(this.state.isLoading) ?
                            <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                            : null
                        }
                        {(this.state.isError === 'Yes') ?
                            <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                            : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                        }
                    </div>
               
               
                {/* <div className="medium-12 marginTop">
                        <div className="grid-x">                         
                            <div className="medium-12">
                                                             
                                    {this.state.enablementTypesJson !== undefined && this.state.enablementTypesJson !== null ?
                                        <EnablementInsightTabTable
                                            data = {this.state.enablementTypesJson}
                                            grandTotal = {this.state.grandTotal}
                                            content={this.props.content}
                                            tableName={this.props.content.enablementWaveDetails.enablementRecommendationHeader}
                                            tableSequence={"2"} // sequence decide header bg color
                                            showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                            metricsType= {this.state.inputParam.metricsType}                                            
                                        />
                                        : <div className="pt10">{this.props.content.noDataFound}</div>}                                                                 
                            </div>
                        </div>
                    </div> */}

                <div className="medium-12 marginTop">
                    <div className="grid-x">    
                                <div className="medium-12">                                
                                    {this.state.enablementTypeData !== undefined && this.state.enablementTypeData !== null ?
                                        <ExpandableTable
                                            data={this.state.enablementTypeData}
                                            grandTotal={this.state.grandTotal}
                                            content={this.props.content.catalogMatch}
                                            //anIDsData={this.state.onboardingWorkstream}
                                            tableName={this.props.content.enablementWaveDetails.enablementRecommendationHeader}
                                            tableSequence={"19"}
                                            showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                            metricsType= {this.state.inputParam.metricsType}
                                            tabName = {'Enablement Recommendations'}
                                        />
                                        : <div className="pt10">{this.props.content.noDataFound}</div>}
                                        </div>
                                </div>
                               </div>
                                           

                  <div>  
                  {this.refs.enablementSupplier ? this.state.openPopup ?
                    this.refs.enablementSupplier.show() :
                    this.refs.enablementSupplier.hide() : ''}        
                  <Modal
                    ref={'enablementSupplier'}
                    modalStyle={changeModalBorderStyle}
                    contentStyle={drilldownModalBorderContentStyle}
                    rectStyle={changeModalBorderRectStyle}                   
                    keyboard={false}
                    backdrop={false}
                   >
                    <div className="grid-x tabs_container supplier-tabs">                       
                        <div className="medium-12">                        
                            {
                                <EnablementSupplierDetailsPopup onSave={this.onSave} input={this.state.inputParam} outsidePopupClick={this.outsidePopupClick} ></EnablementSupplierDetailsPopup>
                            }
                        </div>
                    </div>
                    <button onClick={() => { this.setVisibility(false); this.setState({ selectedIndustryName: {} }) }} className='close' />
                </Modal>
                  </div>                    
            </div>                   
        )
    }

}


const mapStateToProps = state => {
	return {
        content: state.content.enablementInsight,
        //content: state.content.subLeverCalculations,
		ui: state.content.ui,
		currency: decodeURIComponent(state.valueStory.Currency),
		userData: state.valueStory,
		spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
		benchMarkCalculations: state.benchMarksData,
		allContent: state.content,
        language: state.language,
        spendDetails: state.spendDetails,
        user: state.default        
	}
}

const mapDispatchToProps = dispatch => {
	return {
        enablementInsightSuccess: message => dispatch(enablementInsightSuccess(message)),
        enablementInsightFailure: error => dispatch(enablementInsightFailure(error)),
        enablementInsightLoading: bool => dispatch(enablementInsightLoading(bool)),
        resetError: error => dispatch(resetError(error)),
        getEnablementDetails: (data) => dispatch(getEnablementDetails(data)),
        getEnablementFiltersDetails: (data) => dispatch(getEnablementFiltersDetails(data))
	}
}

export default connect(	mapStateToProps, mapDispatchToProps)(EnablementRecommendations)

